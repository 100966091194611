/* eslint-disable react-hooks/exhaustive-deps */
// React
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

// Components
import DemandCard from "../../components/Ui/DemandCard";
import DemandsFilter from "../../components/Ui/DemandsFilter";
import DemandsTab from "../../components/Ui/DemandsTabs";
import InputMoney from "../../components/form/InputMoney";
import ButtonDefault from "../../components/form/ButtonDefault";
import ButtonIcon from "../../components/form/ButtonIcon";

// Context
import { useAuth } from "../../contexts/UserContext";

// Styles
import { Container, Filter, Menu } from "./styles";
import { Paginate } from "../Proposals/styles";

// Libraries
import ReactPaginate from 'react-paginate';
import { Oval } from 'react-loader-spinner';
import toast, { Toaster } from 'react-hot-toast';
import DatePicker from 'react-date-picker';
import moment from "moment";
import 'moment/locale/pt-br';
import Moment from 'react-moment';
import Select from 'react-select';

// Icons
import { IconAdvancePagination, IconBackPagination, IconButtonArrowDown, IconClose, IconListProps } from "../../components/icons";

// Services
import api from "../../services/api";
import {clarity} from "react-microsoft-clarity";

interface Pagination {
    currentPage: string,
    from: number,
    lastPage: number,
    perPage: number,
    to: number,
    total: number,
}

interface ServicesTypes {
    id_servico: number,
    id_categoria_de_servico: number,
    titulo: string,
}

export default function Demands() {
    const [ loading, setLoading ] = useState<boolean>(false);
    const { setPageName, setPageType, user } = useAuth();
    const history = useHistory();
    const location = useLocation<any>();
    const [ selected, setSelected ] = useState<any>('1');
    const [ filterDemands, setFilterDemands ] = useState<any>([]);
    const [ searchProfiles, setSearchProfiles ] = useState<any>('');
    const [ urgentDemands, setUrgentDemands ] = useState<any>();
    const [ urgentDemandsPagination, setUrgentDemandsPagination ] = useState<Pagination>();
    const [ urgentDemandsTotal, setUrgentDemandsTotal ] = useState<any>();
    const [ onGoingDemands, setOnGoingDemands ] = useState<any>();
    const [ onGoingDemandsPagination, setOnGoindDemandsPagination ] = useState<Pagination>();
    const [ onGoingDemandsTotal, setOnGoingDemandsTotal ] = useState<any>();    
    const [ finishedDemands, setFinishedDemands ] = useState<any>();
    const [ finishedDemandsPagination, setFinishedDemandPagination ] = useState<Pagination>();
    const [ finishedDemandsTotal, setFinisehdDemandsTotal ] = useState<any>();
    const [ dataCounties, setDataCounties ] = useState<any>();
    const [ countieSelected, setCountieSelected ] = useState<any>('');
    const [ countieIdSelected, setCountieIdSelected ] = useState<any>('');
    const [ openCounties, setOpenCounties ] = useState<boolean>(false);
    const [ openStatus, setOpenStatus ] = useState<any>();
    const [ statusSelected, setStatusSelected ] = useState<any>('');
    const [ urgentStatusSelected, setUrgentStatusSelected ] = useState<any>('');
    const [ starterDate, setStarterDate ] = useState<any>(new Date().setFullYear(new Date().getFullYear() - 1));
    const [ endDate, setEndDate ] = useState<any>(new Date().setFullYear(new Date().getFullYear() + 1));
    const [ filterDate, setFilterDate ] = useState<any>();
    const [ openServices, setOpenServices ] = useState<boolean>(false);
    const [ categorieIdSelected, setCategorieIdSelected ] = useState<any>('');
    const [ serviceSelected, setServiceSelected ] = useState<any>('');
    const [ openSubtype, setOpenSubtype ] = useState<boolean>(false);
    const [ subtypeSelected, setSubtypeSelected ] = useState<any>('');
    const [ openSubServices, setOpenSubServices ] = useState<boolean>(false);
    const [ subServiceSelected, setSubServiceSelected ] = useState<any>('');
    const [ subServiceIdSelected, setSubServiceIdSelected] = useState<any>('');
    const [ openValue, setOpenValue ] = useState<any>(true);
    const [ openDate, setOpenDate ] = useState<any>(true);
    const [ openEndDate, setOpenEndDate ] = useState<any>(true);
    const [ starterValue, setStarterValue ] = useState<any>('');
    const [ endValue, setEndValue ] = useState<any>('');
    const [ openModality, setOpenModality ] = useState<boolean>(false);
    const [ modalitySelected, setModalitySelected ] = useState<any>('');
    const [ conclusionStartDate, setConclusionStartDate ] = useState<any>(new Date().setFullYear(new Date().getFullYear() - 1));
    const [ conclusionEndDate, setConclusionEndDate ] = useState<any>(new Date().setFullYear(new Date().getFullYear() + 1));
    const [ servicesTypes, setServicesTypes ] = useState<ServicesTypes[]>([]);

    const startsInitalDate = ''
    const startsEndDate = ''
    const conclusionInitalDate = ''
    const conclusionFinishDate = ''
    let filteredStatusOnGoing = '';
    let filteredStatusUrgent = '';
    let filteredStatusFinished = '';
    const [urgentPayload, setUrgentPayload] = useState<any>('');
    const [onGoingPayload, setOnGoingPayload] = useState<any>('');
    const [finishedPayload, setFinishedPayload] = useState<any>('');

    const city = {
        control: (provided: Record<string, unknown>) => ({
            ...provided,
            height: 40,
            boxShadow: "none",
            cursor: 'pointer',
            border: '1px solid #EAEEF3',
            '&:hover': {
                border: '1px solid #04FDD0',
            },
        }),
        placeholder: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
            fontWeight: '400',
        }),
        singleValue: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
        }),
        option: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#5A646E',
            fontSize: '16px',
            fontWeight: '500',
        }),
    } 

    async function getUrgentDemands(page: any) {
        try {

            setLoading(true);

            if (
                statusSelected === 'Pendente de Orientacao' ||
                statusSelected === 'Com Pendencia' ||
                statusSelected === 'Interrompida' ||
                statusSelected === 'Em Atraso' ||
                statusSelected === 'Pendencia Resolvida'
            ) {
                filteredStatusUrgent = statusSelected;
            }

            if (user.profile === 'comum') {

                const response = await api.get(`proposta-urgentes?pesquisa=${searchProfiles}&id_categoria_de_servico=${categorieIdSelected}&id_comarca=${countieIdSelected}&page=${page}&limit=10&valor_de=${starterValue}&valor_ate=${endValue}&solicitada_de=${startsInitalDate}&solicitada_ate=${startsEndDate}&conclusao_de=${conclusionInitalDate}&conclusao_ate=${conclusionFinishDate}&tipo_audiencia=${subtypeSelected}&id_usuario=${user.id}&tipo=demanda&modalidade=${modalitySelected}&status_filter=${filteredStatusUrgent}&id_servico=${subServiceIdSelected}`);
                setUrgentDemands(response.data.result.data);
                setUrgentDemandsTotal(response.data.result.pagination.total);
                setUrgentDemandsPagination(response.data.result.pagination);

                if (response.data.status !== 'success') {
                    // throw new Error();
                    if (response.data.result.length === 0) {

                        throw new Error(response.data.result[0].message)

                    } else {

                        throw new Error(response.data.message);
                    }
                }

            } else {
                const response = await api.get(`proposta-urgentes?pesquisa=${searchProfiles}&id_categoria_de_servico=${categorieIdSelected}&id_comarca=${countieIdSelected}&page=${page}&limit=10&valor_de=${starterValue}&valor_ate=${endValue}&solicitada_de=${startsInitalDate}&solicitada_ate=${startsEndDate}&conclusao_de=${conclusionInitalDate}&conclusao_ate=${conclusionFinishDate}&tipo_audiencia=${subtypeSelected}&id_operador=${user.id}&tipo=demanda&modalidade=${modalitySelected}&status_filter=${statusSelected}&id_servico=${subServiceIdSelected}`);
                setUrgentDemands(response.data.result.data);
                setUrgentDemandsTotal(response.data.result.pagination.total);
                setUrgentDemandsPagination(response.data.result.pagination);
                setUrgentPayload(`pesquisa=${searchProfiles}&id_categoria_de_servico=${categorieIdSelected}&id_comarca=${countieIdSelected}&page=${page}&limit=10&valor_de=${starterValue}&valor_ate=${endValue}&solicitada_de=${startsInitalDate}&solicitada_ate=${startsEndDate}&conclusao_de=${conclusionInitalDate}&conclusao_ate=${conclusionFinishDate}&tipo_audiencia=${subtypeSelected}&id_operador=${user.id}&tipo=demanda&modalidade=${modalitySelected}&status_filter=${statusSelected}&urgente=sim&id_servico=${subServiceIdSelected}`);
                
                if (response.data.status !== 'success') {
                    // throw new Error();
                    if (response.data.message === '1 Erros encontrados') {

                        throw new Error(response.data.result[0].message)

                    } else {

                        throw new Error(response.data.message);
                    }
                }
            }
            

            setLoading(false);

        } catch (error: any) {

            console.log('log do erro pegando demandas urgentes', error)

            toast.error(error.message, {
                duration: 3000,
                className: 'toasted',
                style: {
                    padding: '16px',
                    color: '#1A2858',
                },
                iconTheme: {
                    primary: '#F24343',
                    secondary: '#FFFAEE',
                },
            });

            setLoading(false);
        }

    }

    async function getOnGoingDemands(page: any) {
        try {

            clarity.setEvent('pesquisa-demandas')

            setLoading(true);

            if (
                statusSelected === 'Concluida' || 
                statusSelected === 'Com Pendencia' || 
                statusSelected === 'Em Andamento' || 
                statusSelected === 'Aguardando Ciencia' || 
                statusSelected === 'Pendencia Resolvida' 
            ) {
                filteredStatusOnGoing = statusSelected;
            }

            if (user.profile === 'comum') {
                
                const response = await api.get(`proposta?pesquisa=${searchProfiles}&id_categoria_de_servico=${categorieIdSelected}&id_comarca=${countieIdSelected}&status=Em Andamento-Concluida&page=${page}&limit=10&valor_de=${starterValue}&valor_ate=${endValue}&solicitada_de=${startsInitalDate}&solicitada_ate=${startsEndDate}&conclusao_de=${conclusionInitalDate}&conclusao_ate=${conclusionFinishDate}&tipo_audiencia=${subtypeSelected}&id_usuario=${user.id}&tipo=demanda&modalidade=${modalitySelected}&status_filter=${filteredStatusOnGoing}&id_servico=${subServiceIdSelected}`);
                setOnGoingDemands(response.data.result.data);
                setOnGoingDemandsTotal(response.data.result.pagination.total);
                setOnGoindDemandsPagination(response.data.result.pagination);

                if (response.data.status !== 'success') {
                    // throw new Error();
                    if (response.data.result.length === 0) {

                        throw new Error(response.data.result[0].message)

                    } else {

                        throw new Error(response.data.message);
                    }
                }  

            } else {

                const response = await api.get(`proposta?pesquisa=${searchProfiles}&id_categoria_de_servico=${categorieIdSelected}&id_comarca=${countieIdSelected}&status=Em Andamento-Concluida&page=${page}&limit=10&valor_de=${starterValue}&valor_ate=${endValue}&solicitada_de=${startsInitalDate}&solicitada_ate=${startsEndDate}&conclusao_de=${conclusionInitalDate}&conclusao_ate=${conclusionFinishDate}&tipo_audiencia=${subtypeSelected}&id_operador=${user.id}&tipo=demanda&modalidade=${modalitySelected}&status_filter=${statusSelected}&id_servico=${subServiceIdSelected}`);
                setOnGoingDemands(response.data.result.data);
                setOnGoingDemandsTotal(response.data.result.pagination.total);
                setOnGoindDemandsPagination(response.data.result.pagination);  
                setOnGoingPayload(`pesquisa=${searchProfiles}&id_categoria_de_servico=${categorieIdSelected}&id_comarca=${countieIdSelected}&status=Em Andamento-Concluida&page=${page}&limit=10&valor_de=${starterValue}&valor_ate=${endValue}&solicitada_de=${startsInitalDate}&solicitada_ate=${startsEndDate}&conclusao_de=${conclusionInitalDate}&conclusao_ate=${conclusionFinishDate}&tipo_audiencia=${subtypeSelected}&id_operador=${user.id}&tipo=demanda&modalidade=${modalitySelected}&status_filter=${statusSelected}&id_servico=${subServiceIdSelected}`);   
    
                if (response.data.status !== 'success') {
                    // throw new Error();
                    if (response.data.message === '1 Erros encontrados') {

                        throw new Error(response.data.result[0].message)

                    } else {

                        throw new Error(response.data.message);
                    }
                }      

            }

            setLoading(false);
            

        } catch (error: any) {

            console.log('log do erro pegando demandas em andamento', error)

            toast.error(error.message, {
                duration: 3000,
                className: 'toasted',
                style: {
                    padding: '16px',
                    color: '#1A2858',
                },
                iconTheme: {
                    primary: '#F24343',
                    secondary: '#FFFAEE',
                },
            });

            setLoading(false);
        }

    }

    async function getFinishedDemands(page: any) {
        try {

            setLoading(true);

            if (
                statusSelected === 'Finalizada com Aceite' ||
                statusSelected === 'Finalizada com Recusa' ||
                statusSelected === 'Cancelada' 
            ) {
                filteredStatusFinished = statusSelected;
            }

            if (user.profile === 'comum') {

                const response = await api.get(`proposta?pesquisa=${searchProfiles}&id_categoria_de_servico=${categorieIdSelected}&id_comarca=${countieIdSelected}&status=Cancelada-Finalizada-Interrompida&page=${page}&limit=10&valor_de=${starterValue}&valor_ate=${endValue}&solicitada_de=${startsInitalDate}&solicitada_ate=${startsEndDate}&conclusao_de=${conclusionInitalDate}&conclusao_ate=${conclusionFinishDate}&tipo_audiencia=${subtypeSelected}&id_usuario=${user.id}&tipo=demanda&modalidade=${modalitySelected}&status_filter=${statusSelected}&id_servico=${subServiceIdSelected}`);
                setFinishedDemands(response.data.result.data);
                setFinisehdDemandsTotal(response.data.result.pagination.total);
                setFinishedDemandPagination(response.data.result.pagination);                 

                if (response.data.status !== 'success') {
                    // throw new Error();
                    if (response.data.result.length === 0) {

                        throw new Error(response.data.result[0].message)

                    } else {

                        throw new Error(response.data.message);
                    }
                }
                      
            } else {

                const response = await api.get(`proposta?pesquisa=${searchProfiles}&id_categoria_de_servico=${categorieIdSelected}&id_comarca=${countieIdSelected}&status=Cancelada-Finalizada&page=${page}&limit=10&valor_de=${starterValue}&valor_ate=${endValue}&solicitada_de=${startsInitalDate}&solicitada_ate=${startsEndDate}&conclusao_de=${conclusionInitalDate}&conclusao_ate=${conclusionFinishDate}&tipo_audiencia=${subtypeSelected}&id_operador=${user.id}&tipo=demanda&modalidade=${modalitySelected}&status_filter=${statusSelected}&id_servico=${subServiceIdSelected}`);
                setFinishedDemands(response.data.result.data);
                setFinisehdDemandsTotal(response.data.result.pagination.total);
                setFinishedDemandPagination(response.data.result.pagination);
                setFinishedPayload(`pesquisa=${searchProfiles}&id_categoria_de_servico=${categorieIdSelected}&id_comarca=${countieIdSelected}&status=Cancelada-Finalizada&page=${page}&limit=10&valor_de=${starterValue}&valor_ate=${endValue}&solicitada_de=${startsInitalDate}&solicitada_ate=${startsEndDate}&conclusao_de=${conclusionInitalDate}&conclusao_ate=${conclusionFinishDate}&tipo_audiencia=${subtypeSelected}&id_operador=${user.id}&tipo=demanda&modalidade=${modalitySelected}&status_filter=${filteredStatusFinished}&id_servico=${subServiceIdSelected}`); 

                if (response.data.status !== 'success') {
                    // throw new Error();
                    if (response.data.result.length === 0) {

                        throw new Error(response.data.result[0].message)

                    } else {

                        throw new Error(response.data.message);
                    }
                }

            }

            setLoading(false);

        } catch (error: any) {

            console.log('log do erro pegando demandas finalizadas', error)

            toast.error(error.message, {
                duration: 3000,
                className: 'toasted',
                style: {
                    padding: '16px',
                    color: '#1A2858',
                },
                iconTheme: {
                    primary: '#F24343',
                    secondary: '#FFFAEE',
                },
            });

            setLoading(false);
        }

    }

    useEffect(() => {
        setLoading(true);

        setPageName('Demandas');
        setPageType('normal');

        getUrgentDemands(1);
        getOnGoingDemands(1);
        getFinishedDemands(1);        

        setLoading(false);

    }, [categorieIdSelected, countieIdSelected, selected, statusSelected, serviceSelected, filterDate, searchProfiles, urgentStatusSelected, modalitySelected, subtypeSelected, subServiceSelected])

    useEffect(() => {

        async function getCounties() {
            try {

                setLoading(true)
                const response = await api.get('comarca');

                const countieArray: { value: any, label: any, id_comarca: number }[] = []

                
                response.data.result.map((row: any) => (
                    countieArray.push({
                        label: `${row.uf} - ${row.cidade}`,
                        value: `${row.uf} - ${row.cidade}`,
                        id_comarca: row.id_comarca,
                    })
                ))
                    
                setDataCounties(countieArray);
                
                if (response.data.status !== 'success') {
                    throw new Error('Não foi possível encontrar comarcas.')
                }

                setLoading(false)

            } catch (error: any) {
                toast.error(error.message, {
                    duration: 3000,
                    className: 'toasted',
                    style: {
                        padding: '16px',
                        color: '#1A2858',
                    },
                    iconTheme: {
                        primary: '#F24343',
                        secondary: '#FFFAEE',
                    },
                });
                setLoading(false)
            }
        }

        getCounties();

    }, [filterDemands])

    const onClickPage = (name: any, selectedItem: { selected: number; }) => {

        let pageNumber = selectedItem.selected + 1

        if (name === 'urgent') {
            getUrgentDemands(pageNumber);
        }

        if (name === 'progress') {
            getOnGoingDemands(pageNumber)
        }

        if (name === 'finished') {
            getFinishedDemands(pageNumber)
        }

    }

    function clearAll() {
        setStatusSelected('');
        setUrgentStatusSelected('')
        setCountieSelected('');
        setCountieIdSelected('');
        setServiceSelected('');
        setSubtypeSelected('');
        setStarterValue('');
        setEndValue('');
        setStarterDate(new Date().setFullYear(new Date().getFullYear() - 1));
        setEndDate(new Date().setFullYear(new Date().getFullYear() + 1));
        setConclusionStartDate(new Date().setFullYear(new Date().getFullYear() - 1));
        setConclusionEndDate(new Date().setFullYear(new Date().getFullYear() + 1));
        setCategorieIdSelected('');
        setFilterDemands('');
        setFilterDate(new Date());
        setOpenValue(true);
        setOpenDate(true);
        setOpenEndDate(true);
        setSubServiceSelected('');
        setSubServiceIdSelected('');
        getServices();
    }

    useEffect(() => {

        if (location.state !== undefined) {

            if (location.state.selected === '2') {
                if (location.state.type !== undefined && location.state.type === 'Audiência') {

                    setSelected('2');
                    setCategorieIdSelected(1);
                    setServiceSelected('Audiências');
                    setFilterDemands((filters: any) => [...filters, 'servico']);
                    
                } else if (location.state.type !== undefined && location.state.type === 'Diligencia') {
                    
                    setSelected('2');
                    setCategorieIdSelected(2);
                    setServiceSelected('Diligencia');
                    setFilterDemands((filters: any) => [...filters, 'servico']);

                } else if (location.state.type !== undefined && location.state.type === 'Protocolo') {

                    setSelected('2');
                    setCategorieIdSelected(3);
                    setServiceSelected('Protocolo');
                    setFilterDemands((filters: any) => [...filters, 'servico']);

                } else {
                    
                    setSelected('2');
                    
                }
            }  
            
            if (location.state.selected === '3' && location.state.demands === 'canceled') {
                setSelected('3');
                setStatusSelected('Finalizada com Recusa');
                setFilterDemands((filters: any) => [...filters, 'status']);
            }
    
            if (location.state.selected === '3' && location.state.demands === 'accepted') {
                setSelected('3');
                setStatusSelected('Finalizada com aceite');
                setFilterDemands((filters: any) => [...filters, 'status']);
            }  
            
            if (location.state.filter === 'month') {
                const now = new Date();
                const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);                
                const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);

                setStarterDate(firstDay);
                setEndDate(lastDay)
                setFilterDate(new Date());
                setFilterDemands((filters: any) => [...filters, 'solicitada'])
                
            }

        } 
       
    }, [location])

    function removeEspecificValueFromFilters(value: string) {
        const arrayFiltered = filterDemands;
        const index = arrayFiltered.indexOf(value);
        const newArray = arrayFiltered.splice(index, 1);
        console.log('log do novo array', newArray, arrayFiltered)
        setFilterDemands(arrayFiltered)        

        if (value === 'value') {
            setOpenValue(true)
        } 
        
        if (value === 'starts') {
            setOpenDate(false);
        }
        
        if (value === 'conclusion') {
            setOpenEndDate(false);
        }

        if (value === 'subtype_service') {
            setOpenSubServices(false);
        }

        if (value === 'servico') {
            setOpenServices(false);
            setCategorieIdSelected('');
        }

        if (value === 'solicitada') {
            setStarterDate(new Date().setFullYear(new Date().getFullYear() - 1));
            setEndDate(new Date().setFullYear(new Date().getFullYear() + 1)); 
            setFilterDate(new Date());
        }

        if (value === 'conclusao') {
            setConclusionStartDate(new Date().setFullYear(new Date().getFullYear() - 1));
            setConclusionEndDate(new Date().setFullYear(new Date().getFullYear() + 1)); 
            setFilterDate(new Date());
        }

    }

    async function getServices(id?: number) {
        try {
            setLoading(true);

            if (id) {
                const response = await api.get(`proposta-tipo/${id}`);
                setServicesTypes(response.data.result);
            } else {
                const response = await api.get(`proposta-tipo/`);
                setServicesTypes(response.data.result);
            }

            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log('log do erro response getServices', error);
        }
    }

    useEffect(() => {
        getServices();
    }, [])

    return (
        <Container>

            {
                loading &&
                <Oval
                    height={150}
                    width={150}
                    color="#FA9604"
                    wrapperStyle={{}}
                    wrapperClass="loader"
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="#FA9604"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                />
            }

            {
                user.profile !== 'comum' &&
                <ButtonIcon
                    Icon={<IconListProps />}
                    children={'Nova proposta'}
                    styles="op"
                    onClick={() => history.push('/nova-proposta')}
                />
            }

            <Menu>

                <DemandsTab 
                    selected={selected}
                    setSelected={setSelected}
                    urgent={urgentDemandsTotal}
                    progress={onGoingDemandsTotal}
                    finished={finishedDemandsTotal}
                />

                <DemandsFilter 
                    filters={filterDemands}
                    setFilters={setFilterDemands}
                    setClearAll={clearAll}
                    setSearchDemands={(value: any) => setSearchProfiles(value.replace(/\D/g, ''))}
                    requestedPayload={
                        selected === '1'
                        ? urgentPayload
                        : selected === '2'
                            ? onGoingPayload
                            : selected === '3'
                                ? finishedPayload
                                : ''
                    }
                />

            </Menu>

            <Filter>
                {
                    filterDemands.length > 0 &&
                    <div className="results">
                        {
                            filterDemands !== undefined && selected === '1' &&
                            `${urgentDemands?.length} resultados:`
                        }
                        {
                            filterDemands !== undefined && selected === '2' &&
                            `${onGoingDemands?.length} resultados:`
                        }
                        {
                            filterDemands !== undefined && selected === '3' &&
                            `${finishedDemands?.length} resultados:`
                        }
                    </div>

                }

                <div className="buttons">
                    {
                        filterDemands.includes('status') &&
                        <>
                            <div
                                className="status"
                                onClick={() => setOpenStatus(!openStatus)}
                            >
                                Status <IconButtonArrowDown />
                                <div className={openStatus ? 'status-map' : 'status-map no-show'}>
                                    <div
                                        className="filters"
                                        onClick={() => {setStatusSelected('Interrompida')}}
                                    >
                                        Interrompida
                                    </div>
                                    <div
                                        className="filters"
                                        onClick={() => setStatusSelected('Em Andamento')}
                                    >
                                        Em andamento
                                    </div>
                                    <div
                                        className="filters"
                                        onClick={() => setStatusSelected('Cancelada')}
                                    >
                                        Cancelada
                                    </div>
                                    <div
                                        className="filters"
                                        onClick={() => setStatusSelected('Concluida')}
                                    >
                                        Concluida
                                    </div>
                                    <div
                                        className="filters"
                                        onClick={() => setStatusSelected('Finalizada com Aceite')}
                                    >
                                        Finalizada com aceite
                                    </div>
                                    <div
                                        className="filters"
                                        onClick={() => setStatusSelected('Finalizada com Recusa')}
                                    >
                                        Finalizada com recusa
                                    </div>
                                    <div
                                        className="filters"
                                        onClick={() => { setStatusSelected('Com Pendencia'); filteredStatusUrgent = 'Com Pendencia'}}
                                    >
                                        Com pendência
                                    </div>
                                    <div
                                        className="filters"
                                        onClick={() => { setStatusSelected('Pendencia Resolvida'); filteredStatusUrgent = 'Pendencia Resolvida'}}
                                    >
                                        Pendência resolvida
                                    </div>
                                    <div
                                        className="filters"
                                        onClick={() => { setStatusSelected('Em Atraso'); filteredStatusUrgent = 'Em Atraso' }}
                                    >
                                        Em atraso
                                    </div>
                                    {
                                        user.profile !== 'comum' &&
                                        <div
                                            className="filters"
                                                onClick={() => setStatusSelected('Pendente de Orientacao')}
                                        >
                                            Pendente de orientação
                                        </div>
                                    }
                                    <div
                                        className="filters"
                                        onClick={() => { setStatusSelected('Aguardando Ciencia')}}
                                    >
                                        Aguardando ciência
                                    </div>
                                </div>

                            </div>
                            {
                                statusSelected &&
                                <div
                                    className="status-selected"
                                    onClick={() => setOpenStatus(!openStatus)}
                                >
                                    <div>Status: </div>
                                    <div>
                                        {
                                            statusSelected === 'Pendente de Orientacao'
                                            ?   'Pendente de Orientação'
                                            :   statusSelected

                                        }
                                    </div>
                                    <div
                                        className="close-icon"
                                        onClick={() => {
                                            setStatusSelected(''); 
                                            removeEspecificValueFromFilters('status');
                                        }}
                                    >
                                        <IconClose />
                                    </div>
                                </div>
                            }
                        </>
                    }

                    {
                        filterDemands.includes('comarca') &&
                        <div className="countie-selector">
                            <div
                                className="countie"
                                onClick={() => setOpenCounties(!openCounties)}
                            >
                                Comarca <IconButtonArrowDown />
                                {
                                    countieSelected &&
                                    <div className="countie-selected">
                                        <div>Comarca:</div>
                                        <div>{countieSelected}</div>
                                        <div
                                            className="close-icon"
                                            onClick={() => { 
                                                setCountieSelected(''); 
                                                setCountieIdSelected('');
                                                removeEspecificValueFromFilters('comarca');
                                            }}
                                        >
                                            <IconClose />
                                        </div>
                                    </div>
                                }
                            </div>
                            {
                                countieIdSelected === '' &&
                                <div className="filters">
                                    <Select
                                        styles={city}
                                        components={{
                                            IndicatorSeparator: () => null
                                        }}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 8,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#DDFAF4',
                                                primary50: '#DDFAF4',
                                                primary: '#02E8BE',
                                            },
                                        })}
                                        name='id_comarca'
                                        placeholder="Selecionar"
                                        options={dataCounties}
                                        onChange={(e: any) => {setCountieSelected(e.value); setCountieIdSelected(e.id_comarca); console.log('log da comarca selecionada', e)}}                                        
                                    />
                                </div>
                            }
                        </div>
                    }
                    
                    {
                        filterDemands.includes('servico') &&
                        <div
                            className="servico"
                            onClick={() => setOpenServices(!openServices)}
                        >
                            Tipo de serviço <IconButtonArrowDown />
                            <div className={openServices ? 'servico-map' : 'servico-map no-show'}>
                                <div
                                    className="filters"
                                    onClick={() => { 
                                        setServiceSelected('Audiências'); 
                                        setCategorieIdSelected(1);
                                        getServices(1);
                                    }}
                                >
                                    Audiência
                                </div>
                                <div
                                    className="filters"
                                    onClick={() => { 
                                        setServiceSelected('Diligências'); 
                                        setCategorieIdSelected(2);
                                        getServices(2);
                                    }}
                                >
                                    Diligência
                                </div>
                                <div
                                    className="filters"
                                    onClick={() => { 
                                        setServiceSelected('Protocolos'); 
                                        setCategorieIdSelected(3);
                                        getServices(3);
                                    }}
                                >
                                    Protocolo
                                </div>
                                <div
                                    className="filters"
                                    onClick={() => { 
                                        setServiceSelected('Outros'); 
                                        setCategorieIdSelected(4);
                                        getServices(4);
                                    }}
                                >
                                    Outros
                                </div>
                            </div>

                            {
                                serviceSelected &&
                                <div className="servico-selected">
                                    <div>Serviço:</div>
                                    <div>{serviceSelected}</div>
                                    <div
                                        className="close-icon"
                                        onClick={() => {
                                            setServiceSelected('');
                                            setCategorieIdSelected('');
                                            removeEspecificValueFromFilters('servico')
                                        }}
                                    >
                                        <IconClose />
                                    </div>
                                </div>
                            }  
                        </div>
                    }

                    {
                        filterDemands.includes('subtype_service') &&
                        <>
                            <div
                                className="servico"
                                onClick={() => setOpenSubServices(!openSubServices)}
                            >
                                Subtipo de serviço <IconButtonArrowDown />
                                <div className={openSubServices ? 'servico-map' : 'servico-map no-show'}>
                                    {servicesTypes.map((row: ServicesTypes) => (
                                        <div
                                            className="filters"
                                            onClick={() => {
                                                setSubServiceSelected(row.titulo);
                                                setSubServiceIdSelected(row.id_servico)
                                            }}
                                            key={row.id_servico}
                                        >
                                            {row.titulo}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {
                                subServiceSelected &&
                                <div className="servico-selected">
                                    <div>{subServiceSelected}</div>
                                    <div
                                        className="close-icon"
                                        onClick={() => { 
                                            setSubServiceSelected(''); setSubServiceIdSelected(''); 
                                            removeEspecificValueFromFilters('subtype_service')
                                        }}
                                    >
                                        <IconClose />
                                    </div>
                                </div>
                            }
                        </>
                    }

                    {
                        filterDemands.includes('subtipo') &&
                        <div
                            className="subtype"
                            onClick={() => setOpenSubtype(!openSubtype)}
                        >
                            Subtipo <IconButtonArrowDown />
                            <div className={openSubtype ? 'subtype-map' : 'subtype-map no-show'}>
                                <div
                                    className="filters"
                                    onClick={() => setSubtypeSelected('AIJ')}
                                >
                                    AIJ
                                </div>
                                <div
                                    className="filters"
                                    onClick={() => setSubtypeSelected('Conciliação')}
                                >
                                    Conciliação
                                </div>
                                <div
                                    className="filters"
                                    onClick={() => setSubtypeSelected('Instruções e Julgamento')}
                                >
                                    Instruções e Julgamentos
                                </div>
                                <div
                                    className="filters"
                                    onClick={() => setSubtypeSelected('Justificação')}
                                >
                                    Justificação
                                </div>
                                <div
                                    className="filters"
                                    onClick={() => setSubtypeSelected('Procon')}
                                >
                                    Procon
                                </div>
                                <div
                                    className="filters"
                                    onClick={() => setSubtypeSelected('UNA')}
                                >
                                    UNA
                                </div>
                                <div
                                    className="filters"
                                    onClick={() => setSubtypeSelected('Sustentação Oral')}
                                >
                                    Sustentação Oral
                                </div>
                            </div>

                            {
                                subtypeSelected &&
                                <div className="subtype-selected">
                                    <div>Subtipo:</div>
                                    <div>{subtypeSelected}</div>
                                    <div
                                        className="close-icon"
                                        onClick={() => {
                                            setSubtypeSelected('');
                                            removeEspecificValueFromFilters('subtipo')
                                        }}
                                    >
                                        <IconClose />
                                    </div>
                                </div>
                            }  
                        </div>
                    }

                    {
                        filterDemands.includes('modality') &&
                        <>
                            <div
                                className="servico"
                                onClick={() => setOpenModality(!openModality)}
                            >
                                Modalidade <IconButtonArrowDown />
                                <div className={openModality ? 'servico-map' : 'servico-map no-show'}>
                                    <div
                                        className="filters"
                                        onClick={() => setModalitySelected('presencial')}
                                    >
                                        Presencial
                                    </div>
                                    <div
                                        className="filters"
                                        onClick={() => setModalitySelected('online')}
                                    >
                                        Virtual
                                    </div>                                    
                                </div>
                            </div>
                            {
                                modalitySelected &&
                                <div className="servico-selected">
                                    <div>{modalitySelected == 'online' ? 'Virtual' : 'Presencial'}</div>
                                    <div
                                        className="close-icon"
                                        onClick={() => {
                                            setModalitySelected('');
                                            removeEspecificValueFromFilters('modality');
                                        }}
                                    >
                                        <IconClose />
                                    </div>
                                </div>
                            }                            
                        </>
                    }

                    {
                        filterDemands.includes('valor') &&
                        <div
                            className={openValue ? 'value' : 'value no-show'}
                        >
                            <div className='value-map'>
                                <div className="start">
                                    <div className="filters">
                                        Entre:
                                    </div>

                                    <div className="money">
                                        <InputMoney
                                            name="from"
                                            placeholder="R$ 00,00"
                                            mask="R$ 999.999,99"
                                            value={'sem dados'}
                                            onChangeText={(value) => setStarterValue(value.replace('R$', '').replace(',', '.'))}
                                            className='small'
                                        />
                                    </div>
                                </div>
                                <div className="start">
                                    <div className="filters">
                                        E:
                                    </div>

                                    <div className="money">
                                        <InputMoney
                                            name="to"
                                            placeholder="R$ 00,00"
                                            mask="R$ 999.999,99"
                                            value={'sem dados'}
                                            onChangeText={(value) => setEndValue(value.replace('R$', '').replace(',', '.'))}
                                            className='small'
                                        />
                                    </div>
                                </div>    
                            </div>
                            <div>
                                <ButtonDefault
                                    className="save"
                                    onClick={() => {
                                        setFilterDate(new Date());
                                        setOpenValue(false);
                                    }}
                                >
                                    Aplicar intervalo
                                </ButtonDefault>
                            </div>                 
                            
                        </div>
                    }

                    {
                        starterValue && endValue &&
                        <div className="value-selected">
                            <div>Valor:</div>
                            <div>R$ {starterValue} a R$ {endValue.replace('.', ',')}</div>
                            <div
                                className="close-icon"
                                onClick={() => { 
                                    setStarterValue(''); 
                                    setEndValue(''); 
                                    clearAll(); 
                                    removeEspecificValueFromFilters('valor')
                                }}
                            >
                                <IconClose />
                            </div>
                        </div>
                    }  

                    {
                        filterDemands.includes('solicitada') &&
                        <div className={openDate ? 'date-map' : 'date-map no-show'}>
                            <div className="select-date">
                                <div className="start-date">
                                    <div className="filters">
                                        Data de inicio:
                                    </div>

                                    <div className="date-field">
                                        <DatePicker onChange={setStarterDate} value={starterDate} calendarIcon={null} disableCalendar={true} clearIcon={null} />
                                    </div>
                                </div>
                                <div className="start-date">
                                    <div className="filters">
                                        Data de fim:
                                    </div>

                                    <div className="date-field">
                                        <DatePicker onChange={setEndDate} value={endDate} calendarIcon={null} disableCalendar={true} clearIcon={null} />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <ButtonDefault
                                    className="save"
                                    onClick={() => {
                                        setOpenDate(false);
                                        setFilterDate(new Date());
                                    }}
                                >
                                    Aplicar data
                                </ButtonDefault>
                            </div>
                        </div>
                    }

                    {
                        filterDemands.includes('solicitada') && starterDate && endDate &&
                        <div className="value-selected">
                            <div>Solicitada:</div>
                            <div>
                                De <Moment locale='pt-br' format="DD/MM/YYYY">{starterDate}</Moment> a <Moment locale='pt-br' format="DD/MM/YYYY">{endDate}</Moment>
                            </div>
                            <div
                                className="close-icon"
                                onClick={() => { 
                                    setStarterDate(new Date().setFullYear(new Date().getFullYear() - 1)); 
                                    setEndDate(new Date().setFullYear(new Date().getFullYear() + 1)); 
                                    removeEspecificValueFromFilters('solicitada');
                                    setOpenDate(true);
                                }}
                            >
                                <IconClose />
                            </div>
                        </div>
                    }  

                    {
                        filterDemands.includes('conclusao') &&
                        <div className={openEndDate ? 'conclusion-map' : 'conclusion-map no-show'}>
                            <div className="select-date">
                                <div className="start-date">
                                    <div className="filters">
                                        Data de inicio:
                                    </div>

                                    <div className="date-field">
                                        <DatePicker onChange={setConclusionStartDate} value={conclusionStartDate} calendarIcon={null} disableCalendar={true} clearIcon={null} />
                                    </div>
                                </div>
                                <div className="start-date">
                                    <div className="filters">
                                        Data de fim:
                                    </div>

                                    <div className="date-field">
                                        <DatePicker onChange={setConclusionEndDate} value={conclusionEndDate} calendarIcon={null} disableCalendar={true} clearIcon={null} />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <ButtonDefault
                                    className="save"
                                    onClick={() => {
                                        setOpenEndDate(false);
                                        setFilterDate(new Date());
                                    }}
                                >
                                    Aplicar data
                                </ButtonDefault>
                            </div>
                        </div>
                    }

                    {
                        filterDemands.includes('conclusao') && conclusionStartDate && conclusionEndDate &&
                        <div className="value-selected">
                            <div>Conclusão:</div>
                            <div>
                                De <Moment locale='pt-br' format="DD/MM/YYYY">{conclusionStartDate}</Moment> a <Moment locale='pt-br' format="DD/MM/YYYY">{conclusionEndDate}</Moment>
                            </div>
                            <div
                                className="close-icon"
                                onClick={() => { 
                                    setConclusionStartDate(new Date().setFullYear(new Date().getFullYear() - 1)); 
                                    setConclusionEndDate(new Date().setFullYear(new Date().getFullYear() + 1)); 
                                    removeEspecificValueFromFilters('conclusao');
                                    setOpenEndDate(true);
                                }}
                            >
                                <IconClose />
                            </div>
                        </div>
                    } 
                </div>
            </Filter>

            {
                selected === '1' && urgentDemands &&
                urgentDemands.map((row: any) => (
                    <DemandCard 
                        demand={row}
                        type={selected}
                        key={row.id_proposta}
                    />
                ))
            }

            {
                selected === '1' && urgentDemands?.length < 1 &&
                <div className="zero">Sem demandas disponíveis no momento.</div>
            }

            {
                selected === '2' && onGoingDemands &&
                onGoingDemands.map((row: any) => (
                    <DemandCard 
                        demand={row}
                        type={selected}
                        key={row.id_proposta}
                    />
                ))
            }

            {
                selected === '2' && onGoingDemands?.length < 1 &&
                <div className="zero">Sem demandas disponíveis no momento.</div>
            }

            {
                selected === '3' && finishedDemands &&
                finishedDemands.map((row: any) => (
                    <DemandCard 
                        demand={row}
                        type={selected}
                        key={row.id_proposta}
                    />
                ))
            }

            {
                selected === '3' && finishedDemands?.length < 1 &&
                <div className="zero">Sem demandas disponíveis no momento.</div>
            }

            {
                selected === '1' && urgentDemandsPagination && urgentDemands?.length > 0 &&
                <Paginate>
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel={<IconAdvancePagination />}
                        previousLabel={<IconBackPagination />}
                        onPageChange={(value) => onClickPage('urgent', value)}
                        pageRangeDisplayed={1}
                        marginPagesDisplayed={1}
                        pageCount={urgentDemandsPagination.lastPage}
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="back-page"
                        previousLinkClassName="back-link-page"
                        nextClassName="next-page"
                        nextLinkClassName="next-link-page"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        activeLinkClassName="active"
                    />
                </Paginate>
            }

            {
                selected === '2' && onGoingDemandsPagination && onGoingDemands?.length > 0 &&
                <Paginate>
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel={<IconAdvancePagination />}
                        previousLabel={<IconBackPagination />}
                        onPageChange={(value) => onClickPage('progress', value)}
                        pageRangeDisplayed={1}
                        marginPagesDisplayed={1}
                        pageCount={onGoingDemandsPagination.lastPage}
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="back-page"
                        previousLinkClassName="back-link-page"
                        nextClassName="next-page"
                        nextLinkClassName="next-link-page"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        activeLinkClassName="active"
                    />
                </Paginate>
            }

            {
                selected === '3' && finishedDemandsPagination && finishedDemands?.length > 0 &&
                <Paginate>
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel={<IconAdvancePagination />}
                        previousLabel={<IconBackPagination />}
                        onPageChange={(value) => onClickPage('finished', value)}
                        pageRangeDisplayed={1}
                        marginPagesDisplayed={1}
                        pageCount={finishedDemandsPagination.lastPage}
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="back-page"
                        previousLinkClassName="back-link-page"
                        nextClassName="next-page"
                        nextLinkClassName="next-link-page"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        activeLinkClassName="active"
                    />
                </Paginate>
            }

            <div onClick={() => toast.remove()}>
                <Toaster />
            </div>
        </Container>
    )
}