/* eslint-disable react-hooks/exhaustive-deps */
// React
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// Components
import ButtonIcon from "../../components/form/ButtonIcon";
import ProposalsCard from "../../components/Ui/ProposalsCard";
import ProposalsFilter from "../../components/Ui/ProposalsFilter";
import ProposalsTab from "../../components/Ui/ProposalsTabs";
import InputMoney from "../../components/form/InputMoney";
import ButtonDefault from "../../components/form/ButtonDefault";

// Icons
import { IconAdvancePagination, IconBackPagination, IconButtonArrowDown, IconClose, IconListProps } from "../../components/icons";

// Libraries 
import toast, { Toaster } from 'react-hot-toast';
import ReactPaginate from 'react-paginate';
import { Oval } from 'react-loader-spinner';
import DatePicker from 'react-date-picker';
import moment from "moment";
import 'moment/locale/pt-br';
import Moment from 'react-moment';
import Select from 'react-select';

// Context
import { useAuth } from "../../contexts/UserContext";

// Services
import api from "../../services/api";

// Styles
import { Container, Filter, Menu, Paginate } from "./styles";
import {clarity} from "react-microsoft-clarity";

interface Pagination {
    currentPage: string,
    from: number,
    lastPage: number,
    perPage: number,
    to: number,
    total: number,
}

interface ServicesTypes {
    id_servico: number,
    id_categoria_de_servico: number,
    titulo: string,  
}


export default function Proposals() {
    const history = useHistory()
    const { setPageName, user, setPageType } = useAuth();
    const [ loading, setLoading ] = useState<boolean>(false);
    const [ filterProfiles, setFilterProfiles ] = useState<any>([]);
    const [ selected, setSelected ] = useState('1');
    const [ openProposals, setOpenProposals ] = useState<any>();
    const [ openProposalPagination, setOpenProposalPagination ] = useState<Pagination>();
    const [ totalOpenProposals, setTotalOpenProposals ] = useState<any>();
    const [ sketchedProposals, setSketchedProposals ] = useState<any>();
    const [ sketchedProposalPagination, setSketchedProposalPagination ] = useState<Pagination>();
    const [ totalSketchedProposals, setTotalSketchedProposals ] = useState<any>();
    const [ canceledProposals, setCanceledProposals ] = useState<any>();
    const [ canceledProposalsPagination, setCanceledProposalPagination ] = useState<Pagination>();
    const [ totalCanceled, setTotalCanceled ] = useState<any>();
    const [ searchProfiles, setSearchProfiles ] = useState<any>('');
    const [ openValue, setOpenValue ] = useState<any>(true);
    const [ openDate, setOpenDate ] = useState<any>(true);
    const [ openAcceptDate, setOpenAcceptDate ] = useState<any>(true);
    const [ openEndDate, setOpenEndDate ] = useState<any>(true);
    const [ openCounties, setOpenCounties ] = useState<boolean>(false); 
    const [ dataCounties, setDataCounties ] = useState<any>();
    const [ countieSelected, setCountieSelected ] = useState<any>('');
    const [ countieIdSelected, setCountieIdSelected ] = useState<any>('');
    const [ categorieIdSelected, setCategorieIdSelected ] = useState<any>('');
    const [ subServiceIdSelected, setSubServiceIdSelected ] = useState<any>('');
    const [ modalitySelected, setModalitySelected ] = useState<any>('');
    const [ starterDate, setStarterDate ] = useState<any>(new Date().setFullYear(new Date().getFullYear() - 1));
    const [ endDate, setEndDate ] = useState<any>(new Date().setFullYear(new Date().getFullYear() + 1));
    const [ openServices, setOpenServices ] = useState<boolean>(false);
    const [ openSubServices, setOpenSubServices ] = useState<boolean>(false);
    const [ openModality, setOpenModality ] = useState<boolean>(false);
    const [ serviceSelected, setServiceSelected ] = useState<any>('');
    const [ subServiceSelected, setSubServiceSelected ] = useState<any>('');
    const [ openSubtype, setOpenSubtype ] = useState<boolean>(false);
    const [ subtypeSelected, setSubtypeSelected ] = useState<any>('');
    const [ starterValue, setStarterValue ] = useState<any>('');
    const [ endValue, setEndValue ] = useState<any>('');
    const [ acceptStartDate, setAcceptStartDate ] = useState<any>(new Date().setFullYear(new Date().getFullYear() - 1));
    const [ acceptEndDate, setAcceptEndDate ] = useState<any>(new Date().setFullYear(new Date().getFullYear() + 1));
    const [ conclusionStartDate, setConclusionStartDate ] = useState<any>(new Date().setFullYear(new Date().getFullYear() - 1));
    const [ conclusionEndDate, setConclusionEndDate ] = useState<any>(new Date().setFullYear(new Date().getFullYear() + 1));
    const [ filterDate, setFilterDate ] = useState<any>();
    const [ awaitingPayload, setAwaitingPayload ] = useState<any>('');
    const [ canceledPayload, setCanceledPayload ] = useState<any>('');
    const [ servicesTypes, setServicesTypes] = useState<ServicesTypes[]>([]);

    const startsInitalDate = moment(starterDate).format('YYYY-MM-DD');
    const startsEndDate = moment(endDate).format('YYYY-MM-DD');
    const acceptInitalDate = moment(acceptStartDate).format('YYYY-MM-DD');
    const acceptFinishDate = moment(acceptEndDate).format('YYYY-MM-DD');
    const conclusionInitalDate = moment(conclusionStartDate).format('YYYY-MM-DD');
    const conclusionFinishDate = moment(conclusionEndDate).format('YYYY-MM-DD');

    const city = {
        control: (provided: Record<string, unknown>) => ({
            ...provided,
            height: 40,
            boxShadow: "none",
            cursor: 'pointer',
            border: '1px solid #EAEEF3',
            '&:hover': {
                border: '1px solid #04FDD0',
            },
        }),
        placeholder: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
            fontWeight: '400',
        }),
        singleValue: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
        }),
        option: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#5A646E',
            fontSize: '16px',
            fontWeight: '500',
        }),
    } 

    async function getSketched(page: number) {
        try {
            clarity.setEvent('pesquisa-propostas')
            setLoading(true);

            // const response = await api.get(`proposta?id_usuario=3513&page=${page}&limit=10`);
            const response = await api.get(`proposta?id_usuario=3513&page=${page}&limit=10&pesquisa=${searchProfiles}&id_categoria_de_servico=${categorieIdSelected}&id_comarca=${countieIdSelected}&valor_de=${starterValue}&valor_ate=${endValue}&solicitada_de=${startsInitalDate}&solicitada_ate=${startsEndDate}&aceite_de=${acceptInitalDate}&aceite_ate=${acceptFinishDate}&conclusao_de=${conclusionInitalDate}&conclusao_ate=${conclusionFinishDate}&tipo_audiencia=${subtypeSelected}&id_servico=${subServiceIdSelected}&tipo=proposta&modalidade=${modalitySelected}`);
            setSketchedProposals(response.data.result.data);
            setTotalSketchedProposals(response.data.result.pagination.total);
            setSketchedProposalPagination(response.data.result.pagination);

            if (response.data.status !== 'success') {
                // throw new Error();
                if (response.data.result.length === 0) {

                    throw new Error(response.data.result[0].message)

                } else {

                    throw new Error(response.data.message);
                }
            }

            setLoading(false);
            
        } catch (error: any) {

            console.log('log do erro pegando propostas de rascunho', error)

            toast.error(error.message, {
                duration: 3000,
                className: 'toasted',
                style: {
                    padding: '16px',
                    color: '#1A2858',
                },
                iconTheme: {
                    primary: '#F24343',
                    secondary: '#FFFAEE',
                },
            });
            
        }
    }

    async function getOpenProposals(page: any) {
        
        try {
            setLoading(true);

            const response = await api.get(`proposta?status=Pendente&pesquisa=${searchProfiles}&id_categoria_de_servico=${categorieIdSelected}&id_comarca=${countieIdSelected}&page=${page}&limit=10&valor_de=${starterValue}&valor_ate=${endValue}&solicitada_de=${startsInitalDate}&solicitada_ate=${startsEndDate}&aceite_de=${acceptInitalDate}&aceite_ate=${acceptFinishDate}&conclusao_de=${conclusionInitalDate}&conclusao_ate=${conclusionFinishDate}&tipo_audiencia=${subtypeSelected}&id_servico=${subServiceIdSelected}&tipo=proposta&modalidade=${modalitySelected}`);
            setOpenProposals(response.data.result.data);
            setTotalOpenProposals(response.data.result.pagination.total);
            setOpenProposalPagination(response.data.result.pagination);
            setAwaitingPayload(`status=Pendente&pesquisa=${searchProfiles}&id_categoria_de_servico=${categorieIdSelected}&id_comarca=${countieIdSelected}&page=${page}&limit=10&valor_de=${starterValue}&valor_ate=${endValue}&solicitada_de=${startsInitalDate}&solicitada_ate=${startsEndDate}&aceite_de=${acceptInitalDate}&aceite_ate=${acceptFinishDate}&conclusao_de=${conclusionInitalDate}&conclusao_ate=${conclusionFinishDate}&tipo_audiencia=${subtypeSelected}&tipo=proposta&modalidade=${modalitySelected}`)

            if (response.data.status !== 'success') {
                // throw new Error();
                if (response.data.result.length === 0) {

                    throw new Error(response.data.result[0].message)

                } else {

                    throw new Error(response.data.message);
                }
            }
            setLoading(false);
            
        } catch (err: any) {

            console.log('log do erro pegando propostas em aberto', err)

            toast.error(err.message, {
                duration: 3000,
                className: 'toasted',
                style: {
                    padding: '16px',
                    color: '#1A2858',
                },
                iconTheme: {
                    primary: '#F24343',
                    secondary: '#FFFAEE',
                },
            });

        }
    }

    async function getCanceledProposals(page: any) {
        
        try {

            // Working
            // proposta?status=Cancelada-Recusada&limit=10&page=${page}
            // Test
            // proposta?status=Cancelada-Recusada&id_categoria_de_servico=2&id_comarca=${countieIdSelected}&page=${page}&limit=10&valor_de=${starterValue}&valor_ate=${endValue}&solicitada_de=${startsInitalDate}&solicitada_ate=${startsEndDate}&aceite_de=${acceptInitalDate}&aceite_ate=${acceptFinishDate}&conclusao_de=${conclusionInitalDate}&conclusao_ate=${conclusionFinishDate}&tipo_audiencia=${subtypeSelected}


            setLoading(true);
            const response = await api.get(`proposta?status=Cancelada-Recusada&pesquisa=${searchProfiles}&id_categoria_de_servico=${categorieIdSelected}&id_comarca=${countieIdSelected}&page=${page}&limit=10&valor_de=${starterValue}&valor_ate=${endValue}&solicitada_de=${startsInitalDate}&solicitada_ate=${startsEndDate}&aceite_de=${acceptInitalDate}&aceite_ate=${acceptFinishDate}&conclusao_de=${conclusionInitalDate}&conclusao_ate=${conclusionFinishDate}&tipo_audiencia=${subtypeSelected}&id_servico=${subServiceIdSelected}&tipo=proposta&modalidade=${modalitySelected}`)
            setTotalCanceled(response.data.result.pagination.total)
            setCanceledProposals(response.data.result.data);
            setCanceledProposalPagination(response.data.result.pagination); 
            setCanceledPayload(`status=Cancelada-Recusada&pesquisa=${searchProfiles}&id_categoria_de_servico=${categorieIdSelected}&id_comarca=${countieIdSelected}&page=${page}&limit=10&valor_de=${starterValue}&valor_ate=${endValue}&solicitada_de=${startsInitalDate}&solicitada_ate=${startsEndDate}&aceite_de=${acceptInitalDate}&aceite_ate=${acceptFinishDate}&conclusao_de=${conclusionInitalDate}&conclusao_ate=${conclusionFinishDate}&tipo_audiencia=${subtypeSelected}&tipo=proposta&modalidade=${modalitySelected}`);           

            if (response.data.status !== 'success') {
                // throw new Error();
                if (response.data.result.length === 0) {

                    throw new Error(response.data.result[0].message)

                } else {

                    throw new Error(response.data.message);
                }
            }

            setLoading(false);

        } catch (err: any) {

            console.log('log do erro pegando propostas canceladas', err);

            setLoading(false);

            toast.error(err.message, {
                duration: 3000,
                className: 'toasted',
                style: {
                    padding: '16px',
                    color: '#1A2858',
                },
                iconTheme: {
                    primary: '#F24343',
                    secondary: '#FFFAEE',
                },
            });

        }
    }

    async function getOpenProposalsForUser(page: any) {

        try {

            
            setLoading(true);
            const response = await api.get(`proposta?status=Pendente&pesquisa=${searchProfiles}&id_usuario=${user.id}&id_categoria_de_servico=${categorieIdSelected}&id_comarca=${countieIdSelected}&page=${page}&limit=10&valor_de=${starterValue}&valor_ate=${endValue}&solicitada_de=${startsInitalDate}&solicitada_ate=${startsEndDate}&aceite_de=${acceptInitalDate}&aceite_ate=${acceptFinishDate}&conclusao_de=${conclusionInitalDate}&conclusao_ate=${conclusionFinishDate}&tipo_audiencia=${subtypeSelected}&id_servico=${subServiceIdSelected}&tipo=proposta&modalidade=${modalitySelected}`);
            setOpenProposals(response.data.result.data);
            setTotalOpenProposals(response.data.result.pagination.total);
            setOpenProposalPagination(response.data.result.pagination);

            if (response.data.status !== 'success') {
                // throw new Error();
                if (response.data.result.length === 0) {

                    throw new Error(response.data.result[0].message)

                } else {

                    throw new Error(response.data.message);
                }
            }
            setLoading(false);

        } catch (err: any) {

            console.log('log do erro pegando propostas em aberto', err)

            toast.error(err.message, {
                duration: 3000,
                className: 'toasted',
                style: {
                    padding: '16px',
                    color: '#1A2858',
                },
                iconTheme: {
                    primary: '#F24343',
                    secondary: '#FFFAEE',
                },
            });

        }
    }

    async function getCanceledProposalsForUser(page: any) {

        try {

            const response = await api.get(`proposta?status=Cancelada-Recusada&pesquisa=${searchProfiles}&id_usuario=${user.id}&id_categoria_de_servico=${categorieIdSelected}&id_comarca=${countieIdSelected}&page=${page}&limit=10&valor_de=${starterValue}&valor_ate=${endValue}&solicitada_de=${startsInitalDate}&solicitada_ate=${startsEndDate}&aceite_de=${acceptInitalDate}&aceite_ate=${acceptFinishDate}&conclusao_de=${conclusionInitalDate}&conclusao_ate=${conclusionFinishDate}&tipo_audiencia=${subtypeSelected}&id_servico=${subServiceIdSelected}&tipo=proposta&modalidade=${modalitySelected}`)
            setTotalCanceled(response.data.result.pagination.total)
            setCanceledProposals(response.data.result.data);
            setCanceledProposalPagination(response.data.result.pagination);

            if (response.data.status !== 'success') {
                // throw new Error();
                if (response.data.result.length === 0) {

                    throw new Error(response.data.result[0].message)

                } else {

                    throw new Error(response.data.message);
                }
            }

        } catch (err: any) {

            console.log('log do erro pegando propostas canceladas', err);

            toast.error(err.message, {
                duration: 3000,
                className: 'toasted',
                style: {
                    padding: '16px',
                    color: '#1A2858',
                },
                iconTheme: {
                    primary: '#F24343',
                    secondary: '#FFFAEE',
                },
            });

        }
    }

    async function getServices(id?: number) {
        try {
            setLoading(true);

            if (id) {
                const response = await api.get(`proposta-tipo/${id}`);
                setServicesTypes(response.data.result);
            } else {
                const response = await api.get(`proposta-tipo/`);
                setServicesTypes(response.data.result);
            }

            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log('log do erro response getServices', error);
        }
    }

    useEffect(() => {
        getServices();
    }, [])

    useEffect(() => {

        clarity.setEvent('pesquisa-propostas')
        
        setPageName('Propostas');
        setPageType('normal');

        if (countieIdSelected === undefined) {
            setCountieIdSelected('')
        }
        if (starterValue === undefined) {
            setStarterValue('')
        }
        if (endValue === undefined) {
            setEndValue(1000)
        }
        if (subtypeSelected === undefined) {
            setSubtypeSelected('')
        }
        if (categorieIdSelected === undefined) {
            setCategorieIdSelected('')
        }        

        if (user.profile !== 'comum') {

            getOpenProposals(1);
            getCanceledProposals(1);
            getSketched(1);

        } else {

            getOpenProposalsForUser(1);
            getCanceledProposalsForUser(1);

        }
        
    }, [countieIdSelected, subtypeSelected, categorieIdSelected, filterDate, searchProfiles, modalitySelected, subServiceSelected]);

    useEffect(() => {

        async function getCounties() {
            try {

                setLoading(true)
                const response = await api.get('comarca');

                const countieArray: { value: any, label: any, id_comarca: number }[] = []
                
                response.data.result.map((row: any) => (
                    countieArray.push({
                        label: `${row.uf} - ${row.cidade}`,
                        value: `${row.uf} - ${row.cidade}`,
                        id_comarca: row.id_comarca,
                    })
                ))
                    
                setDataCounties(countieArray);

                if (response.data.status !== 'success') {

                    throw new Error('Não foi possível encontrar comarcas.');

                }

                setLoading(false)

            } catch (error: any) {
                toast.error(error.message, {
                    duration: 3000,
                    className: 'toasted',
                    style: {
                        padding: '16px',
                        color: '#1A2858',
                    },
                    iconTheme: {
                        primary: '#F24343',
                        secondary: '#FFFAEE',
                    },
                });
                setLoading(false)
            }
        }

        getCounties();

    }, [filterProfiles])

    const onClickPage = (name: any, selectedItem: { selected: number; }) => {

        let pageNumber = selectedItem.selected + 1

        if (name === 'waiting') {
            getOpenProposals(pageNumber);
        }

        if (name === 'canceled') {
            getCanceledProposals(pageNumber)
        }

        if (name === 'sketched') {
            getSketched(pageNumber)
        }
        
    }

    function clearFilters() {
        setCountieSelected('');
        setCountieIdSelected('');
        setFilterProfiles('');
        setStarterDate(new Date().setFullYear(new Date().getFullYear() - 1));
        setEndDate(new Date().setFullYear(new Date().getFullYear() + 1))
        setStarterValue('');
        setEndValue('');
        setAcceptStartDate(new Date().setFullYear(new Date().getFullYear() - 1));
        setAcceptEndDate(new Date().setFullYear(new Date().getFullYear() + 1));
        setConclusionStartDate(new Date().setFullYear(new Date().getFullYear() - 1));
        setConclusionEndDate(new Date().setFullYear(new Date().getFullYear() + 1));
        setSubtypeSelected('');
        setCategorieIdSelected('');
        setServiceSelected('');
        setSubServiceSelected('')
        setFilterDate(new Date());
        setOpenValue(true);
        setOpenEndDate(true);
        setOpenAcceptDate(true);
        setOpenDate(true);
        setSubServiceIdSelected('');
        getServices();
    }

    function removeEspecificValueFromFilters(value: string) {
        const arrayFiltered = filterProfiles;
        const index = arrayFiltered.indexOf(value);
        const newArray = arrayFiltered.splice(index, 1);
        setFilterProfiles(arrayFiltered)
        console.log('log do novo array', newArray, arrayFiltered)

        if (value === 'value') {
            setOpenValue(true)
        } 

        if (value === 'starts') {
            setOpenDate(true);
        }

        if (value === 'accept') {
            setOpenAcceptDate(true);
        }

        if (value === 'conclusion') {
            setOpenEndDate(true);
        }

        if (value === 'servico') {
            setOpenServices(false);
            setCategorieIdSelected('');
        }

        if (value === 'subtype_service') {
            setOpenSubServices(false);
        }

        if (value === 'subtype') {
            setOpenSubtype(false);
        }

        if (value === 'modality') {
            setOpenModality(false);
        }

    }

    return (
        <Container>
            {
                user.profile !== 'comum' &&
                <ButtonIcon
                    Icon={<IconListProps />}
                    children={'Nova proposta'}
                    styles="op"
                    onClick={() => history.push('/nova-proposta')}
                />
            }

            <Menu>
                <div className="initial">
                    <ProposalsTab 
                        selected={selected}
                        setSelected={setSelected}
                        waiting={totalOpenProposals}
                        canceled={totalCanceled}
                        sketched={totalSketchedProposals}
                    />
                    
                </div>
                
                <ProposalsFilter 
                    filters={filterProfiles}
                    setFilters={setFilterProfiles}
                    setClean={clearFilters}
                    setSearchProfiles={(value: any) => setSearchProfiles(value.replace(/\D/g, ''))}
                    requestedPayload={
                        selected === '1'
                            ? awaitingPayload
                            : selected === '2'
                                ? canceledPayload
                                : ''
                    }
                />
            </Menu>

            <Filter>
                <div className="buttons">
                    {
                        filterProfiles.length > 0 &&
                        <div className="filter-applied">Filtros aplicados:</div>
                    }
                    {
                        filterProfiles.includes('comarca') &&
                        <div className="countie-selector">
                            <div
                                className="countie"
                                onClick={() => setOpenCounties(!openCounties)}
                            >
                                Comarca: 

                                {
                                    countieSelected &&
                                    <div className="countie-selected">
                                        <div>{countieSelected}</div>
                                        <div
                                            className="close-icon"
                                            onClick={() => {setCountieSelected(''); setCountieIdSelected('')}}
                                        >
                                            <IconClose />
                                        </div>
                                    </div>
                                }
                            </div>
                            {
                                countieIdSelected === '' &&
                                <div className="filters">
                                    <Select
                                        styles={city}
                                        components={{
                                            IndicatorSeparator: () => null
                                        }}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 8,
                                            colors: {
                                                ...theme.colors,
                                                primary25: '#DDFAF4',
                                                primary50: '#DDFAF4',
                                                primary: '#02E8BE',
                                            },
                                        })}
                                        name='id_comarca'
                                        placeholder="Selecionar"
                                        options={dataCounties}
                                        // defaultValue={uf && cidade ? { value: `${uf} - ${cidade}`, label: `${uf} - ${cidade}`} : ''}
                                        onChange={(e: any) => {setCountieSelected(e.value); setCountieIdSelected(e.id_comarca);}}
                                        // onChange={(selected: any) => handleOnChange('cidade_comarca', selected.value)}
                                    />
                                </div>
                            }
                        </div>
                    }

                    {
                        filterProfiles.includes('service') &&
                        <>
                            <div
                                className="servico"
                                onClick={() => setOpenServices(!openServices)}
                            >
                                Tipo de serviço <IconButtonArrowDown />
                                <div className={openServices ? 'servico-map' : 'servico-map no-show'}>
                                    <div
                                        className="filters"
                                        onClick={() => {
                                            setServiceSelected('Audiências'); 
                                            setCategorieIdSelected(1);
                                            getServices(1);
                                        }}
                                    >
                                        Audiências
                                    </div>
                                    <div
                                        className="filters"
                                        onClick={() => {
                                            setServiceSelected('Diligências'); 
                                            setCategorieIdSelected(2);
                                            getServices(2);
                                        }}
                                    >
                                        Diligências
                                    </div>
                                    <div
                                        className="filters"
                                        onClick={() => {
                                            setServiceSelected('Protocolos'); 
                                            setCategorieIdSelected(3);
                                            getServices(3);
                                        }}
                                    >
                                        Protocolos
                                    </div>
                                    <div
                                        className="filters"
                                        onClick={() => {
                                            setServiceSelected('Outros'); 
                                            setCategorieIdSelected(4);
                                            getServices(4);
                                        }}
                                    >
                                        Outros
                                    </div>
                                </div>
                            </div>
                            {
                                serviceSelected &&
                                <div className="servico-selected">
                                    <div>{serviceSelected}</div>
                                    <div
                                        className="close-icon"
                                        onClick={() => {
                                            setServiceSelected(''); 
                                            setCategorieIdSelected('');
                                            removeEspecificValueFromFilters('service');
                                        }}
                                    >
                                        <IconClose />
                                    </div>
                                </div>
                            }                            
                        </>
                    }

                    {
                        filterProfiles.includes('subtype_service') &&
                        <>
                            <div
                                className="servico"
                                onClick={() => setOpenSubServices(!openSubServices)}
                            >
                                Subtipo de serviço <IconButtonArrowDown />
                                <div className={openSubServices ? 'servico-map' : 'servico-map no-show'}>
                                    {servicesTypes.map((row: ServicesTypes) =>  (
                                        <div
                                            className="filters"
                                            onClick={() => { 
                                                setSubServiceSelected(row.titulo); 
                                                setSubServiceIdSelected(row.id_servico)
                                            }}
                                            key={row.id_servico}
                                        >
                                            {row.titulo}
                                        </div>                                    
                                    ))}
                                </div>
                            </div>
                            {
                                subServiceSelected &&
                                <div className="servico-selected">
                                    <div>{subServiceSelected}</div>
                                    <div
                                        className="close-icon"
                                            onClick={() => { 
                                                setSubServiceSelected(''); 
                                                setSubServiceIdSelected('');
                                                removeEspecificValueFromFilters('subtype_service');
                                            }}
                                    >
                                        <IconClose />
                                    </div>
                                </div>
                            }                            
                        </>
                    }

                    {
                        filterProfiles.includes('modality') &&
                        <>
                            <div
                                className="servico"
                                onClick={() => setOpenModality(!openModality)}
                            >
                                Modalidade <IconButtonArrowDown />
                                <div className={openModality ? 'servico-map' : 'servico-map no-show'}>
                                    <div
                                        className="filters"
                                        onClick={() => setModalitySelected('Presencial')}
                                    >
                                        Presencial
                                    </div>
                                    <div
                                        className="filters"
                                        onClick={() => setModalitySelected('Online')}
                                    >
                                        Virtual
                                    </div>                                    
                                </div>
                            </div>
                            {
                                modalitySelected &&
                                <div className="servico-selected">
                                    <div>
                                        {
                                            modalitySelected === 'Online'
                                            ? 'Virtual'
                                            : modalitySelected
                                        }
                                    </div>
                                    <div
                                        className="close-icon"
                                        onClick={() => {
                                            setModalitySelected('');
                                            removeEspecificValueFromFilters('modality');
                                        }}
                                    >
                                        <IconClose />
                                    </div>
                                </div>
                            }                            
                        </>
                    }

                    {
                        filterProfiles.includes('subtype') &&
                        <>
                            <div
                                className="subtype"
                                onClick={() => setOpenSubtype(!openSubtype)}
                            >
                                Subtipos de audiência <IconButtonArrowDown />
                                <div className={openSubtype ? 'subtype-map' : 'subtype-map no-show'}>
                                    <div
                                        className="filters"
                                        onClick={() => setSubtypeSelected('AIJ')}
                                    >
                                        AIJ
                                    </div>
                                    <div
                                        className="filters"
                                        onClick={() => setSubtypeSelected('Conciliação')}
                                    >
                                        Conciliação
                                    </div>
                                    <div
                                        className="filters"
                                        onClick={() => setSubtypeSelected('Instruções e Julgamento')}
                                    >
                                        Instruções e Julgamentos
                                    </div>
                                    <div
                                        className="filters"
                                        onClick={() => setSubtypeSelected('Justificação')}
                                    >
                                        Justificação
                                    </div>
                                    <div
                                        className="filters"
                                        onClick={() => setSubtypeSelected('Procon')}
                                    >
                                        Procon
                                    </div>
                                    <div
                                        className="filters"
                                        onClick={() => setSubtypeSelected('UNA')}
                                    >
                                        UNA
                                    </div>
                                    <div
                                        className="filters"
                                        onClick={() => setSubtypeSelected('Sustentação Oral')}
                                    >
                                        Sustentação Oral
                                    </div>
                                </div>
                            </div>
                            {
                                subtypeSelected &&
                                <div className="subtype-selected">
                                    <div>{subtypeSelected}</div>
                                    <div
                                        className="close-icon"
                                        onClick={() => {
                                            setSubtypeSelected('');
                                            removeEspecificValueFromFilters('subtype');
                                        }}
                                    >
                                        <IconClose />
                                    </div>
                                </div>
                            }    
                        </>
                    }

                    {
                        filterProfiles.includes('value') &&
                        <div className={openValue ? 'value' : 'value no-show'} >
                            <div className='value-map'>
                                <div className="start">
                                    <div className="filters">
                                        Entre:
                                    </div>

                                    <div className="money">
                                        <InputMoney
                                            name="from"
                                            placeholder="R$ 00,00"
                                            mask="R$ 999.999,99"
                                            value={'sem dados'}
                                            onChangeText={(value) => setStarterValue(value.replace('R$', '').replace(',', '.'))}
                                            className='small'
                                        />
                                    </div>
                                </div>
                                <div className="start">
                                    <div className="filters">
                                        E:
                                    </div>

                                    <div className="money">
                                        <InputMoney
                                            name="to"
                                            placeholder="R$ 00,00"
                                            mask="R$ 999.999,99"
                                            value={'sem dados'}
                                            onChangeText={(value) => setEndValue(value.replace('R$','').replace(',', '.'))}
                                            className='small'
                                        />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <ButtonDefault
                                    className="save"
                                    onClick={() => {setOpenValue(false); setFilterDate(new Date())}}
                                >
                                    Aplicar intervalo
                                </ButtonDefault>
                            </div>
                            
                        </div>
                    }
                    
                    {
                        starterValue && endValue &&
                        <div className="value-selected">
                            <div>Valor:</div>
                            <div>R$ {starterValue} a R$ {endValue.replace('.', ',')}</div>
                            <div
                                className="close-icon"
                                style={{ cursor: 'pointer' }}
                                onClick={() => { 
                                    setStarterValue(''); 
                                    setEndValue(''); 
                                    removeEspecificValueFromFilters('value');
                                    setFilterDate(new Date());
                                }}
                            >
                                <IconClose />
                            </div>
                        </div>
                    }    

                    {
                        filterProfiles.includes('starts') &&
                        <div className={openDate ? 'date-map' : 'date-map no-show'}>
                            <div className="select-date">
                                <div className="start-date">
                                    <div className="filters">
                                        Data de inicio:
                                    </div>

                                    <div className="date-field">
                                        <DatePicker onChange={setStarterDate} value={starterDate} calendarIcon={null} disableCalendar={true} clearIcon={null} />
                                    </div>
                                </div>
                                <div className="start-date">
                                    <div className="filters">
                                        Data de fim:
                                    </div>

                                    <div className="date-field">
                                        <DatePicker onChange={setEndDate} value={endDate} calendarIcon={null} disableCalendar={true} clearIcon={null} />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <ButtonDefault
                                    className="save"
                                    onClick={() => {setOpenDate(false); setFilterDate(new Date())}}
                                >
                                    Aplicar data
                                </ButtonDefault>
                            </div>
                        </div>
                    }

                    {
                        filterProfiles.includes('starts') && starterDate && endDate &&
                        <div className="value-selected">
                            <div>Solicitada:</div>
                                <div style={{ cursor: 'pointer' }} onClick={() => setOpenDate(true)}>
                                De <Moment locale='pt-br' format="DD/MM/YYYY">{starterDate}</Moment> a <Moment locale='pt-br' format="DD/MM/YYYY">{endDate}</Moment>
                            </div>
                            <div
                                className="close-icon"
                                style={{ cursor: 'pointer' }}
                                onClick={() => { 
                                    setStarterDate(new Date().setFullYear(new Date().getFullYear() - 1)); 
                                    setEndDate(new Date().setFullYear(new Date().getFullYear() + 1)); 
                                    removeEspecificValueFromFilters('starts');
                                    setFilterDate(new Date());
                                }}
                            >
                                <IconClose />
                            </div>
                        </div>
                    }   

                    {
                        filterProfiles.includes('accept') &&
                        <div className={openAcceptDate ? 'date-map' : 'date-map no-show'}>
                            <div className="select-date">
                                <div className="start-date">
                                    <div className="filters">
                                        Data de inicio:
                                    </div>

                                    <div className="date-field">
                                        <DatePicker onChange={setAcceptStartDate} value={acceptStartDate} calendarIcon={null} disableCalendar={true} clearIcon={null} />
                                    </div>
                                </div>
                                <div className="start-date">
                                    <div className="filters">
                                        Data de fim:
                                    </div>

                                    <div className="date-field">
                                        <DatePicker onChange={setAcceptEndDate} value={acceptEndDate} calendarIcon={null} disableCalendar={true} clearIcon={null} />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <ButtonDefault
                                    className="save"
                                    onClick={() => {setOpenAcceptDate(false); setFilterDate(new Date())}}
                                >
                                    Aplicar data
                                </ButtonDefault>
                            </div>
                        </div>
                    }

                    {
                        filterProfiles.includes('accept') && acceptStartDate && acceptEndDate &&
                        <div className="value-selected">
                            <div>Aceite:</div>
                                <div style={{ cursor: 'pointer' }} onClick={() => setOpenAcceptDate(true)}>
                                De <Moment locale='pt-br' format="DD/MM/YYYY">{acceptStartDate}</Moment> a <Moment locale='pt-br' format="DD/MM/YYYY">{acceptEndDate}</Moment>
                            </div>
                            <div
                                className="close-icon"
                                style={{ cursor: 'pointer' }}
                                onClick={() => { 
                                    setAcceptStartDate(new Date().setFullYear(new Date().getFullYear() - 1)); 
                                    setAcceptEndDate(new Date().setFullYear(new Date().getFullYear() + 1));  
                                    removeEspecificValueFromFilters('accept');
                                    setFilterDate(new Date());
                                }}
                            >
                                <IconClose />
                            </div>
                        </div>
                    }   

                    {
                        filterProfiles.includes('conclusion') &&
                        <div className={openEndDate ? 'conclusion-map' : 'conclusion-map no-show'}>
                            <div className="select-date">
                                <div className="start-date">
                                    <div className="filters">
                                        Data de inicio:
                                    </div>

                                    <div className="date-field">
                                        <DatePicker onChange={setConclusionStartDate} value={conclusionStartDate} calendarIcon={null} disableCalendar={true} clearIcon={null} />
                                    </div>
                                </div>
                                <div className="start-date">
                                    <div className="filters">
                                        Data de fim:
                                    </div>

                                    <div className="date-field">
                                        <DatePicker onChange={setConclusionEndDate} value={conclusionEndDate} calendarIcon={null} disableCalendar={true} clearIcon={null} />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <ButtonDefault
                                    className="save"
                                    onClick={() => {setOpenEndDate(false); setFilterDate(new Date())}}
                                >
                                    Aplicar data
                                </ButtonDefault>
                            </div>
                        </div>
                    }

                    {
                        filterProfiles.includes('conclusion') && conclusionStartDate && conclusionEndDate &&
                        <div className="value-selected">
                            <div>Conclusão:</div>
                            <div style={{ cursor: 'pointer' }} onClick={() => setOpenEndDate(true)}>
                                De <Moment locale='pt-br' format="DD/MM/YYYY">{conclusionStartDate}</Moment> a <Moment locale='pt-br' format="DD/MM/YYYY">{conclusionEndDate}</Moment>
                            </div>
                            <div
                                className="close-icon"
                                style={{ cursor: 'pointer' }}
                                onClick={() => { 
                                    setConclusionStartDate(new Date().setFullYear(new Date().getFullYear() - 1)); 
                                    setConclusionEndDate(new Date().setFullYear(new Date().getFullYear() + 1)); 
                                    removeEspecificValueFromFilters('conclusion');
                                    setFilterDate(new Date());
                                }}
                            >
                                <IconClose />
                            </div>
                        </div>
                    } 

                </div>

                {
                    filterProfiles.length > 0 &&
                    <div className="results">
                        {
                            filterProfiles !== undefined && selected === '1' &&
                            `${openProposals?.length} resultados:`
                        }
                        {
                            filterProfiles !== undefined && selected === '2' &&
                            `${canceledProposals?.length} resultados:`
                        }                        
                        {
                            filterProfiles !== undefined && selected === '3' &&
                            `${sketchedProposals?.length} resultados:`
                        }                        
                    </div>
                }

            </Filter>
            
            {
                loading &&
                <Oval
                    height={150}
                    width={150}
                    color="#FA9604"
                    wrapperStyle={{}}
                    wrapperClass="loader"
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="#FA9604"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                />
            }

            {
                selected === '1' && openProposals?.length > 0 &&
                openProposals?.map((row: any) => (
                    <ProposalsCard 
                        proposal={row}
                        key={row.id_proposta}
                    />
                ))
            }

            {
                selected === '1' && openProposals?.length < 1 &&
                <div className="zero">Sem propostas disponíveis no momento.</div>
            }

            {
                selected === '2' && canceledProposals?.length > 0 &&
                canceledProposals?.map((row: any) => (
                    <ProposalsCard 
                        proposal={row}
                        key={row.id_proposta}
                    />
                ))
            }

            {
                selected === '2' && canceledProposals?.length < 1 &&
                <div className="zero">Sem propostas disponíveis no momento.</div>
            }

            {
                selected === '3' &&
                sketchedProposals.map((row: any) => (
                    <ProposalsCard 
                        proposal={row}
                        key={row.id_proposta}
                    />
                ))
            }

            {
                selected === '3' && sketchedProposals?.length < 1 &&
                <div className="zero">Sem propostas disponíveis no momento.</div>
            }

            {
                selected === '1' && openProposalPagination && openProposals?.length > 0 &&
                <Paginate>
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel={<IconAdvancePagination />}
                        previousLabel={<IconBackPagination />}
                        onPageChange={(value) => onClickPage('waiting', value)}
                        pageRangeDisplayed={1}
                        marginPagesDisplayed={1}
                        pageCount={openProposalPagination?.lastPage}
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="back-page"
                        previousLinkClassName="back-link-page"
                        nextClassName="next-page"
                        nextLinkClassName="next-link-page"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        activeLinkClassName="active"
                    />
                </Paginate>
            }

            {
                selected === '2' && canceledProposalsPagination && canceledProposals?.length > 0 &&
                <Paginate>
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel={<IconAdvancePagination />}
                        previousLabel={<IconBackPagination />}
                        onPageChange={(value) => onClickPage('canceled', value)}
                        pageRangeDisplayed={1}
                        marginPagesDisplayed={1}
                        pageCount={canceledProposalsPagination?.lastPage}
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="back-page"
                        previousLinkClassName="back-link-page"
                        nextClassName="next-page"
                        nextLinkClassName="next-link-page"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        activeLinkClassName="active"
                    />
                </Paginate>
            }

            {
                selected === '3' && sketchedProposalPagination && sketchedProposals?.length > 0 &&
                <Paginate>
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel={<IconAdvancePagination />}
                        previousLabel={<IconBackPagination />}
                        onPageChange={(value) => onClickPage('sketched', value)}
                        pageRangeDisplayed={1}
                        marginPagesDisplayed={1}
                        pageCount={sketchedProposalPagination?.lastPage}
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="back-page"
                        previousLinkClassName="back-link-page"
                        nextClassName="next-page"
                        nextLinkClassName="next-link-page"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        activeLinkClassName="active"
                    />
                </Paginate>
            }

            <div onClick={() => toast.remove()}>
                <Toaster />
            </div>
        </Container>
    )
}