/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
// React
import { clarity } from 'react-microsoft-clarity';


import { useEffect, useRef, useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";

// Libraries
import toast, { Toaster } from 'react-hot-toast';
import Moment from 'react-moment';
import moment from "moment";
import 'moment/locale/pt-br';
import { Oval } from 'react-loader-spinner';
import Select from 'react-select';
import DateTimePicker from 'react-datetime-picker';


// Services
import axios from "axios";
import api from "../../services/api";
import { useAuth } from "../../contexts/UserContext";

// Icons
import {
    IconClock,
    IconClose,
    IconServiceValue,
    IconThreeDots,
    IconThreeDotsH,
    IconTimer,
    IconUser,
    IconStatus,
    IconSearch,
    IconFloppyDisk,
    IconFinish,
    IconAttachment,
    IconAccept,
    IconWrong,
    IconEdit,
    IconInstruction,
    IconWarning,
    IconPlus,
    IconPendency,
    IconUpload,
    IconTrash,
    IconActivityOne,
    IconActivityTwo,
    IconOrientation,
    IconAdvanceMoney,
    IconInProgress,
    IconArrowDown,
    IconArrowUp,
    IconDeleteTwo,
    IconDeleteOne,
    IconDuplicate,
    IconDownload
} from "../../components/icons";

//  Components
import ButtonIcon from "../../components/form/ButtonIcon";
import ModalDefault from "../../components/ModalDefault";
import ButtonDefault from "../../components/form/ButtonDefault";
import { ModalContactHead, ModalcontactMain } from "../../components/SearchProfessionalProposal/styles";
import InputDefault from "../../components/form/InputDefault";
import DemandFiles from "../../components/Ui/DemandFiles";
import { ProfessionalTypeNecessary } from "../../components/Data/ProfessionalTypeNecessary";
import InputMoney from "../../components/form/InputMoney";
import ProfessionalOrientations from "../OrientationsProfessional";

//  Styles
import {
    Buttons,
    Container,
    DeadlineInfos,
    DropDownMenu,
    HistoryTimeLine,
    HistoryTitle,
    InfoCard,
    ModalButtons,
    ModalJustification,
    ModalTitle,
    ModalWrapper,
    Orientations,
    DemandAdress,
    DemandDetails,
    DemandInfos,
    DemandLeft,
    ServiceTags,
    Status,
    StatusHeader,
    Pendencies,
    Pendency,
    ModalPendenciesType,
    ModalAnswerType,
    ModalResult,
    OrientationsCard,
    OrientationsFiles
} from "./styles";
import { ModalSearch } from "../ProposalEdit/styles";

// Data
import { EstadosBrasileiros } from "../../components/Data/StatesData";
import { CivilHearing } from "../../components/Data/CivilHearing";
import { CriminalHearing } from "../../components/Data/CriminalHearing";
import { LaborHearing } from "../../components/Data/LaborHearing";
import { ModalityAudience } from "../../components/Data/ModalityAudience";



interface DemandProps {
    id_usuario: any,
    id_servico: any,
    id_comarca: any,
    tags: any,
    arquivos: any,
    numero_processo: string,
    nome_cliente: string,
    valor_cliente: string,
    codigo_cliente: string,
    prazo_aceite: string,
    prazo_para_conclusao: string,
    dias_uteis_para_conclusao: string,
    orientacoes: string,
    tipo_audiencia: string,
    data: any,
    profissional_necessario: string,
    modalidade: string,
    endereco_virtual: string,
    rua: string,
    bairro: string,
    cep: string,
    numero: string,
    complemento: string,
    tipo_cartorio: string,
    pagina_inicial: string,
    pagina_final: string,
    valor_profissional?: string,
    pendencias?: any,
    pendente_de_orientacao?: any,
    tipo: string,
}

interface PendencyCounterProps {
    resolvidas?: any,
    aceitas?: any,
    recusadas?: any,
    aguardando?: any,
    responder?: any
}

interface LocationParams {
    type: string,
}

export default function DemandInfo() {
    const { setPageName, user, setPageType } = useAuth();
    const params = useParams<any>();
    const proposalId = params.id;
    const location = useLocation<LocationParams>();
    const history = useHistory();
    const historyRef = useRef<any>();
    const menuRef = useRef<any>();
    const [ loading, setLoading] = useState<any>(false);
    const [ demandInitial, setDemandInitial ] = useState<any>();
    const [ professional, setProfessional ] = useState<any>();
    const [ modalContact, setModalContact ] = useState<any>();
    const [ openHistory, setOpenHistory ] = useState<any>();
    const [ openMenu, setOpenMenu ] = useState<any>();
    const [ finishDemand, setFinishDemand ] = useState<any>();
    const [ demandResult, setDemandResult ] = useState<any>('');
    const [ justicationText, setJustificationText ] = useState<any>();
    const [ secondStage, setSecondStage ] = useState<any>();
    const [ editDemand, setEditDemand ] = useState<any>();
    const [ counties, setCounties ] = useState<any>();
    const [ cepCounty, setCepCounty ] = useState<any>();
    const [ cepAddress, setCepAddress ] = useState<any>();
    const [ DTODemand, setDTODemand ] = useState<DemandProps>({
        id_usuario: '',
        id_servico: '',
        id_comarca: '',
        tags: '',
        arquivos: '',
        numero_processo: '',
        nome_cliente: '',
        valor_cliente: '',
        codigo_cliente: '',
        prazo_aceite: '',
        prazo_para_conclusao: '',
        dias_uteis_para_conclusao: "",
        orientacoes: '',
        tipo_audiencia: '',
        data: '',
        profissional_necessario: '',
        modalidade: '',
        endereco_virtual: '',
        rua: cepAddress,
        bairro: cepCounty,
        cep: '',
        numero: '',
        complemento: '',
        tipo_cartorio: '',
        pagina_inicial: '',
        pagina_final: '',
        valor_profissional: '',
        tipo: 'demanda',
    });
    const [ dateHearing, setDateHearing ] = useState<any>();
    const [ modalTags, setModalTags ] = useState<any>();
    const [ tag, setTag ] = useState<any>();
    const [ filteredTags, setFilteredTags ] = useState<any>();
    const [ selectedTags, setSelectedTags ] = useState<any>([]);
    const [ historyProposal, setHistoryProposal ] = useState<any>();
    const [ handDemand, setHandDemand ] = useState<any>();
    const [ cancelDemandModal, setCancelDemandModal ] = useState<any>();
    const [ demandTags, setDemandTags ] = useState<any[]>([]);
    const [ demandFiles, setDemandFiles ] = useState<any[]>([]);
    const [ countieInfo, setCountieInfo ] = useState<any>();
    const [ modalPendencies, setModalPendencies ] = useState<any>();
    const [ pendencieType, setPendencieType ] = useState<any>('');
    const [ modalAnswerPendency, setModalAnswerPendency ] = useState<any>();
    const [ answerType, setAnswerType ] = useState<any>('');
    const [ allowPayment, setAllowPayment ] = useState<any>('nada');
    const [ pendencyInfos, setPendencyInfos ] = useState<any>();
    const [ moneyModal, setMoneyModal ] = useState<boolean>(false);
    const [ openPendecy, setOpenPendency ] = useState<boolean>(false);
    const [ pendencyCounters, setPendencyCounters ] = useState<PendencyCounterProps>();
    const [ pendencyValue, setPendencyValue ] = useState<any>();
    const [ selectedRadio, setSelectedRadio ] = useState<boolean>();
    const [ modalOrientations, setModalOrientations ] = useState<any>();
    const [ raiseValue, setRaiseValue ] = useState<number>();
    const [ update, setUpdate ] = useState<any>();
    const reader = new FileReader();    
    const [ conclusionFiles, setConclusionFiles ] = useState<any[]>([]);

    const styles = {
        control: (provided: Record<string, unknown>) => ({
            ...provided,
            height: 48,
            boxShadow: "none",
            cursor: 'pointer',
            // border: advancePageError?.uf ? '1px solid #FDB141' : '1px solid #EAEEF3',
            '&:hover': {
                border: '1px solid #04FDD0',
            },
        }),
        placeholder: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
            fontWeight: '400',
        }),
        singleValue: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
        }),
        option: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#5A646E',
            fontSize: '16px',
            fontWeight: '500',
        }),
    }

    const modalities = {
        control: (provided: Record<string, unknown>) => ({
            ...provided,
            height: 48,
            boxShadow: "none",
            cursor: 'pointer',
            // border: advancePageError?.modalidade ? '1px solid #FDB141' : '1px solid #EAEEF3',
            '&:hover': {
                border: '1px solid #04FDD0',
            },
        }),
        placeholder: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
            fontWeight: '400',
        }),
        singleValue: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
        }),
        option: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#5A646E',
            fontSize: '16px',
            fontWeight: '500',
        }),
    }

    async function getDemand() {
        try {

            setLoading(true);
            const response = await api.get('proposta/' + proposalId);
            setDemandInitial(response.data.result);
            setDTODemand(response.data.result);
            setDemandTags(response.data.result.tags);
            setDemandFiles(response.data.result.arquivos);
            setDateHearing(new Date(response.data.result.data));
                        
            if (response.data.status !== 'success') {
                throw new Error();
            }
            
            if (
                response.data.result.orientacoes.length > 0 && response.data.result.status_orientacao === "Aguardando Ciência" 
                && user.profile === 'comum' && response.data.result.status !== 'Concluida' && response.data.result.status !== 'Finalizada'
                && response.data.result.status !== 'Cancelada'
            ) {
                setModalOrientations(true);                
            }

            const id_user = response.data.result.id_usuario

            const responseProfessional = await api.get('usuario/' + id_user);
            setProfessional(responseProfessional.data.result);

            if (responseProfessional.data.status !== 'success') {
                throw new Error();
            }

           const responseHistory = await api.get('historico-proposta/' + proposalId);
           setHistoryProposal(responseHistory.data.result);

            if (responseHistory.data.status !== 'success') {
                throw new Error();
            }

            const countieId = response.data.result.id_comarca
            const responseCountie = await api.get('comarca/' + countieId)
            setCountieInfo(responseCountie.data.result);

            if (responseCountie.data.status !== 'success') {

                throw new Error(responseCountie.data.message)

            }


            setLoading(false);

        } catch (error: any) {

            console.log('log do erro pegando propostas', error)

            toast.error(error, {
                duration: 3000,
                className: 'toasted',
                style: {
                    padding: '16px',
                    color: '#1A2858',
                },
                iconTheme: {
                    primary: '#F24343',
                    secondary: '#FFFAEE',
                },
            });

            setLoading(false);
        }

    }

    async function getPendencyCounters() {
        try {

            setLoading(true);

            const response = await api.get(`pendencia-contadores/` + proposalId);
            setPendencyCounters(response.data.result);
            
            if (response.data.status !== 'success') {

                if (response.data.message === '1 Erros encontrados') {

                    throw new Error(response.data.result[0].message);

                } else {

                    throw new Error(response.data.message);
                }

            }

            setLoading(false);

        } catch (error: any) {

            console.log('log do erro pegando contadores de pendência', error)

            toast.error(error, {
                duration: 3000,
                className: 'toasted',
                style: {
                    padding: '16px',
                    color: '#1A2858',
                },
                iconTheme: {
                    primary: '#F24343',
                    secondary: '#FFFAEE',
                },
            });

            setLoading(false);
            
        }
        
    }

    useEffect(() => {
        setPageName(`Demanda #${proposalId}`);

        setPageType('special');

        getDemand();  
        
        getPendencyCounters();

    }, [update, editDemand])

    async function duplicateDemand() {
        try {

            setLoading(true)

            const response = await api.delete('proposta-reaproveitar/' + proposalId)

            if (response.data.status !== 'success') {
                if (response.data.result[0] === undefined) {
                    throw new Error(response.data.message);

                } else {
                    throw new Error(response.data.result[0].message);
                }
            } else {

                setFinishDemand(false);
                history.push({
                    pathname: '/formulario-proposta',
                    state: {
                        name: 'Reuse',
                        proposal: response.data.result,
                    }
                })

            }


            setLoading(false);

        } catch (error: any) {

            console.log('log do erro duplicando proposta', error)
            setLoading(false);

        }
    }

    async function cancelDemand() {
        try {

            setLoading(true)

            if (justicationText === '') {
                throw new Error('Justifique sua decisão antes de cancelar a demanda')
            }

            const response = await api.delete('proposta/' + proposalId)

            if (response.data.status !== 'success') {

                throw new Error(response.data.result[0].message)

            } else {
                toast.success('Demanda cancelada com sucesso', {
                    duration: 3000,
                    className: 'toasted',
                    style: {
                        padding: '16px',
                        color: '#1A2858',
                        background: '#E7F8EB',
                        border: '2px solid #9EE4AF',
                    }
                });

                setCancelDemandModal(false);
                setSecondStage(false);

            }


            setLoading(false);

        } catch (error: any) {

            console.log('log do erro cancelando proposta', error)
            setLoading(false);

            toast.error(error.message, {
                duration: 3000,
                className: 'toasted',
                style: {
                    padding: '16px',
                    color: '#1A2858',
                },
                iconTheme: {
                    primary: '#F24343',
                    secondary: '#FFFAEE',
                },
            });

        }
    }

    function beforeCancelDemand() {
        
        if (justicationText === undefined) {
            
            toast.error('Justifique sua decisão antes de prosseguir.', {
                duration: 3000,
                className: 'toasted',
                style: {
                    padding: '16px',
                    color: '#1A2858',
                },
                iconTheme: {
                    primary: '#F24343',
                    secondary: '#FFFAEE',
                },
            });

        } else {

            setSecondStage(true);

        }

    }

    async function getCounties(selected: any) {

        try {

            setLoading(true);

            const response = await api.get(`comarca?uf=${selected.value}`)

            if (response.data.status !== 'success') {
                throw new Error();
            }
            const countieArray: { value: any, label: any }[] = []

            response.data.result.map((row: any) => (
                countieArray.push({
                    label: row.cidade,
                    value: row.id_comarca
                })
            ))

            setCounties(countieArray);
            setCountieInfo({
                uf: selected.value,
                cidade: '',
            });

            setLoading(false);

        } catch (error: any) {

            toast.error(error, {
                duration: 3000,
                className: 'toasted',
                style: {
                    padding: '16px',
                    color: '#1A2858',
                },
                iconTheme: {
                    primary: '#F24343',
                    secondary: '#FFFAEE',
                },
            });
        }

    }

    function handleOnChange(name: any, value: any) {
        console.log('log do handleChange', name, value)

        const newDTO: any = DTODemand
        newDTO[name] = value
        setDTODemand({ ...newDTO })

    }

    async function consultCep(name: any, value: any) {

        try {

            handleOnChange('cep', value);
            const response = await axios.get(`https://viacep.com.br/ws/${value}/json/`)
            const data = response.data
            setCepCounty(data.bairro);
            setCepAddress(data.logradouro);

            handleOnChange('bairro', data.bairro);
            handleOnChange('rua', data.logradouro);

        } catch (err: any) {

            console.log('err, cep', err)

        }

    }

    async function getTags() {
        try {

            if (!tag) {
                toast(<div className="warning"><span><IconWarning /></span> Digite uma TAG antes de pesquisar.</div>,
                    {
                        duration: 3000,
                        style: {
                            padding: '16px',
                            minWidth: '400px',
                            color: '#1A2858',
                            background: '#E4F5FF',
                            border: '2px solid #88D3FE',
                        },
                    });
                throw new Error('Digite uma TAG antes de pesquisar')
            }

            setLoading(true);

            const response = await api.get(`tag-find/${tag}?like=true`);
            setFilteredTags(response.data.result);

            if (response.data.status !== 'success') {
                throw new Error()
            }

            setLoading(false);

        } catch (error: any) {
            console.log('error log getting tags', error)
            setLoading(false);
        }

    }

    function searchTags(event: any) {

        if (event.key === 'Enter') {
            getTags()
        }
    }

    function setNewTags(tag: any) {

        setSelectedTags((selectedTags: any) => [...selectedTags, tag]);

        setTag('');
        setFilteredTags('');

        setDemandTags((demandTags: any) => [...demandTags, tag])

        setModalTags(false);
    }

    async function sendFiles(e: any) {        
        try {
            setLoading(true)

            if (e.target.id === 'conclusion') {
                
                const formData = new FormData();
                formData.append('arquivo', e.target.files[0]);

                reader.addEventListener("load", () => {});

                reader.readAsDataURL(e.target.files[0]);
              
                const response = await api.post('fileupload', formData)

                setDemandFiles(demandFiles => [...demandFiles, {
                    arquivo_final: "não",
                    caminho: response.data.name
                }]);

                setConclusionFiles(conclusionFiles => [...conclusionFiles, response.data.name]);

                handleOnChange('arquivos', demandFiles);


            } else if (e.target.id === 'finish') {
                
                const formData = new FormData();
                formData.append('arquivo', e.target.files[0]);

                reader.addEventListener("load", () => {});

                reader.readAsDataURL(e.target.files[0]);
              
                const response = await api.post('fileupload', formData)

                setDemandFiles(demandFiles => [...demandFiles, {
                    arquivo_final: "não",
                    caminho: response.data.name
                }]);

                setConclusionFiles(conclusionFiles => [...conclusionFiles, response.data.name]);

                handleOnChange('arquivos', demandFiles);

                
            } else {

                const formData = new FormData();
                formData.append('arquivo', e.target.files[0]);
    
                reader.addEventListener("load", () => {
                });
    
                reader.readAsDataURL(e.target.files[0]);    
    
                const response = await api.post('fileupload', formData)
    
                setDemandFiles(demandFiles => [...demandFiles, {
                    arquivo_final: "não",
                    caminho: response.data.name
                }]);

                handleOnChange('arquivos', demandFiles);                
            }
            

            setLoading(false)

        } catch (error: any) {

            // toast.error(error, {
            //     duration: 3000,
            //     className: 'toasted',
            //     style: {
            //         padding: '16px',
            //         color: '#1A2858',
            //     },
            //     iconTheme: {
            //         primary: '#F24343',
            //         secondary: '#FFFAEE',
            //     },
            // });

        }
    }

    async function updateDemand() {
        try {

            const newDate = moment(dateHearing).format("YYYY-MM-DD HH:mm:ss")
            const deadline = moment(DTODemand.prazo_aceite).format("YYYY-MM-DD HH:mm:ss");
            const conclusionDate = moment(DTODemand.prazo_para_conclusao).format("YYYY-MM-DD HH:mm:ss");

            const proposalUpdated = {
                id_usuario: DTODemand.id_usuario,
                id_servico: DTODemand.id_servico,
                id_comarca: DTODemand.id_comarca,
                tags: demandTags,
                arquivos: demandFiles,
                numero_processo: DTODemand.numero_processo,
                nome_cliente: DTODemand.nome_cliente,
                valor_cliente: DTODemand.valor_cliente,
                codigo_cliente: DTODemand.codigo_cliente,
                prazo_aceite: deadline,
                prazo_para_conclusao: conclusionDate,
                dias_uteis_para_conclusao: DTODemand.dias_uteis_para_conclusao,
                orientacoes: DTODemand.orientacoes,
                tipo_audiencia: DTODemand.tipo_audiencia,
                data: newDate,
                profissional_necessario: DTODemand.profissional_necessario,
                modalidade: DTODemand.modalidade,
                endereco_virtual: DTODemand.endereco_virtual,
                rua: DTODemand.rua,
                bairro: DTODemand.bairro,
                cep: DTODemand.cep,
                numero: DTODemand.numero,
                complemento: DTODemand.complemento,
                tipo_cartorio: DTODemand.tipo_cartorio,
                pagina_inicial: DTODemand.pagina_inicial,
                pagina_final: DTODemand.pagina_final,
                valor_profissional: DTODemand.valor_profissional,
                tipo: demandInitial.tipo,
            }

            const response = await api.put('proposta/' + proposalId, proposalUpdated)

            if (response.data.status !== 'success') {
                if (response.data.result[0] === undefined) {
                    throw new Error(response.data.message);

                } else {
                    throw new Error(response.data.result[0].message);
                }

            } else {
                toast.success('Alterações salvas com sucesso', {
                    duration: 3000,
                    className: 'toasted',
                    style: {
                        padding: '16px',
                        color: '#1A2858',
                        background: '#E7F8EB',
                        border: '2px solid #9EE4AF',
                    }
                });

                setEditDemand(false);
            }

        } catch (error: any) {

            console.log('log do erro', error)

            toast.error(error.message, {
                duration: 3000,
                className: 'toasted',
                style: {
                    padding: '16px',
                    color: '#1A2858',
                },
                iconTheme: {
                    primary: '#F24343',
                    secondary: '#FFFAEE',
                },
            });

        }
    }

    async function createPendency() {
        try {

            if (pendencieType === 'correct') {
                const pendency = {
                    id_proposta: proposalId,
                    tipo: 'correcao',
                    descricao: justicationText,
                    valor: ''
                }

                const response = await api.post('/pendencia', pendency);

                if (response.data.status !== 'success') {

                    if (response.data.result[0] === undefined) {

                        throw new Error(response.data.message);

                    } else {

                        throw new Error(response.data.result[0].error);
                    }

                } else {

                    setModalPendencies(false);
                    setPendencieType('');
                    setUpdate(new Date());

                    toast.success('Pendência criada com sucesso', {
                        duration: 3000,
                        className: 'toasted',
                        style: {
                            padding: '16px',
                            color: '#1A2858',
                            background: '#E7F8EB',
                            border: '2px solid #9EE4AF',
                        }
                    });
                }
            }

            if (pendencieType === 'doc') {
                const pendency = {
                    id_proposta: proposalId,
                    tipo: 'documentacao',
                    descricao: justicationText,
                    valor: ''
                }

                const response = await api.post('/pendencia', pendency);

                if (response.data.status !== 'success') {

                    if (response.data.result[0] === undefined) {

                        throw new Error(response.data.message);

                    } else {

                        throw new Error(response.data.result[0].error);
                    }

                } else {
                    setModalPendencies(false);
                    setPendencieType('');
                    setUpdate(new Date());

                    toast.success('Pendência criada com sucesso', {
                        duration: 3000,
                        className: 'toasted',
                        style: {
                            padding: '16px',
                            color: '#1A2858',
                            background: '#E7F8EB',
                            border: '2px solid #9EE4AF',
                        }
                    });
                }
            }

            if (pendencieType === 'info') {
                const pendency = {
                    id_proposta: proposalId,
                    tipo: 'informacao',
                    descricao: justicationText,
                    valor: ''
                }

                const response = await api.post('/pendencia', pendency);

                if (response.data.status !== 'success') {

                    if (response.data.result[0] === undefined) {

                        throw new Error(response.data.message);

                    } else {

                        throw new Error(response.data.result[0].error);
                    }

                } else {
                    setModalPendencies(false);
                    setPendencieType('');
                    setUpdate(new Date());

                    toast.success('Pendência criada com sucesso', {
                        duration: 3000,
                        className: 'toasted',
                        style: {
                            padding: '16px',
                            color: '#1A2858',
                            background: '#E7F8EB',
                            border: '2px solid #9EE4AF',
                        }
                    });
                }
            }

            if (pendencieType === 'maj') {
                const pendency = {
                    id_proposta: proposalId,
                    tipo: 'marjoracao',
                    descricao: justicationText,
                    valor: pendencyValue
                }

                const response = await api.post('/pendencia', pendency);

                if (response.data.status !== 'success') {

                    if (response.data.result[0] === undefined) {

                        throw new Error(response.data.message);

                    } else {

                        throw new Error(response.data.result[0].error);
                    }

                } else {
                    setMoneyModal(false);
                    setPendencieType('');
                    setUpdate(new Date());

                    toast.success('Pendência criada com sucesso', {
                        duration: 3000,
                        className: 'toasted',
                        style: {
                            padding: '16px',
                            color: '#1A2858',
                            background: '#E7F8EB',
                            border: '2px solid #9EE4AF',
                        }
                    });
                }
            }
            
            if (pendencieType === 'refund') {
                const pendency = {
                    id_proposta: proposalId,
                    tipo: 'reembolso',
                    descricao: justicationText,
                    valor: pendencyValue
                }

                const response = await api.post('/pendencia', pendency);

                if (response.data.status !== 'success') {

                    if (response.data.result[0] === undefined) {

                        throw new Error(response.data.message);

                    } else {

                        throw new Error(response.data.result[0].error);
                    }

                } else {
                    setMoneyModal(false);
                    setPendencieType('');
                    setUpdate(new Date());

                    toast.success('Pendência criada com sucesso', {
                        duration: 3000,
                        className: 'toasted',
                        style: {
                            padding: '16px',
                            color: '#1A2858',
                            background: '#E7F8EB',
                            border: '2px solid #9EE4AF',
                        }
                    });
                }
            }

            if (pendencieType === 'advance') {
                const pendency = {
                    id_proposta: proposalId,
                    tipo: 'adiantamento',
                    descricao: justicationText,
                    valor: pendencyValue
                }

                const response = await api.post('/pendencia', pendency);

                if (response.data.status !== 'success') {

                    if (response.data.result[0] === undefined) {

                        throw new Error(response.data.message);

                    } else {

                        throw new Error(response.data.result[0].error);
                    }

                } else {
                    setMoneyModal(false);
                    setPendencieType('');
                    setUpdate(new Date());

                    toast.success('Pendência criada com sucesso', {
                        duration: 3000,
                        className: 'toasted',
                        style: {
                            padding: '16px',
                            color: '#1A2858',
                            background: '#E7F8EB',
                            border: '2px solid #9EE4AF',
                        }
                    });
                }
            }

        } catch (error: any) {

            console.log('log do erro', error.message);

            toast.error(error.message, {
                duration: 3000,
                className: 'toasted',
                style: {
                    padding: '16px',
                    color: '#1A2858',
                },
                iconTheme: {
                    primary: '#F24343',
                    secondary: '#FFFAEE',
                },
            });
            
        }
    }

    async function resolvePendency(id: any) {
        try {

            if (answerType === 'refund') {
                const pendency = {
                    aceitar: 'sim',
                    arquivos: demandFiles
                }

                const response = await api.put('pendencia-responder/' + id, pendency);

                if (response.data.status !== 'success') {
                    if (response.data.result[0] === undefined) {
                        throw new Error(response.data.message);

                    } else {
                        throw new Error(response.data.result[0].message);
                    }
                } else {
                    toast.success('Pendência respondida com sucesso', {
                        duration: 3000,
                        className: 'toasted',
                        style: {
                            padding: '16px',
                            color: '#1A2858',
                            background: '#E7F8EB',
                            border: '2px solid #9EE4AF',
                        }
                    });
                    setModalAnswerPendency(false);
                    history.push('/demandas')
                }
                
            }
            
            if (answerType === 'raise') {
                const pendency = {
                    aceitar: 'sim',
                    arquivos: demandFiles
                }

                const response = await api.put('pendencia-responder/' + id, pendency);

                if (response.data.status !== 'success') {
                    if (response.data.result[0] === undefined) {
                        throw new Error(response.data.message);

                    } else {
                        throw new Error(response.data.result[0].message);
                    }
                } else {
                    toast.success('Pendência respondida com sucesso', {
                        duration: 3000,
                        className: 'toasted',
                        style: {
                            padding: '16px',
                            color: '#1A2858',
                            background: '#E7F8EB',
                            border: '2px solid #9EE4AF',
                        }
                    });
                    setModalAnswerPendency(false);
                    history.push('/demandas')
                }
            }
            
            if (answerType === 'advance') {
                const pendency = {
                    aceitar: 'sim',
                    arquivos: demandFiles
                }

                const response = await api.put('pendencia-responder/' + id, pendency);

                if (response.data.status !== 'success') {

                    if (response.data.result[0] === undefined) {
                        throw new Error(response.data.message);

                    } else {
                        throw new Error(response.data.result[0].message);
                    }

                } else {
                    toast.success('Pendência respondida com sucesso', {
                        duration: 3000,
                        className: 'toasted',
                        style: {
                            padding: '16px',
                            color: '#1A2858',
                            background: '#E7F8EB',
                            border: '2px solid #9EE4AF',
                        }
                    });
                    setModalAnswerPendency(false);
                    history.push('/demandas')
                }
            }
            
            if (answerType === 'doc') {
                const pendency = {
                    aceitar: 'sim',
                    arquivos: demandFiles
                }

                const response = await api.put('pendencia-responder/'+ id, pendency);

                if (response.data.status !== 'success') {
                    if (response.data.result[0] === undefined) {
                        throw new Error(response.data.message);

                    } else {
                        throw new Error(response.data.result[0].message);
                    }
                } else {
                    setModalAnswerPendency(false);

                    toast.success('Pendência respondida com sucesso', {
                        duration: 3000,
                        className: 'toasted',
                        style: {
                            padding: '16px',
                            color: '#1A2858',
                            background: '#E7F8EB',
                            border: '2px solid #9EE4AF',
                        }
                    });
                    setModalAnswerPendency(false);
                    history.push('/demandas')
                }
            }

            if (answerType === 'info') {

                const pendency = {
                    aceitar: 'sim',
                    resposta: justicationText,
                }

                const response = await api.put('pendencia-responder/'+ id, pendency);

                if (response.data.status !== 'success') {
                    if (response.data.result[0] === undefined) {
                        throw new Error(response.data.message);

                    } else {
                        throw new Error(response.data.result[0].message);
                    }
                } else {
                    setModalAnswerPendency(false);

                    toast.success('Pendência respondida com sucesso', {
                        duration: 3000,
                        className: 'toasted',
                        style: {
                            padding: '16px',
                            color: '#1A2858',
                            background: '#E7F8EB',
                            border: '2px solid #9EE4AF',
                        }
                    });
                    setModalAnswerPendency(false);
                    history.push('/demandas')
                }
            }

            if (answerType === 'correc') {

                const pendency = {
                    aceitar: 'sim',
                    resposta: justicationText,
                }

                const response = await api.put('pendencia-responder/'+ id, pendency);

                if (response.data.status !== 'success') {
                    if (response.data.result[0] === undefined) {
                        throw new Error(response.data.message);

                    } else {
                        throw new Error(response.data.result[0].message);
                    }
                } else {
                    setModalAnswerPendency(false);

                    toast.success('Pendência respondida com sucesso', {
                        duration: 3000,
                        className: 'toasted',
                        style: {
                            padding: '16px',
                            color: '#1A2858',
                            background: '#E7F8EB',
                            border: '2px solid #9EE4AF',
                        }
                    });
                    setModalAnswerPendency(false);
                    history.push('/demandas')
                }
            }
            
            
        } catch (error: any) {
            console.log('log error resolvePendency', error);

            toast.error(error.message, {
                duration: 3000,
                className: 'toasted',
                style: {
                    padding: '16px',
                    color: '#1A2858',
                },
                iconTheme: {
                    primary: '#F24343',
                    secondary: '#FFFAEE',
                },
            });
        }
    }

    async function refusePendency(id: any) {
        try {

            if (answerType === 'refund') {
                const pendency = {
                    aceitar: 'nao',
                    arquivos: demandFiles
                }

                const response = await api.put('pendencia-responder/' + id, pendency);

                if (response.data.status !== 'success') {
                    if (response.data.result[0] === undefined) {
                        throw new Error(response.data.message);

                    } else {
                        throw new Error(response.data.result[0].message);
                    }
                } else {
                    toast.success('Pendência respondida com sucesso', {
                        duration: 3000,
                        className: 'toasted',
                        style: {
                            padding: '16px',
                            color: '#1A2858',
                            background: '#E7F8EB',
                            border: '2px solid #9EE4AF',
                        }
                    });
                    setModalAnswerPendency(false);
                }

            }

            if (answerType === 'raise') {
                const pendency = {
                    aceitar: 'nao',
                    arquivos: demandFiles
                }

                const response = await api.put('pendencia-responder/' + id, pendency);

                if (response.data.status !== 'success') {
                    if (response.data.result[0] === undefined) {
                        throw new Error(response.data.message);

                    } else {
                        throw new Error(response.data.result[0].message);
                    }
                } else {
                    toast.success('Pendência respondida com sucesso', {
                        duration: 3000,
                        className: 'toasted',
                        style: {
                            padding: '16px',
                            color: '#1A2858',
                            background: '#E7F8EB',
                            border: '2px solid #9EE4AF',
                        }
                    });
                    setModalAnswerPendency(false)
                }
            }

            if (answerType === 'advance') {
                const pendency = {
                    aceitar: 'nao',
                    arquivos: demandFiles
                }

                const response = await api.put('pendencia-responder/' + id, pendency);

                if (response.data.status !== 'success') {

                    if (response.data.result[0] === undefined) {
                        throw new Error(response.data.message);

                    } else {
                        throw new Error(response.data.result[0].message);
                    }

                } else {
                    toast.success('Pendência respondida com sucesso', {
                        duration: 3000,
                        className: 'toasted',
                        style: {
                            padding: '16px',
                            color: '#1A2858',
                            background: '#E7F8EB',
                            border: '2px solid #9EE4AF',
                        }
                    });
                    setModalAnswerPendency(false)
                }
            }

            // if (answerType === 'doc') {
            //     const pendency = {
            //         aceitar: 'nao',
            //         arquivos: demandFiles
            //     }

            //     const response = await api.put('pendencia-responder/' + id, pendency);

            //     if (response.data.status !== 'success') {
            //         if (response.data.result[0] === undefined) {
            //             throw new Error(response.data.message);

            //         } else {
            //             throw new Error(response.data.result[0].message);
            //         }
            //     } else {
            //         setModalAnswerPendency(false);

            //         toast.success('Pendência respondida com sucesso', {
            //             duration: 3000,
            //             className: 'toasted',
            //             style: {
            //                 padding: '16px',
            //                 color: '#1A2858',
            //                 background: '#E7F8EB',
            //                 border: '2px solid #9EE4AF',
            //             }
            //         });
            //         setModalAnswerPendency(false)
            //     }
            // }

            // if (answerType === 'info') {

            //     const pendency = {
            //         aceitar: 'nao',
            //         resposta: justicationText,
            //     }

            //     const response = await api.put('pendencia-responder/' + id, pendency);

            //     if (response.data.status !== 'success') {
            //         if (response.data.result[0] === undefined) {
            //             throw new Error(response.data.message);

            //         } else {
            //             throw new Error(response.data.result[0].message);
            //         }
            //     } else {
            //         setModalAnswerPendency(false);

            //         toast.success('Pendência respondida com sucesso', {
            //             duration: 3000,
            //             className: 'toasted',
            //             style: {
            //                 padding: '16px',
            //                 color: '#1A2858',
            //                 background: '#E7F8EB',
            //                 border: '2px solid #9EE4AF',
            //             }
            //         });
            //         setModalAnswerPendency(false)
            //     }
            // }


        } catch (error: any) {
            console.log('log error resolvePendency', error);

            toast.error(error.message, {
                duration: 3000,
                className: 'toasted',
                style: {
                    padding: '16px',
                    color: '#1A2858',
                },
                iconTheme: {
                    primary: '#F24343',
                    secondary: '#FFFAEE',
                },
            });
        }
    }

    async function endDemand(option: any) {
        try {

            clarity.setEvent('proposta-concluida')

            
            if (option === 'yes') {
                if (!justicationText) {
                    throw new Error('Digite um texto de justificativa')
                }

                const finish = {
                    status_finalizacao: 'Aceita',
                    justificativa: justicationText,
                }
    
                const response = await api.put('/proposta-finalizar/' + proposalId, finish);                

                if (response.data.status !== 'success') {
                    
                    if (response.data.result[0] === undefined) {

                        throw new Error(response.data.message);

                    } else {

                        throw new Error(response.data.result[0].message);
                    }

                } else {
                    setFinishDemand(false);

                    toast.success('Demanda finalizada com aceite', {
                        duration: 3000,
                        className: 'toasted',
                        style: {
                            padding: '16px',
                            color: '#1A2858',
                            background: '#E7F8EB',
                            border: '2px solid #9EE4AF',
                        }
                    });

                    history.push('/demandas');
                }

            } else {

                const finish = {
                    status_finalizacao: 'Recusada',
                    justificativa: justicationText,
                }

                const response = await api.put('/proposta-finalizar/' + proposalId, finish);                

                if (response.data.status !== 'success') {

                    if (response.data.result[0] === undefined) {

                        throw new Error(response.data.message);

                    } else {

                        throw new Error(response.data.result[0].message);
                    }

                } else {
                    setFinishDemand(false);

                    toast.success('Demanda finalizada com recusada', {
                        duration: 3000,
                        className: 'toasted',
                        style: {
                            padding: '16px',
                            color: '#1A2858',
                            background: '#E7F8EB',
                            border: '2px solid #9EE4AF',
                        }
                    });

                    history.push('/demandas');
                }

            }

        } catch (error: any) {
            console.log('log do erro ao finalizar demanda', error);

            toast.error(error.message, {
                duration: 3000,
                className: 'toasted',
                style: {
                    padding: '16px',
                    color: '#1A2858',
                },
                iconTheme: {
                    primary: '#F24343',
                    secondary: '#FFFAEE',
                },
            });
        }
    }

    async function concludeDemand(option: string) {
        try {
            
            if (option === 'Concluida') {

                setLoading(true);
                
                // if (justicationText === undefined) {
                //     throw new Error('Justifique sua decisão antes de concluir a entrega');
                // }

                if (user.profile !== 'comum') {

                    const conclusionPayload = {
                        justificativa: justicationText,
                    }

                    const response = await api.put(`proposta-concluirinterromper/${proposalId}?status=Concluida`, conclusionPayload);

                    if (response.data.status !== 'success') {

                        if (response.data.result[0] === undefined) {

                            throw new Error(response.data.message);

                        } else {

                            throw new Error(response.data.result[0].message);
                        }

                    } else {
                        setHandDemand(false);

                        toast.success('Demanda concluída com sucesso', {
                            duration: 3000,
                            className: 'toasted',
                            style: {
                                padding: '16px',
                                color: '#1A2858',
                                background: '#E7F8EB',
                                border: '2px solid #9EE4AF',
                            }
                        });

                        history.push('/demandas');

                        setLoading(false);
                    }
                    
                } else {

                    const lastFile = conclusionFiles.slice(-2);                
    
                    
                    const arquivos = [
                        {
                            arquivo_final: "sim",
                            caminho: lastFile[0]
                        },
                        {
                            arquivo_final: "sim",
                            caminho: lastFile[1]
                        },
                    ]
                    
                    const conclusionPayload = {
                        justificativa: justicationText,
                        arquivos,
                    }
    
        
                    const response = await api.put(`proposta-concluirinterromper/${proposalId}?status=Concluida`, conclusionPayload);
    
                    if (response.data.status !== 'success') {
    
                        if (response.data.result[0] === undefined) {
    
                            throw new Error(response.data.message);
    
                        } else {
    
                            throw new Error(response.data.result[0].message);
                        }
    
                    } else {
                        setHandDemand(false);
    
                        toast.success('Demanda concluída com sucesso', {
                            duration: 3000,
                            className: 'toasted',
                            style: {
                                padding: '16px',
                                color: '#1A2858',
                                background: '#E7F8EB',
                                border: '2px solid #9EE4AF',
                            }
                        });
    
                        history.push('/demandas');
    
                        setLoading(false);
                    }
                }


            } else {

                setLoading(true);

                if (justicationText === undefined) {
                    throw new Error('Justifique o motivo da desistência');
                }

                const response = await api.put(`proposta-concluirinterromper/${proposalId}?status=Interrompida`);                

                if (response.data.status !== 'success') {

                    if (response.data.result[0] === undefined) {
                        throw new Error(response.data.message);

                    } else {
                        throw new Error(response.data.result[0].message);
                    }

                } else {
                    setHandDemand(false);

                    toast.success('Demanda interrompida com sucesso', {
                        duration: 3000,
                        className: 'toasted',
                        style: {
                            padding: '16px',
                            color: '#1A2858',
                            background: '#E7F8EB',
                            border: '2px solid #9EE4AF',
                        }
                    });

                    setLoading(false);
                }

            }

            setLoading(false)
            
        } catch (error: any) {
            setLoading(false)

            toast.error(error.message, {
                duration: 3000,
                className: 'toasted',
                style: {
                    padding: '16px',
                    color: '#1A2858',
                },
                iconTheme: {
                    primary: '#F24343',
                    secondary: '#FFFAEE',
                },
            });
        }
    }

    const aboutSection = useRef<any>(null);

    const scrollDown = () => aboutSection.current.scrollIntoView();

    async function acceptOrientation() {

        try {
            if (demandInitial.orientacoes.length === 1) {

                const id_orientation = demandInitial.orientacoes[0].id_orientacao;
                
                const response = await api.put('orientacao-aceitar/' + id_orientation);
    
                if (response.data.status !== 'success') {
                    if (response.data.result[0] === undefined) {
                        throw new Error(response.data.message);
    
                    } else {
                        throw new Error(response.data.result[0].message);
                    }
                }

                history.push('/demandas');

            } else {

                const id_orientation = demandInitial.orientacoes[1].id_orientacao;

                const response = await api.put('orientacao-aceitar/' + id_orientation);

                if (response.data.status !== 'success') {
                    if (response.data.result[0] === undefined) {
                        throw new Error(response.data.message);

                    } else {
                        throw new Error(response.data.result[0].message);
                    }
                }
                
                history.push('/demandas');
            }

        } catch (error: any) {
            console.log('log error accepting orientation', error);
            toast.error(error.message, {
                duration: 3000,
                className: 'toasted',
                style: {
                    padding: '16px',
                    color: '#1A2858',
                },
                iconTheme: {
                    primary: '#F24343',
                    secondary: '#FFFAEE',
                },
            });
        }

    } 

    useEffect(() => {   

        const newValue = demandInitial?.valor_cliente + pendencyInfos?.valor
        setRaiseValue(newValue);

    }, [pendencyInfos])

    useEffect(() => {
        const checkIfClickedOutside = (e: any) => {
            
            if (openHistory && historyRef.current && !historyRef.current.contains(e.target)) {
                setOpenHistory(false);
            }

            if (openMenu && menuRef.current && !menuRef.current.contains(e.target)) {
                setOpenMenu(false);
            }

        };

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [openHistory, openMenu]);
    
    return (
        <Container>
            {
                loading &&
                <Oval
                    height={150}
                    width={150}
                    color="#FA9604"
                    wrapperStyle={{}}
                    wrapperClass="loader"
                    visible={true}
                    ariaLabel='oval-loading'
                    secondaryColor="#FA9604"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                />
            }

            {
                loading !== true &&
                <StatusHeader>
                    <Status>
                        {
                            demandInitial?.status === 'Pendente' &&
                            <div className="type">
                                <IconClock />
                                <div>
                                    <Moment locale='pt-br' fromNow ago>{demandInitial.prazo_aceite}</Moment> para a conclusão
                                </div>
                            </div>
                        }
                        {
                            demandInitial?.status === 'Interrompida' &&
                            <div className="type">
                                <IconClock />
                                <div>
                                    <Moment locale='pt-br' fromNow ago>{demandInitial.prazo_aceite}</Moment> para a conclusão
                                </div>
                            </div>
                        }
                        {
                            demandInitial?.status === 'Em Andamento' && demandInitial?.atrasada !== 'sim' &&
                            <div className="type">
                                <IconClock />
                                <div>
                                    <Moment locale='pt-br' fromNow ago>{demandInitial.prazo_aceite}</Moment> para a conclusão
                                </div>
                            </div>
                        }
                        {
                            demandInitial?.status === 'Em Andamento' && demandInitial?.atrasada === 'sim' &&
                            <div className="type delayed">
                                <IconClock />
                                <div>
                                    <Moment locale='pt-br' fromNow ago>{demandInitial.prazo_aceite}</Moment> após o prazo
                                </div>
                            </div>
                        }
                    </Status>

                    <Buttons>
                        {
                            editDemand !== true && 
                            <>
                                {
                                    demandInitial?.status === 'Finalizada' && user.profile !== 'comum' &&
                                    <ButtonIcon
                                        styles="save"
                                        Icon={<IconDuplicate />}
                                        onClick={() => duplicateDemand()}
                                    >
                                        Duplicar
                                    </ButtonIcon>
                                }
                                {
                                    demandInitial?.status === 'Cancelada' && user.profile !== 'comum' &&
                                    <ButtonIcon
                                        styles="save"
                                        Icon={<IconDuplicate />}
                                        onClick={() => duplicateDemand()}
                                    >
                                        Duplicar
                                    </ButtonIcon>
                                }
                                {
                                    demandInitial?.status === 'Interrompida' && user.profile !== 'comum' &&
                                    <ButtonIcon
                                        styles="save"
                                        Icon={<IconFinish />}
                                        onClick={() => setFinishDemand(true)}                                    
                                    >
                                        Finalizar
                                    </ButtonIcon>
                                }
                                {
                                    demandInitial?.status === 'Concluida' && user.profile !== 'comum' &&
                                    <ButtonIcon
                                        styles="save"
                                        Icon={<IconFinish />}
                                        onClick={() => setFinishDemand(true)}                                    
                                    >
                                        Finalizar
                                    </ButtonIcon>
                                }
                                {
                                    (demandInitial?.status !== 'Interrompida' && user.profile !== 'comum') &&
                                    (demandInitial?.status !== 'Concluida' && user.profile !== 'comum') &&
                                    (demandInitial?.status !== 'Cancelada' && user.profile !== 'comum') &&
                                    (demandInitial?.status !== 'Finalizada' && user.profile !== 'comum') 
                                    ?   <ButtonIcon
                                            styles="save"
                                            Icon={<IconWrong />}
                                            onClick={() => setCancelDemandModal(true)}                                    
                                        >
                                            Cancelar
                                        </ButtonIcon>
                                    :   ''
                                }
                                {
                                    demandInitial?.status !== 'Concluida' && user.profile === 'comum' &&
                                    demandInitial?.status !== 'Interrompida' && user.profile === 'comum' &&
                                    demandInitial?.status !== 'Cancelada' && user.profile === 'comum' &&                                    
                                    demandInitial?.status !== 'Finalizada' && user.profile === 'comum' &&                                    
                                    <ButtonIcon
                                        styles="save"
                                        Icon={<IconFinish />}  
                                        onClick={() => setHandDemand(true)}                                  
                                    >
                                        Entregar
                                    </ButtonIcon>
                                }                                

                                <DropDownMenu
                                    onClick={() => { setOpenHistory(!openHistory); setOpenMenu(false) }}   
                                    ref={historyRef}                                 
                                >
                                    <IconTimer />
                                    <div
                                        className={openHistory ? 'hidden-history' : 'hidden-history no-show'}
                                    >
                                        <HistoryTitle>
                                            Histórico:
                                            <div
                                                onClick={() => setOpenHistory(false)}
                                            >
                                                <IconClose />
                                            </div>
                                        </HistoryTitle>
                                        
                                        <HistoryTimeLine>
                                            {
                                                historyProposal?.length > 0 &&
                                                historyProposal.map((row: any) => (
                                                    <div className="line-info" key={row?.id_historico_proposta}>
                                                        <div className="date"><Moment locale='pt-br' format="DD/MM/YY">{row.criado}</Moment></div>
                                                        <div
                                                            className={
                                                                row.acao === 'criou proposta' || row.acao.includes('Duplicou')
                                                                ? 'bullet start' 
                                                                : row.acao === 'cancelou' || row.acao.includes('finalizou')
                                                                ? "bullet end" 
                                                                : 'bullet'
                                                            }
                                                        >
                                                            <IconStatus />
                                                        </div>
                                                        <div className="event">
                                                            {
                                                                row.acao === 'criou proposta' ? 'Proposta criada' : '' ||
                                                                row.acao === 'editou proposta' ? 'Proposta editada' : '' ||
                                                                row.acao === 'editou' ? 'Proposta editada' : '' ||
                                                                row.acao === 'recusou proposta' ? 'Proposta recusada' : '' ||
                                                                row.acao === 'aceitou proposta' ? 'Proposta aceite' : '' ||
                                                                row.acao === 'cancelou proposta' ? 'Proposta cancelada' : '' ||
                                                                row.acao === 'criou rascunho' ? 'Rascunho criado' : '' ||
                                                                row.acao === 'cancelou' ? 'Proposta cancelada' : '' ||                                                                
                                                                row.acao.includes('Duplicou') ? 'Proposta duplicada' : '' ||
                                                                row.acao.includes('respondeu pendência') ? 'Pendência respondida' : '' ||
                                                                row.acao.includes('criou pendencia') ? 'Pendência criada' : '' ||
                                                                row.acao.includes('criou orientacao') ? 'Orientação criada' : ''  ||
                                                                row.acao.includes('Deu ciencia na orientaçao') ? 'Orientação aceita' : ''  ||
                                                                row.acao.includes('editou orientacao') ? 'Editou orientação' : ''  ||
                                                                row.acao === 'interrompeu demanda' ? 'Demanda interrompida' : ''  ||
                                                                row.acao === 'finalizou demanda com recusa' ? 'Demanda finalizada com recusa' : ''  ||
                                                                row.acao === 'finalizou demanda com aceite' ? 'Demanda finalizada' : ''  ||
                                                                row.acao === 'concluiu demanda' ? 'Demanda concluída' : ''  
                                                            } por {row.nome_responsavel.split(' ')[0]} {
                                                                row.tipo_responsavel === 'Operador' 
                                                                ? `P${row.id_responsavel}` 
                                                                : row.tipo_responsavel === 'Profissional'
                                                                ? `D${row.id_responsavel}`
                                                                : row.tipo_responsavel === 'Admin'
                                                                ? `J${row.id_responsavel}`
                                                                : ''
                                                            }
                                                        </div>
                                                    </div>
                                                ))
                                            }

                                        </HistoryTimeLine>
                                    </div>
                                </DropDownMenu>
                                
                                {
                                    user.profile !== 'comum' &&
                                    demandInitial?.status !== 'Cancelada' &&
                                    demandInitial?.status !== 'Concluida' &&
                                    demandInitial?.status !== 'Finalizada' &&
                                    <DropDownMenu
                                        onClick={() => { setOpenMenu(!openMenu); setOpenHistory(false) }}
                                        ref={menuRef}
                                    >
                                        <div>
                                            <IconThreeDots />
                                        </div>
                                        <div
                                            className={openMenu ? 'hidden-menu' : 'hidden-menu no-show'}
                                        >
                                            <div
                                                className="button"
                                                onClick={() => setEditDemand(true)}
                                            >
                                                <IconEdit /> 
                                                Editar demanda
                                            </div>                                            
                                            <div
                                                className="button"
                                                onClick={() => concludeDemand('Concluida')}
                                            >
                                                <IconFinish /> 
                                                Concluir demanda
                                            </div>                                            
                                        </div>
                                    </DropDownMenu>
                                }

                                {
                                    demandInitial?.status !== 'Concluida' && user.profile === 'comum' &&
                                    demandInitial?.status !== 'Cancelada' && user.profile === 'comum' &&
                                    demandInitial?.status !== 'Interrompida' && user.profile === 'comum' &&
                                    demandInitial?.status !== 'Finalizada' && user.profile === 'comum' &&
                                    <DropDownMenu
                                        onClick={() => { setOpenMenu(!openMenu); setOpenHistory(false) }}
                                        ref={menuRef}
                                    >
                                        <div>
                                            <IconThreeDots />
                                        </div>
                                        <div
                                            className={openMenu ? 'hidden-menu' : 'hidden-menu no-show'}
                                        >
                                            <div
                                                className="button money"
                                                onClick={() => setMoneyModal(true)}
                                            >
                                                <IconAdvanceMoney />
                                                Suporte financeiro
                                            </div>                                            
                                        </div>
                                    </DropDownMenu>
                                }
                            </>                            
                        }
                        {
                            editDemand === true && user.profile !== 'comum' &&
                            <>
                                <ButtonDefault
                                    className="cancel"
                                    onClick={() => setEditDemand(false)}
                                >
                                    Cancelar
                                </ButtonDefault>

                                <ButtonIcon
                                    styles="filter"
                                    Icon={<IconFloppyDisk />}
                                    onClick={() => updateDemand()}
                                >
                                    Salvar Alterações
                                </ButtonIcon>
                            </>

                        }
                    </Buttons>
                </StatusHeader>
            }

            {
                loading !== true &&
                <DemandDetails>

                    <DemandLeft>

                        {
                            editDemand !== true &&
                            <>
                                <DemandAdress>
                                    <div className="title-service">
                                        {
                                            demandInitial?.descricao_categoria_servico.split(' ')[0] === 'Audiências' ? 'Audiência' : '' ||
                                            demandInitial?.descricao_categoria_servico.split(' ')[0] === 'Diligências' ? 'Diligência' : '' ||
                                            demandInitial?.descricao_categoria_servico.split(' ')[0] === 'Protocolos' ? demandInitial?.titulo_servico : ''
                                        } { demandInitial?.titulo_servico.split(' ')[0] === 'Protocolo' ? '' : demandInitial?.titulo_servico.split(' ')[0]}
                                    </div>

                                    <div className="countie-process">
                                        <div className="title-infos">
                                            <span>Comarca:</span>
                                            <div className="info">
                                                {countieInfo?.uf} | {countieInfo?.cidade}
                                            </div>
                                        </div>
                                        <div className="title-infos">
                                            <span>Número do processo:</span>
                                            <div className="info">
                                                {
                                                    demandInitial?.numero_processo
                                                        ? demandInitial.numero_processo
                                                        : 'Número não informado'
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        demandInitial?.descricao_categoria_servico.split(' ')[0] === 'Audiências' &&
                                        <>
                                            <div className="countie-process">
                                                <div className="title-infos">
                                                    <span>Subtipo:</span>
                                                    <div className="info">
                                                        {
                                                            demandInitial?.tipo_audiencia
                                                                ? demandInitial.tipo_audiencia
                                                                : 'Sem subtipo'
                                                        }
                                                    </div>
                                                </div>
                                                <div className="title-infos">
                                                    <span>Modalidade:</span>
                                                    <div className="info">
                                                        {
                                                            demandInitial?.modalidade
                                                                ? demandInitial?.modalidade
                                                                : 'Sem modalidade'
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="countie-process">
                                                <div className="title-infos">
                                                    <span>Profissional necessário:</span>
                                                    <div className="info">
                                                        {
                                                            demandInitial?.profissional_necessario
                                                                ? demandInitial.profissional_necessario === 'Advogado_Preposto'
                                                                    ? 'Advogado + Preposto'
                                                                    :   demandInitial.profissional_necessario
                                                                : 'Sem profissional necessário'
                                                        }
                                                    </div>
                                                </div>
                                                <div className="title-infos">
                                                    <span>Data e hora da audiência:</span>
                                                    <div className="info">
                                                        {
                                                            demandInitial?.data !== null
                                                                ? <Moment locale='pt-br' format="DD/MM/YYYY | HH:mm">{demandInitial?.data}</Moment>
                                                                : 'Sem data'
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }


                                    <div className="address">
                                        <span>Endereço físico:</span>
                                        {
                                            demandInitial?.modalidade === 'online'
                                                ? <div className="address-info">
                                                    <div className="info">Apenas endereço virtual</div>
                                                </div>
                                                : <div className="address-info">
                                                    <div className="info">
                                                        {
                                                            demandInitial?.rua
                                                                ? demandInitial.rua
                                                                : 'End. não informado'
                                                        }
                                                    </div>
                                                    <div className="info">nº {demandInitial?.numero}</div>
                                                    <div className="info">{demandInitial?.bairro}</div>
                                                    <div className="info">{demandInitial?.cep}</div>
                                                    {
                                                        demandInitial?.complemento !== ''
                                                            ? <div className="info">{demandInitial?.complemento}</div>
                                                            : ''
                                                    }
                                                </div>
                                        }

                                    </div>

                                    {
                                        demandInitial?.endereco_virtual &&
                                            <div className="virtual-address">
                                            <div className="title-infos">
                                                <span>Endereço virtual:</span>
                                                {
                                                    demandInitial?.modalidade === 'presencial'
                                                        ? 'Apenas presencial'
                                                        : <div className="info">{demandInitial?.endereco_virtual}</div>
                                                }
                                            </div>
                                        </div>
                                    }

                                </DemandAdress>

                                <ServiceTags>
                                    <span>Detalhes do serviço:</span>
                                    <div className="tags">
                                        {
                                            demandInitial?.tags.map((row: any) => (
                                                <div className="tag" key={row}>{row}</div>
                                            ))
                                        }
                                    </div>
                                </ServiceTags>

                                <Pendencies>
                                    <span>Pendências</span>

                                    <div className="number-cards">
                                        <div className="card resolved">
                                            <div className="top">{pendencyCounters?.resolvidas ? pendencyCounters.resolvidas : 0} <IconAccept /></div>
                                            <span>Resolvidas</span>
                                        </div>
                                        <div className="card accept">
                                            <div className="top">{pendencyCounters?.aceitas ? pendencyCounters.aceitas : 0} <IconAccept /></div>
                                            <span>Aceitas</span>
                                        </div>
                                        <div className="card refused">
                                            <div className="top">{pendencyCounters?.recusadas ? pendencyCounters.recusadas : 0} <IconWrong /></div>
                                            <span>Recusadas</span>
                                        </div>
                                        <div className="card waiting">
                                            <div className="top">{pendencyCounters?.aguardando ? pendencyCounters.aguardando : 0} <IconInProgress /></div>
                                            <span>Aguardando</span>
                                        </div>
                                        <div className="card response">
                                            <div className="top">{pendencyCounters?.responder ? pendencyCounters.responder : 0}</div>
                                            <span>Responder</span>
                                        </div>
                                        <div 
                                            className="arrow"
                                            onClick={() => setOpenPendency(!openPendecy)}
                                        >   
                                            {
                                                openPendecy
                                                ?   <IconArrowUp />
                                                :   <IconArrowDown />
                                            }
                                            
                                        </div>
                                    </div>

                                    {   openPendecy 
                                        ?
                                            DTODemand.pendencias?.length > 0 ?
                                            DTODemand.pendencias.map((row: any) => (
                                                row.tipo === 'marjoracao'
                                                    ? <Pendency key={row.id_pendencia}>
                                                        <div className="header">
                                                            <div className="icon-title">
                                                                <IconAdvanceMoney />
                                                                Majoração
                                                            </div>

                                                            <div className="date-status">
                                                                <div className="date">
                                                                    <Moment locale='pt-br' fromNow>{row.criado}</Moment>
                                                                </div>
                                                                {
                                                                    row.resolvida !== 'sim'
                                                                        ?   
                                                                            row.destino === 'operador' && user.profile !== 'comum'
                                                                            ?
                                                                                <div 
                                                                                    className="status resolve"
                                                                                    onClick={() => { setModalAnswerPendency(true); setAnswerType('raise'); setPendencyInfos(row) }}
                                                                                >
                                                                                    Responder
                                                                                </div>
                                                                            :   <div className="status waiting">
                                                                                    <IconInProgress />
                                                                                    Aguardando
                                                                                </div>
                                                                        : row.aceita === 'sim'
                                                                            ?   <div className="status success">
                                                                                    <IconAccept />
                                                                                    Aceita
                                                                                </div>
                                                                            :   <div className="status refuse">
                                                                                    <IconWrong />
                                                                                    Recusada
                                                                                </div>
                                                                }                                                                
                                                            </div>

                                                        </div>

                                                        <div className="money">
                                                            {
                                                                row.valor.toString().includes('.')
                                                                    ? row.valor.toString().replace('.', ',')
                                                                    : `R$ ${row.valor},00`
                                                            }
                                                            <span>Valor adiantado</span>
                                                        </div>

                                                        <div className="info">
                                                            {row.descricao}
                                                        </div>
                                                    </Pendency>
                                                    : row.tipo === 'reembolso'
                                                        ? <Pendency key={row.id_pendencia}>
                                                            <div className="header">
                                                                <div className="icon-title">
                                                                    <IconAdvanceMoney />
                                                                    Reembolso
                                                                </div>

                                                                <div className="date-status">
                                                                    <div className="date">
                                                                        <Moment locale='pt-br' fromNow>{row.criado}</Moment>
                                                                    </div>
                                                                    {
                                                                        row.resolvida !== 'sim'
                                                                            ?
                                                                            row.destino === 'operador' && user.profile !== 'comum'
                                                                                ?
                                                                                <div 
                                                                                    className="status resolve"
                                                                                    onClick={() => { setModalAnswerPendency(true); setAnswerType('refund'); setPendencyInfos(row) }}
                                                                                >
                                                                                    Responder
                                                                                </div>
                                                                                : <div className="status waiting">
                                                                                    <IconInProgress />
                                                                                    Aguardando
                                                                                </div>
                                                                            : row.aceita === 'sim'
                                                                                ? <div className="status success">
                                                                                    <IconAccept />
                                                                                    Aceita
                                                                                </div>
                                                                                : <div className="status refuse">
                                                                                    <IconWrong />
                                                                                    Recusada
                                                                                </div>
                                                                    }
                                                                </div>
                                                            </div>

                                                            <div className="money">
                                                                R$ {row.valor},00
                                                                <span>Valor na fatura</span>
                                                            </div>

                                                            <div className="info">
                                                                {row.descricao}
                                                            </div>
                                                        </Pendency>
                                                        : row.tipo === 'adiantamento'
                                                            ? <Pendency key={row.id_pendencia}>
                                                                <div className="header">
                                                                    <div className="icon-title">
                                                                        <IconAdvanceMoney />
                                                                        Adiantamento
                                                                    </div>

                                                                    <div className="date-status">
                                                                        <div className="date">
                                                                            <Moment locale='pt-br' fromNow>{row.criado}</Moment>
                                                                        </div>
                                                                        {
                                                                            row.resolvida !== 'sim'
                                                                                ?
                                                                                row.destino === 'operador' && user.profile !== 'comum'
                                                                                    ?
                                                                                    <div 
                                                                                        className="status resolve"
                                                                                        onClick={() => { setModalAnswerPendency(true); setAnswerType('advance'); setPendencyInfos(row) }}
                                                                                    >
                                                                                        Responder
                                                                                    </div>
                                                                                    : <div className="status waiting">
                                                                                        <IconInProgress />
                                                                                        Aguardando
                                                                                    </div>
                                                                                : row.aceita === 'sim'
                                                                                    ? <div className="status success">
                                                                                        <IconAccept />
                                                                                        Aceita
                                                                                    </div>
                                                                                    : <div className="status refuse">
                                                                                        <IconWrong />
                                                                                        Recusada
                                                                                    </div>
                                                                        }
                                                                    </div>
                                                                </div>

                                                                <div className="money">
                                                                    R$ {row.valor},00
                                                                    <span>Valor adiantado</span>
                                                                </div>

                                                                <div className="info">
                                                                    {row.descricao}
                                                                </div>
                                                            </Pendency>
                                                            : row.tipo === 'documentacao'
                                                                ? <Pendency key={row.id_pendencia}>
                                                                    <div className="header">
                                                                        <div className="icon-title">
                                                                            <IconAttachment />
                                                                            Documentação
                                                                        </div>

                                                                        {
                                                                            row.resolvida !== 'sim'
                                                                                ?   row.destino === 'usuario' && user.profile === 'comum'
                                                                                    ?   <div className="date-status">
                                                                                            <div className="date">
                                                                                                <Moment locale='pt-br'fromNow>{row.criado}</Moment>
                                                                                            </div>
                                                                                            <div
                                                                                                className="status resolve"
                                                                                                onClick={() => { setModalAnswerPendency(true); setAnswerType('doc'); setPendencyInfos(row) }}
                                                                                            >
                                                                                                Responder
                                                                                            </div>
                                                                                        </div>
                                                                                    :   row.destino !== 'usuario' && user.profile !== 'comum'
                                                                                        ?   <div className="date-status">
                                                                                                <div className="date">
                                                                                                    <Moment locale='pt-br' fromNow>{row.criado}</Moment>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="status resolve"
                                                                                                    onClick={() => { setModalAnswerPendency(true); setAnswerType('doc'); setPendencyInfos(row) }}
                                                                                                >
                                                                                                    Responder
                                                                                                </div>
                                                                                            </div>
                                                                                        :   <div className="date-status"> 
                                                                                                <div className="date">
                                                                                                    <Moment locale='pt-br' fromNow>{row.criado}</Moment>
                                                                                                </div>
                                                                                                <div className="status waiting">
                                                                                                    <IconInProgress />
                                                                                                    Aguardando
                                                                                                </div>
                                                                                            </div>
                                                                                :   
                                                                                    <div className="status-link">
                                                                                        <div className="status success">
                                                                                            <IconAccept />
                                                                                            Resolvida
                                                                                        </div>
                                                                                        <div 
                                                                                            className="button"
                                                                                            onClick={scrollDown}
                                                                                        >
                                                                                            <IconAttachment />                                                                             
                                                                                        </div>
                                                                                    </div>
                                                                        }
                                                                    </div>

                                                                    <div className="list">
                                                                        <p>{row.descricao}</p>
                                                                    </div>
                                                                </Pendency>
                                                                : row.tipo === 'informacao'
                                                                    ? <Pendency key={row.id_pendencia}>
                                                                        <div className="header">
                                                                            <div className="icon-title">
                                                                                <IconInstruction />
                                                                                Instrução
                                                                            </div>

                                                                            {
                                                                                row.resolvida !== 'sim'
                                                                                    ? row.destino === 'usuario' && user.profile === 'comum'
                                                                                        ? <div
                                                                                            className="status resolve"
                                                                                            onClick={() => { setModalAnswerPendency(true); setAnswerType('info'); setPendencyInfos(row) }}
                                                                                        >
                                                                                            Responder
                                                                                        </div>
                                                                                        : row.destino !== 'usuario' && user.profile !== 'comum'
                                                                                            ? <div
                                                                                                className="status resolve"
                                                                                                onClick={() => { setModalAnswerPendency(true); setAnswerType('info'); setPendencyInfos(row) }}
                                                                                            >
                                                                                                Responder
                                                                                            </div>
                                                                                            : <div className="status waiting">
                                                                                                <IconInProgress />
                                                                                                Aguardando
                                                                                            </div>
                                                                                    : <div className="status success">
                                                                                        <IconAccept />
                                                                                        Resolvida
                                                                                    </div>
                                                                            }
                                                                        </div>

                                                                        <div className="list">
                                                                            <p>{row.descricao}</p>
                                                                            {
                                                                                row.resposta !== null
                                                                                ?
                                                                                <div className="answer">
                                                                                    <div className="title">Resposta:</div>
                                                                                    <div className="info">{row.resposta}</div>
                                                                                </div>
                                                                                : ''
                                                                            }
                                                                        </div>
                                                                    </Pendency>
                                                                    : row.tipo === 'correcao'
                                                                        ? 
                                                                            <Pendency key={row.id_pendencia}>
                                                                                <div className="header">
                                                                                    <div className="icon-title">
                                                                                        <IconInstruction />
                                                                                        Correção
                                                                                    </div>

                                                                                    {
                                                                                        row.resolvida !== 'sim'
                                                                                            ? row.destino === 'usuario' && user.profile === 'comum'
                                                                                                ? <div
                                                                                                    className="status resolve"
                                                                                                    onClick={() => { setModalAnswerPendency(true); setAnswerType('correc'); setPendencyInfos(row) }}
                                                                                                >
                                                                                                    Responder
                                                                                                </div>
                                                                                                : row.destino !== 'usuario' && user.profile !== 'comum'
                                                                                                    ? <div
                                                                                                        className="status resolve"
                                                                                                        onClick={() => { setModalAnswerPendency(true); setAnswerType('correc'); setPendencyInfos(row) }}
                                                                                                    >
                                                                                                        Responder
                                                                                                    </div>
                                                                                                    : <div className="status waiting">
                                                                                                        <IconInProgress />
                                                                                                        Aguardando
                                                                                                    </div>
                                                                                            : <div className="status success">
                                                                                                <IconAccept />
                                                                                                Resolvida
                                                                                            </div>
                                                                                    }
                                                                                </div>

                                                                                <div className="list">
                                                                                <p>{row.descricao}</p>
                                                                                {
                                                                                    row.resposta !== null
                                                                                    ?
                                                                                    <div className="answer">
                                                                                        <div className="title">Resposta:</div>
                                                                                        <div className="info">{row.resposta}</div>
                                                                                    </div>
                                                                                    : ''
                                                                                }
                                                                            </div>
                                                                            </Pendency>
                                                                        :   'Sem Pendências'
                                            ))
                                            :   <div className="empty">Sem pendências no momento</div> 
                                        :   ''
                                    }
                                    {
                                        user.profile === 'comum' &&
                                        <div
                                            className="add"
                                            onClick={() => setModalPendencies(true)}
                                        >
                                            <IconPendency />
                                            Adicionar pendências
                                        </div>
                                    }

                                </Pendencies>

                                {/* Orientações iniciais */}
                                <Orientations>
                                    <span>Orientações prévias:</span>
                                    {
                                        demandInitial?.orientacoes.length === 1 &&
                                        demandInitial?.orientacoes[0] === '' &&
                                        demandInitial?.orientacoes[0].id_proposta !== '' 
                                            ?   
                                                <>
                                                    <div className="info">
                                                        Sem orientações iniciais.
                                                    </div>                                                    
                                                </>
                                            :   
                                                <div className="info">{demandInitial?.orientacoes[0]}</div>

                                                    
                                    }
                                    
                                </Orientations>
                                
                                {/* Orientações completas */}
                                <Orientations>
                                    <span>Orientações completas:</span>
                                    {
                                        demandInitial?.orientacoes.length > 1 &&
                                        demandInitial?.orientacoes[1] === ''
                                        ?   <div className="info">
                                                Sem orientações completas.
                                            </div>
                                        : 
                                        <>  
                                            {
                                                demandInitial?.pendente_de_orientacao !== "sim" &&       
                                                <div className="details">
                                                    <div className="flex">
                                                        {
                                                            demandInitial?.orientacoes.length > 1
                                                                ?   demandInitial?.pendente_de_orientacao === "sim"
                                                                    ?   
                                                                    ''                                                                    
                                                                    :   demandInitial?.status_orientacao === 'Aguardando Ciência'
                                                                        ? 
                                                                            <div className="status waiting">
                                                                                <IconInProgress />
                                                                                Aguardando ciência
                                                                            </div>
                                                                        :
                                                                            <div className="status accept">
                                                                                <IconAccept />
                                                                                Ciente
                                                                            </div>                                                                    

                                                                :   demandInitial?.pendente_de_orientacao === "sim"
                                                                    ?   
                                                                    ''                                                                    
                                                                    :   demandInitial?.status_orientacao === 'Aguardando Ciência'
                                                                        ? 
                                                                            <div className="status waiting">
                                                                                <IconInProgress />
                                                                                Aguardando ciência
                                                                            </div>
                                                                        :  
                                                                            demandInitial?.status_orientacao === 'ciente'
                                                                            ?
                                                                                <div className="status accept">
                                                                                    <IconAccept />
                                                                                    Ciente
                                                                                </div>
                                                                            : ''
                                                                        
                                                        }
                                                        
                                                        {
                                                            demandInitial?.orientacoes.length > 1
                                                                ?   <div className="time">
                                                                        Atualizada <Moment locale='pt-br' fromNow>{demandInitial?.orientacoes[1]?.criado}</Moment>
                                                                    </div>
                                                                :   
                                                                    <div className="time">
                                                                        Atualizada <Moment locale='pt-br' fromNow>{demandInitial?.orientacoes[0]?.criado}</Moment>
                                                                    </div>
                                                        }
                                                        
                                                    </div>
                                                    {/* <div
                                                        className="arrow"
                                                        onClick={() => setOpenOrientations(!openOrientations)}
                                                    >
                                                        {
                                                            openOrientations
                                                                ? <IconArrowUp />
                                                                : <IconArrowDown />
                                                        }

                                                    </div> */}
                                                </div>
                                            }
                                            {
                                                demandInitial?.orientacoes.length > 1 
                                                ?
                                                    <OrientationsCard>
                                                        <div className="info">
                                                            <span>Qual parte representamos:</span>
                                                            <div className="info-details">
                                                                {
                                                                    demandInitial?.orientacoes[1].parte_representada
                                                                        ?   demandInitial?.orientacoes[1].parte_representada
                                                                        :   ""
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="lawyer-info">
                                                            <div className="lawyer">
                                                                <span>Nome do advogado:</span>
                                                                <div className="info-details">{demandInitial?.orientacoes[1].nome_advogado}</div>
                                                            </div>
                                                            <div className="number">
                                                                <span>OAB:</span>
                                                                <div className="info-details">{demandInitial?.orientacoes[1].oab_advogado}</div>
                                                            </div>
                                                        </div>

                                                        <div className="info">
                                                            <span>Nome do preposto:</span>
                                                            <div 
                                                                className="info-details">{
                                                                    demandInitial?.orientacoes[1].nome_preposto 
                                                                        ?   demandInitial?.orientacoes[1].nome_preposto
                                                                        :   ''
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="text-info">
                                                            <span>Proposta de acordo:</span>
                                                            <div className="info">
                                                                {
                                                                    demandInitial?.orientacoes[1].proposta_acordo
                                                                        ?   demandInitial?.orientacoes[1].proposta_acordo
                                                                        :   ""
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="text-info">
                                                            <span>Requerimentos a realizar em audiência:</span>
                                                            <div className="info dot">
                                                                {
                                                                    demandInitial?.orientacoes[1].requerimentos
                                                                        ?   demandInitial?.orientacoes[1].requerimentos
                                                                        :   ""
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="text-info">
                                                            <span>Documentos a juntar:</span>
                                                            <div className="info dot">
                                                                {
                                                                    demandInitial?.orientacoes[1].documentos
                                                                        ?   demandInitial?.orientacoes[1].documentos
                                                                        :   ""
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="text-info">
                                                            <span>Testemunhas a ouvir:</span>
                                                            <div className="info dot">
                                                                {
                                                                    demandInitial?.orientacoes[1].testemunhas
                                                                        ?   demandInitial?.orientacoes[1].testemunhas
                                                                        :   ""
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="text-info">
                                                            <span>Pedidos relevantes da inicial:</span>
                                                            <div className="info dot">
                                                                {
                                                                    demandInitial?.orientacoes[1].pedidos
                                                                        ?   demandInitial?.orientacoes[1].pedidos
                                                                        :   ""
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="text-info">
                                                            <span>Resumo da contestação:</span>
                                                            <div className="info">
                                                                {
                                                                    demandInitial?.orientacoes[1].resumo_contestacao
                                                                        ?   demandInitial?.orientacoes[1].resumo_contestacao
                                                                        :   ""
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="text-info">
                                                            <span>Outras informações:</span>
                                                            <div className="info">
                                                                {
                                                                    demandInitial?.orientacoes[1].outras_informacoes
                                                                        ?   demandInitial?.orientacoes[1].outras_informacoes
                                                                        :   ""
                                                                }
                                                            </div>
                                                        </div>

                                                        {/* <Link
                                                            to={{
                                                                pathname: '/demanda/orientacoes',
                                                                state: {
                                                                    demandInfo: demandInitial,
                                                                }
                                                            }}
                                                        >
                                                            <div className="new">
                                                                <IconEdit />
                                                                Ajustar orientação
                                                            </div>
                                                        </Link> */}
                                                    </ OrientationsCard>
                                                :   
                                                    <div className="info">
                                                        Sem orientações completas.
                                                    </div>
                                                        
                                            }                                            
                                        </>
                                    }

                                    {
                                        demandInitial?.orientacoes.length > 1 &&
                                        demandInitial?.orientacoes[1].arquivos.length > 0 &&
                                        <OrientationsFiles>
                                            <DemandFiles
                                                title="Arquivos da orientação:"
                                                files={demandInitial?.orientacoes[1].arquivos}
                                            />
                                        </OrientationsFiles>
                                    }
                                </Orientations>

                                <DemandFiles
                                    files={demandInitial?.arquivos} 
                                    edit={editDemand}
                                />  

                                <div ref={aboutSection}>
                                </div>                                                                          
                            </>
                        }

                        {
                            editDemand &&
                            <>
                                <DemandAdress>
                                        <div className="title-service">
                                            {
                                                demandInitial?.descricao_categoria_servico.split(' ')[0] === 'Audiências' ? 'Audiência' : '' ||
                                                    demandInitial?.descricao_categoria_servico.split(' ')[0] === 'Diligências' ? 'Diligência' : '' ||
                                                        demandInitial?.descricao_categoria_servico.split(' ')[0] === 'Protocolos' ? 'Protocolo' : ''
                                            } {demandInitial?.titulo_servico.split(' ')[0]}
                                        </div>

                                        <div className="line">
                                            <div className="label-state">
                                                <p>Estado:</p>
                                                <Select
                                                    name="estados"
                                                    styles={styles}
                                                    components={{
                                                        IndicatorSeparator: () => null
                                                    }}
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: 8,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#DDFAF4',
                                                            primary50: '#DDFAF4',
                                                            primary: '#02E8BE',
                                                        },
                                                    })}
                                                    placeholder='Selecionar'
                                                    defaultValue={{ value: countieInfo?.uf, label: countieInfo?.uf }}
                                                    onChange={getCounties}
                                                    options={EstadosBrasileiros}
                                                />
                                                {/* <span className="error">{advancePageError?.uf ? '*' : ''}</span> */}
                                            </div>

                                            <div className="label-state">
                                                <p>Comarca:</p>
                                                <Select
                                                    styles={styles}
                                                    components={{
                                                        IndicatorSeparator: () => null
                                                    }}
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: 8,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#DDFAF4',
                                                            primary50: '#DDFAF4',
                                                            primary: '#02E8BE',
                                                        },
                                                    })}
                                                    name='id_comarca'
                                                    placeholder="Selecionar"
                                                    defaultValue={{ value: countieInfo?.cidade, label: countieInfo?.cidade }}
                                                    options={counties}
                                                    onChange={(e: any) => handleOnChange('id_comarca', e.value)}
                                                />
                                            </div>
                                        </div>

                                        <div className="line">
                                            <InputDefault
                                                title="Número do processo:"
                                                name="numero_processo"
                                                value={DTODemand?.numero_processo}
                                                mask={'9999999-99.9999.9.99.9999'}
                                                onChangeText={(value) => handleOnChange('numero_processo', value)}
                                                className={demandInitial?.numero_processo !== DTODemand?.numero_processo ? 'mid highlight' : 'mid'}
                                            />
                                            {
                                                demandInitial?.descricao_categoria_servico.split(' ')[0] === 'Audiências' &&
                                                <div className="label-state">
                                                    <p>Subtipo:</p>
                                                    {
                                                        demandInitial?.titulo_servico.split(' ')[0] === "Cível" &&
                                                        <>
                                                            <Select
                                                                name="tipo_audiencia"
                                                                styles={styles}
                                                                components={{
                                                                    IndicatorSeparator: () => null
                                                                }}
                                                                theme={(theme) => ({
                                                                    ...theme,
                                                                    borderRadius: 8,
                                                                    colors: {
                                                                        ...theme.colors,
                                                                        primary25: '#DDFAF4',
                                                                        primary50: '#DDFAF4',
                                                                        primary: '#02E8BE',
                                                                    },
                                                                })}
                                                                placeholder='Selecionar'
                                                                defaultValue={DTODemand?.tipo_audiencia !== '' ? { value: DTODemand.tipo_audiencia, label: DTODemand.tipo_audiencia } : ''}
                                                                onChange={(selected: any) => handleOnChange('tipo_audiencia', selected.value)}
                                                                options={CivilHearing}
                                                            />
                                                        </>
                                                    }
                                                    {
                                                        demandInitial?.titulo_servico.split(' ')[0] === "Penal" &&
                                                        <>
                                                            <Select
                                                                name="tipo_audiencia"
                                                                styles={styles}
                                                                components={{
                                                                    IndicatorSeparator: () => null
                                                                }}
                                                                theme={(theme) => ({
                                                                    ...theme,
                                                                    borderRadius: 8,
                                                                    colors: {
                                                                        ...theme.colors,
                                                                        primary25: '#DDFAF4',
                                                                        primary50: '#DDFAF4',
                                                                        primary: '#02E8BE',
                                                                    },
                                                                })}
                                                                placeholder='Selecionar'
                                                                defaultValue={DTODemand.tipo_audiencia !== '' ? { value: DTODemand.tipo_audiencia, label: DTODemand.tipo_audiencia } : ''}
                                                                onChange={(selected: any) => handleOnChange('tipo_audiencia', selected.value)}
                                                                options={CriminalHearing}
                                                            />
                                                        </>
                                                    }
                                                    {
                                                        demandInitial?.titulo_servico.split(' ')[0] === "Trabalhista" &&
                                                        <>
                                                            <Select
                                                                name="tipo_audiencia"
                                                                styles={styles}
                                                                components={{
                                                                    IndicatorSeparator: () => null
                                                                }}
                                                                theme={(theme) => ({
                                                                    ...theme,
                                                                    borderRadius: 8,
                                                                    colors: {
                                                                        ...theme.colors,
                                                                        primary25: '#DDFAF4',
                                                                        primary50: '#DDFAF4',
                                                                        primary: '#02E8BE',
                                                                    },
                                                                })}
                                                                placeholder='Selecionar'
                                                                defaultValue={DTODemand.tipo_audiencia !== '' ? { value: DTODemand.tipo_audiencia, label: DTODemand.tipo_audiencia } : ''}
                                                                onChange={(selected: any) => handleOnChange('tipo_audiencia', selected.value)}
                                                                options={LaborHearing}
                                                            />
                                                        </>
                                                    }
                                                    {
                                                        // formTitle === 'Audiências' &&
                                                        // <>
                                                        //     <Select
                                                        //         name="tipo_audiencia"
                                                        //         styles={hearing}
                                                        //         components={{
                                                        //             IndicatorSeparator: () => null
                                                        //         }}
                                                        //         theme={(theme) => ({
                                                        //             ...theme,
                                                        //             borderRadius: 8,
                                                        //             colors: {
                                                        //                 ...theme.colors,
                                                        //                 primary25: '#DDFAF4',
                                                        //                 primary50: '#DDFAF4',
                                                        //                 primary: '#02E8BE',
                                                        //             },
                                                        //         })}
                                                        //         placeholder='Selecionar'
                                                        //         defaultValue={DTODemand.tipo_audiencia !== '' ? { value: DTODemand.tipo_audiencia, label: DTODemand.tipo_audiencia } : ''}
                                                        //         onChange={(selected: any) => handleOnChange('tipo_audiencia', selected.value)}
                                                        //         options={AnotherHearing}
                                                        //     />
                                                        //     {/* <span className="error">{advancePageError?.tipo_audiencia ? '*' : ''}</span> */}
                                                        // </>
                                                    }
                                                </div>
                                            }
                                        </div>
                                        
                                        {
                                            demandInitial?.descricao_categoria_servico.split(' ')[0] === 'Audiências' &&
                                            <div className="line">
                                                <div className="label-state">
                                                    <p>Modalidade:</p>
                                                    <Select
                                                        name="modalidade"
                                                        styles={modalities}
                                                        components={{
                                                            IndicatorSeparator: () => null
                                                        }}
                                                        theme={(theme) => ({
                                                            ...theme,
                                                            borderRadius: 8,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary25: '#DDFAF4',
                                                                primary50: '#DDFAF4',
                                                                primary: '#02E8BE',
                                                            },
                                                        })}
                                                        placeholder='Selecionar'
                                                        defaultValue={DTODemand?.modalidade !== '' ? { value: DTODemand.modalidade, label: DTODemand.modalidade } : ''}
                                                        onChange={(selected: any) => handleOnChange('modalidade', selected.value)}
                                                        options={ModalityAudience}
                                                    />
                                                    {/* <span className="error">{advancePageError?.modalidade ? '*' : ''}</span> */}
                                                </div>

                                                <div className="label-state">
                                                    <p>Profissional necessário:</p>
                                                    <Select
                                                        name="profissional_necessario"
                                                        styles={styles}
                                                        components={{
                                                            IndicatorSeparator: () => null
                                                        }}
                                                        theme={(theme) => ({
                                                            ...theme,
                                                            borderRadius: 8,
                                                            colors: {
                                                                ...theme.colors,
                                                                primary25: '#DDFAF4',
                                                                primary50: '#DDFAF4',
                                                                primary: '#02E8BE',
                                                            },
                                                        })}
                                                        placeholder='Selecionar'
                                                        defaultValue={DTODemand?.profissional_necessario !== '' ? { value: DTODemand.profissional_necessario, label: DTODemand.profissional_necessario } : ''}
                                                        onChange={(selected: any) => handleOnChange('profissional_necessario', selected.value)}
                                                        options={ProfessionalTypeNecessary}
                                                    />
                                                    {/* <span className="error">{advancePageError?.profissional_necessario ? '*' : ''}</span> */}
                                                </div>
                                            </div>
                                        }

                                        <div className="line">
                                            {
                                                demandInitial?.descricao_categoria_servico.split(' ')[0] === 'Audiências' &&
                                                <div className="label-state">
                                                    <p>Data e hora:</p>
                                                    {
                                                        demandInitial?.data !== null
                                                            ? <DateTimePicker
                                                                onChange={setDateHearing}
                                                                value={dateHearing}
                                                                calendarIcon={null}
                                                                disableClock={true}
                                                                className='date'
                                                            />
                                                            : <div className="no-data">Sem data</div>

                                                    }
                                                </div>
                                            }

                                            <InputDefault
                                                title="CEP:"
                                                name="cep"
                                                mask="99999-999"
                                                value={DTODemand.cep}
                                                onChangeText={(value) => consultCep('cep', value)}
                                                className={demandInitial?.cep !== DTODemand?.cep ? 'mid highlight' : 'mid'}
                                            />
                                        </div>

                                        <div className="line">
                                            <InputDefault
                                                title="Rua:"
                                                name="rua"
                                                value={DTODemand?.rua}
                                                // disabled={cepAddress ? true : false}
                                                onChangeText={(value) => handleOnChange('rua', value)}
                                                className={demandInitial?.rua !== DTODemand?.rua ? 'mid highlight' : 'mid'}
                                            />
                                            <InputDefault
                                                title="Número:"
                                                name="numero"
                                                value={DTODemand?.numero}
                                                onChangeText={(value) => handleOnChange('numero', value)}
                                                className={demandInitial?.numero !== DTODemand?.numero ? 'mid highlight' : 'mid'}
                                            />
                                        </div>

                                        <div className="line">
                                            <InputDefault
                                                title="Bairro:"
                                                name="bairro"
                                                value={DTODemand?.bairro}
                                                // disabled={cepCounty ? true : false}
                                                onChangeText={(value) => handleOnChange('bairro', value)}
                                                className={demandInitial?.bairro !== DTODemand?.bairro ? 'mid highlight' : 'mid'}
                                            />
                                            <InputDefault
                                                title="Complemento:"
                                                name="complemento"
                                                value={DTODemand?.complemento}
                                                onChangeText={(value) => handleOnChange('complemento', value)}
                                                className={demandInitial.complemento !== DTODemand?.complemento ? 'mid highlight' : 'mid'}
                                            />
                                        </div>
                                        
                                        {
                                            demandInitial?.modalidade !== 'presencial' &&
                                            <div className="line">
                                                <InputDefault
                                                    title="Endereço virtual:"
                                                    name="link"
                                                    value={DTODemand?.endereco_virtual}
                                                    onChangeText={(value) => handleOnChange('endereco_virtual', value)}
                                                    className={demandInitial?.endereco_virtual !== DTODemand?.endereco_virtual ? 'big highlight' : 'big'}
                                                />
                                            </div>
                                        }


                                </DemandAdress>

                                <ServiceTags>
                                    <span>Detalhes do serviço:</span>
                                    <div className="tags">
                                        {
                                            demandTags?.map((row: any) => (
                                                <div className={demandInitial?.tags.includes(row) ? 'tag' : 'tag highlight'} key={row}>
                                                    {row}
                                                    <div
                                                        onClick={() => setDemandTags(demandTags.filter((obj: any) => obj !== row))}
                                                        className='close'
                                                    >
                                                        <IconClose />
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <div
                                        className="add"
                                        onClick={() => setModalTags(!modalTags)}
                                    >
                                        <IconPlus /> Adicionar detalhes
                                    </div>
                                </ServiceTags>

                                <Pendencies>
                                    <span>Pendências</span>

                                    <div className="number-cards">
                                        <div className="card resolved">
                                                <div className="top">{pendencyCounters?.resolvidas ? pendencyCounters.resolvidas : 0 } <IconAccept /></div>
                                            <span>Resolvidas</span>
                                        </div>
                                        <div className="card accept">
                                            <div className="top">{pendencyCounters?.aceitas ? pendencyCounters.aceitas : 0} <IconAccept /></div>
                                            <span>Aceitas</span>
                                        </div>
                                        <div className="card refused">
                                            <div className="top">{pendencyCounters?.recusadas ? pendencyCounters.recusadas : 0} <IconWrong /></div>
                                            <span>Recusadas</span>
                                        </div>
                                        <div className="card waiting">
                                            <div className="top">{pendencyCounters?.aguardando ? pendencyCounters.aguardando : 0} <IconInProgress /></div>
                                            <span>Aguardando</span>
                                        </div>
                                        <div className="card response">
                                            <div className="top">{pendencyCounters?.responder ? pendencyCounters.responder : 0}</div>
                                            <span>Responder</span>
                                        </div>
                                        <div
                                            className="arrow"
                                            onClick={() => setOpenPendency(!openPendecy)}
                                        >
                                            {
                                                openPendecy
                                                    ? <IconArrowUp />
                                                    : <IconArrowDown />
                                            }

                                        </div>
                                    </div>


                                    {   
                                        openPendecy
                                        ?
                                            DTODemand.pendencias?.length > 0 &&
                                            DTODemand.pendencias.map((row: any) => (
                                                row.tipo === 'marjoracao'
                                                    ? <Pendency key={row.id_pendencia}>
                                                        <div className="header">
                                                            <div className="icon-title">
                                                                <IconAdvanceMoney />
                                                                Majoração
                                                            </div>

                                                            <div className="money">
                                                                {
                                                                    row.valor.toString().includes('.')
                                                                    ?   row.valor.toString().replace('.', ',')
                                                                    :   `R$ ${row.valor},00`
                                                                }                                                                
                                                                <span>Valor adiantado</span>
                                                            </div>

                                                            {
                                                                row.resolvida !== 'sim'
                                                                    ?   <div
                                                                            className="status resolve"
                                                                            onClick={() => { setModalAnswerPendency(true); setAnswerType('raise'); setPendencyInfos(row) }}
                                                                        >
                                                                            Responder
                                                                        </div>
                                                                    : row.aceita === 'sim'
                                                                        ? <div className="status success">
                                                                            <IconAccept />
                                                                            Aceita
                                                                        </div>
                                                                        : <div className="status refuse">
                                                                            <IconWrong />
                                                                            Recusada
                                                                        </div>
                                                            }
                                                        </div>

                                                        <div className="info">
                                                            {row.descricao}
                                                        </div>
                                                    </Pendency>
                                                :   row.tipo === 'reembolso' 
                                                    ? <Pendency key={row.id_pendencia}>
                                                            <div className="header">
                                                                <div className="icon-title">
                                                                    <IconAdvanceMoney />
                                                                    Reembolso
                                                                </div>

                                                                <div className="money">
                                                                    R$ {row.valor},00
                                                                    <span>Valor na fatura</span>
                                                                </div>
                                                                {
                                                                    row.resolvida !== 'sim'
                                                                    ?   <div
                                                                            className="status resolve"
                                                                            onClick={() => { setModalAnswerPendency(true); setAnswerType('refund'); setPendencyInfos(row) }}
                                                                        >
                                                                            Responder
                                                                        </div>
                                                                    :   row.aceita === 'sim'
                                                                        ?   <div className="status success">
                                                                                <IconAccept />
                                                                                Aceita
                                                                            </div>
                                                                        :   <div className="status refuse">
                                                                                <IconWrong />
                                                                                Recusada
                                                                            </div>
                                                                }
                                                            </div>

                                                            <div className="info">
                                                                {row.descricao}
                                                            </div>
                                                        </Pendency>
                                                        : row.tipo === 'adiantamento'
                                                            ?   <Pendency key={row.id_pendencia}>
                                                                    <div className="header">
                                                                        <div className="icon-title">
                                                                            <IconAdvanceMoney />
                                                                            Adiantamento
                                                                        </div>

                                                                        <div className="money">
                                                                            R$ {row.valor},00
                                                                            <span>Valor adiantado</span>
                                                                        </div>
                                                                        {
                                                                            row.resolvida !== 'sim'
                                                                                ?   <div
                                                                                        className="status resolve"
                                                                                        onClick={() => { setModalAnswerPendency(true); setAnswerType('advance'); setPendencyInfos(row) }}
                                                                                    >
                                                                                        Responder
                                                                                    </div>
                                                                                : row.aceita === 'sim'
                                                                                    ? <div className="status success">
                                                                                        <IconAccept />
                                                                                        Aceita
                                                                                    </div>
                                                                                    : <div className="status refuse">
                                                                                        <IconWrong />
                                                                                        Recusada
                                                                                    </div>
                                                                        }
                                                                    </div>

                                                                    <div className="info">
                                                                        {row.descricao}
                                                                    </div>
                                                                </Pendency>
                                                                : row.tipo === 'documentacao'
                                                                ?   <Pendency key={row.id_pendencia}>
                                                                            <div className="header">
                                                                                <div className="icon-title">
                                                                                    <IconAttachment />
                                                                                    Documentação
                                                                                </div>
                                                                                
                                                                                {
                                                                                    row.resolvida !== 'sim'
                                                                                        ?   row.destino === 'usuario' && user.profile === 'comum'
                                                                                            ?   <div className="date-status">
                                                                                                    <div className="date">
                                                                                                        <Moment locale='pt-br'fromNow>{row.criado}</Moment>
                                                                                                    </div>
                                                                                                    <div
                                                                                                        className="status resolve"
                                                                                                        onClick={() => { setModalAnswerPendency(true); setAnswerType('doc'); setPendencyInfos(row) }}
                                                                                                    >
                                                                                                        Responder
                                                                                                    </div>
                                                                                                </div>
                                                                                            :   row.destino !== 'usuario' && user.profile !== 'comum'
                                                                                                ?   <div className="date-status">
                                                                                                        <div className="date">
                                                                                                            <Moment locale='pt-br' fromNow>{row.criado}</Moment>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className="status resolve"
                                                                                                            onClick={() => { setModalAnswerPendency(true); setAnswerType('doc'); setPendencyInfos(row) }}
                                                                                                        >
                                                                                                            Responder
                                                                                                        </div>
                                                                                                    </div>
                                                                                                : <div className="date-status">
                                                                                                    <div className="date">
                                                                                                        <Moment locale='pt-br' fromNow>{row.criado}</Moment>
                                                                                                    </div>
                                                                                                    <div className="status waiting">
                                                                                                        <IconInProgress />
                                                                                                        Aguardando
                                                                                                    </div>
                                                                                                </div>
                                                                                        :   <div className="status-link">
                                                                                                <div className="status success">
                                                                                                    <IconAccept />
                                                                                                    Resolvida
                                                                                                </div>
                                                                                                <div
                                                                                                    className="button"
                                                                                                    onClick={scrollDown}
                                                                                                >
                                                                                                    <IconAttachment />
                                                                                                </div>
                                                                                            </div>
                                                                                }
                                                                            </div>

                                                                            <div className="list">
                                                                                <p>{row.descricao}</p> 
                                                                            </div>
                                                                        </Pendency>
                                                                : row.tipo === 'informacao'
                                                                    ?   <Pendency key={row.id_pendencia}>
                                                                            <div className="header">
                                                                                <div className="icon-title">
                                                                                    <IconInstruction />
                                                                                    Instrução
                                                                                </div>

                                                                                {
                                                                                    row.resolvida !== 'sim'
                                                                                        ? row.destino === 'usuario' && user.profile === 'comum'
                                                                                            ? <div
                                                                                                className="status resolve"
                                                                                                onClick={() => { setModalAnswerPendency(true); setAnswerType('info'); setPendencyInfos(row) }}
                                                                                            >
                                                                                                Responder
                                                                                            </div>
                                                                                            : row.destino !== 'usuario' && user.profile !== 'comum'
                                                                                                ? <div
                                                                                                    className="status resolve"
                                                                                                    onClick={() => { setModalAnswerPendency(true); setAnswerType('info'); setPendencyInfos(row) }}
                                                                                                >
                                                                                                    Responder
                                                                                                </div>
                                                                                                : <div className="status waiting">
                                                                                                    <IconInProgress />
                                                                                                    Aguardando
                                                                                                </div>
                                                                                        : <div className="status success">
                                                                                            <IconAccept />
                                                                                            Resolvida
                                                                                        </div>
                                                                                }

                                                                                
                                                                            </div>

                                                                            <div className="list">
                                                                                <p>{row.descricao}</p>
                                                                                {
                                                                                    row.resposta !== null
                                                                                    ?
                                                                                    <div className="answer">
                                                                                        <div className="title">Resposta:</div>
                                                                                        <div className="info">{row.resposta}</div>
                                                                                    </div>
                                                                                    : ''
                                                                                }
                                                                            </div>
                                                                        </Pendency>
                                                                    : row.tipo === 'correcao'
                                                                        ?
                                                                        <Pendency key={row.id_pendencia}>
                                                                            <div className="header">
                                                                                <div className="icon-title">
                                                                                    <IconInstruction />
                                                                                    Correção
                                                                                </div>

                                                                                {
                                                                                    row.resolvida !== 'sim'
                                                                                        ?  
                                                                                            <div className="status waiting">
                                                                                                <IconInProgress />
                                                                                                Aguardando
                                                                                            </div>
                                                                                        : <div className="status success">
                                                                                            <IconAccept />
                                                                                            Resolvida
                                                                                        </div>
                                                                                }
                                                                            </div>

                                                                            <div className="list">
                                                                                <p>{row.descricao}</p>
                                                                                {
                                                                                    row.resposta !== null
                                                                                    ?
                                                                                    <div className="answer">
                                                                                        <div className="title">Resposta:</div>
                                                                                        <div className="info">{row.resposta}</div>
                                                                                    </div>
                                                                                    : ''
                                                                                }
                                                                            </div>
                                                                        </Pendency>
                                                                        : 'Sem Pendências' 
                                            ))
                                        :   ''
                                    }
                                    {/* 

                                    <div className="instruction">
                                        <div className="header">
                                            <div className="icon-title">
                                                <IconAttachment />
                                                Instrução
                                            </div>
                                            <div className="status success">
                                                <IconAccept />
                                                Resolvida
                                            </div>
                                        </div>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sit egestas facilisis etiam estas semper scelerisque aliquam. ? Sit egestas facilisis?</p>
                                    </div>

                                     */}

                                    <div 
                                        className="add"
                                        onClick={() => setModalPendencies(true)}
                                    >
                                        <IconPendency /> 
                                        Adicionar pendências
                                    </div>

                                </Pendencies>
                                
                                {/* Orientações iniciais */}
                                <Orientations>
                                    <span>Orientações prévias:</span>
                                    {
                                        demandInitial?.orientacoes.length === 1 &&
                                        demandInitial?.orientacoes[0] === '' &&
                                        demandInitial?.orientacoes[0].id_proposta !== '' 
                                            ?   
                                                <>
                                                    <div className="info">
                                                        Sem orientações iniciais.
                                                    </div>                                                    
                                                </>
                                            :   
                                                <div className="info">{demandInitial?.orientacoes[0]}</div>                                                    
                                    }
                                    
                                </Orientations>
                                
                                {/* Orientações completas */}
                                <Orientations>
                                    <span>Orientações completas:</span>
                                    {
                                        demandInitial?.orientacoes.length === 1 &&
                                        demandInitial?.orientacoes[0] === '' &&
                                        demandInitial?.status_orientacao === null 
                                            ?   
                                                <>
                                                    <div className="info">
                                                        Sem orientações completas.
                                                    </div>
                                                    <Link
                                                        to={{
                                                            pathname: '/demanda/orientacoes',
                                                            state: {
                                                                demandInfo: demandInitial,
                                                                update: false,
                                                            }
                                                        }}
                                                    >
                                                        <div className="new">
                                                            <IconOrientation />
                                                            Adicionar orientação
                                                        </div>
                                                    </Link>
                                                </>
                                            :   <>  
                                                    {
                                                        demandInitial?.pendente_de_orientacao !== "sim"  &&
                                                        <div className="details">
                                                            <div className="flex">
                                                                {
                                                                    demandInitial?.orientacoes.length > 1
                                                                        ? demandInitial?.pendente_de_orientacao === "sim"
                                                                            ? ''
                                                                            :   demandInitial?.status_orientacao === 'Aguardando Ciência'
                                                                                ? 
                                                                                    <div className="status waiting">
                                                                                        <IconInProgress />
                                                                                        Aguardando ciência
                                                                                    </div>
                                                                                :                                                                            
                                                                                    <div className="status accept">
                                                                                        <IconAccept />
                                                                                        Ciente
                                                                                    </div>                                                                                  
                                                                                
                                                                        : demandInitial?.pendente_de_orientacao === "sim"
                                                                            ?   
                                                                                ''                                                                    
                                                                                : demandInitial?.status_orientacao === 'Aguardando Ciência'
                                                                                    ?
                                                                                    <div className="status waiting">
                                                                                        <IconInProgress />
                                                                                        Aguardando ciência
                                                                                    </div>
                                                                                    :
                                                                                    demandInitial?.status_orientacao === 'ciente'
                                                                                        ?
                                                                                        <div className="status accept">
                                                                                            <IconAccept />
                                                                                            Ciente
                                                                                        </div>
                                                                                        : ''
                                                                }

                                                                {
                                                                    demandInitial?.orientacoes.length > 1
                                                                        ? <div className="time">
                                                                            Atualizada <Moment locale='pt-br' fromNow>{demandInitial?.orientacoes[1]?.criado}</Moment>
                                                                        </div>
                                                                        : <div className="time">
                                                                            Atualizada <Moment locale='pt-br' fromNow>{demandInitial?.orientacoes[0]?.criado}</Moment>
                                                                        </div>
                                                                }

                                                            </div>                                                        
                                                        </div>
                                                    }

                                                    {
                                                        demandInitial?.orientacoes.length > 1 
                                                        ?
                                                            <OrientationsCard>
                                                                <div className="info">
                                                                    <span>Qual parte representamos:</span>
                                                                    <div className="info-details">
                                                                        {
                                                                            demandInitial?.orientacoes[1].parte_representada
                                                                                ?   demandInitial?.orientacoes[1].parte_representada.toLowerCase()
                                                                                :   ""
                                                                        }
                                                                    </div>
                                                                </div>

                                                                <div className="lawyer-info">
                                                                    <div className="lawyer">
                                                                        <span>Nome do advogado:</span>
                                                                        <div className="info-details">{demandInitial?.orientacoes[1].nome_advogado}</div>
                                                                    </div>
                                                                    <div className="number">
                                                                        <span>OAB:</span>
                                                                        <div className="info-details">{demandInitial?.orientacoes[1].oab_advogado}</div>
                                                                    </div>
                                                                </div>

                                                                <div className="info">
                                                                    <span>Nome do preposto:</span>
                                                                    <div className="info-details">{demandInitial?.orientacoes[1].nome_preposto}</div>
                                                                </div>

                                                                <div className="text-info">
                                                                    <span>Proposta de acordo:</span>
                                                                    <div className="info">{demandInitial?.orientacoes[1].proposta_acordo}</div>
                                                                </div>

                                                                <div className="text-info">
                                                                    <span>Requerimentos a realizar em audiência:</span>
                                                                    <div className="bullet">
                                                                        <ul>
                                                                            {
                                                                                demandInitial?.orientacoes[1].requerimentos &&
                                                                                demandInitial?.orientacoes[1].requerimentos.split('\n').map((row: any, key: any) => (
                                                                                    <li key={key}>{row.replace('•', '')}</li>
                                                                                ))
                                                                            }
                                                                        </ul>
                                                                    </div>
                                                                </div>

                                                                <div className="text-info">
                                                                    <span>Documentos a juntar:</span>
                                                                    <div className="bullet">
                                                                        <ul>
                                                                            {
                                                                                demandInitial?.orientacoes[1].documentos &&
                                                                                demandInitial?.orientacoes[1].documentos.split('\n').map((row: any, key: any) => (
                                                                                    <li key={key}>{row.replace('•', '')}</li>
                                                                                ))
                                                                            }
                                                                        </ul>
                                                                    </div>
                                                                </div>

                                                                <div className="text-info">
                                                                    <span>Testemunhas a ouvir:</span>
                                                                    <div className="info dot">{demandInitial?.orientacoes[1].testemunhas}</div>
                                                                </div>

                                                                <div className="text-info">
                                                                    <span>Pedidos relevantes da inicial:</span>
                                                                    <div className="info dot">{demandInitial?.orientacoes[1].pedidos}</div>
                                                                </div>

                                                                <div className="text-info">
                                                                    <span>Resumo da contestação:</span>
                                                                    <div className="info">{demandInitial?.orientacoes[1].resumo_contestacao}</div>
                                                                </div>

                                                                <div className="text-info">
                                                                    <span>Outras informações:</span>
                                                                    <div className="info">{demandInitial?.orientacoes[1].outras_informacoes}</div>
                                                                </div>

                                                                <Link
                                                                    to={{
                                                                        pathname: '/demanda/orientacoes',
                                                                        state: {
                                                                            demandInfo: demandInitial,
                                                                            update: true,
                                                                        }
                                                                    }}
                                                                >
                                                                    <div className="new">
                                                                        <IconEdit />
                                                                        Ajustar orientação
                                                                    </div>
                                                                </Link>
                                                            </ OrientationsCard>
                                                        :   
                                                            <>
                                                                <div className="info">
                                                                    Sem orientações completas.
                                                                </div>
                                                                    <Link
                                                                        to={{
                                                                            pathname: '/demanda/orientacoes',
                                                                            state: {
                                                                                demandInfo: demandInitial,
                                                                            }
                                                                        }}
                                                                    >
                                                                        <div className="new">
                                                                            <IconOrientation />
                                                                            Adicionar orientação
                                                                        </div>
                                                                    </Link>
                                                            </>
                                                          
                                                    }
                                                    
                                                    {/* {
                                                        demandInitial?.pendente_de_orientacao === "sim"
                                                            ? 
                                                            <Link
                                                                to={{
                                                                    pathname: '/demanda/orientacoes',
                                                                    state: {
                                                                        demandInfo: demandInitial,
                                                                    }
                                                                }}
                                                            >
                                                                <div className="new">
                                                                    <IconOrientation />
                                                                    Adicionar orientação
                                                                </div>
                                                            </Link>
                                                            : ''
                                                    } */}
                                                </>
                                    }
                                    {   
                                        demandInitial?.orientacoes.length > 1 &&
                                        demandInitial?.orientacoes[1].arquivos.length > 0 &&
                                        <OrientationsFiles>                                        
                                            <DemandFiles 
                                                title="Arquivos da orientação:"
                                                        files={demandInitial?.orientacoes[1].arquivos}
                                            />
                                        </OrientationsFiles>
                                    }
                                </Orientations>

                                <DemandFiles
                                    files={demandFiles}
                                    edit={editDemand}
                                    sendFiles={sendFiles}                           
                                />
                            </>
                        }

                    </DemandLeft>

                    <DemandInfos>

                        {
                            location.state.type === '1' &&
                            user.profile !== 'comum' &&
                            <InfoCard>
                                <span>Status:</span>
                                {   
                                    demandInitial?.status === 'Interrompida'                          
                                        ?   <div className="status interrupted">
                                                <IconStatus />
                                                Interrompida
                                            </div>
                                        : 
                                            <div 
                                                className={
                                                    demandInitial?.status === 'Cancelada' ? "status canceled" : '' ||
                                                    demandInitial?.pendente_de_orientacao === 'sim' ? "status interrupted" : '' ||
                                                    demandInitial?.com_pendencia ? "status interrupted" : '' ||
                                                    demandInitial?.atrasada === 'sim' ? "status interrupted" : '' ||                                                   
                                                    demandInitial?.status_pendencias === 'resolvida pelo usuario' ? "status pending" : ''                                                    
                                                }
                                            >
                                                <IconStatus />
                                                {
                                                    demandInitial?.status === 'Cancelada' ? "Cancelada" : '' ||
                                                    demandInitial?.pendente_de_orientacao === 'sim' ? "Pendente de Orientacao" : '' ||
                                                    demandInitial?.com_pendencia ? "Com pendência" : '' ||
                                                    demandInitial?.atrasada === 'sim' ? "Em atraso" : ''  ||
                                                    demandInitial?.status_pendencias === 'resolvida pelo usuario' ? "Pendência resolvida" : ''                                                  
                                                }
                                            </div> 
                                }                                
                            </InfoCard>
                        }

                        {
                            location.state.type === '1' &&
                            user.profile === 'comum' &&
                            <InfoCard>
                                <span>Status:</span>
                                {   
                                    demandInitial?.status_orientacao === 'Aguardando Ciência'                          
                                        ?   <div className="status interrupted">
                                                <IconStatus />
                                                Aguardando ciência
                                            </div>
                                        : 
                                            <div 
                                                className={
                                                    demandInitial?.status === 'Cancelada' ? "status canceled" : '' ||
                                                    demandInitial?.atrasada === 'sim' ? "status interrupted" : '' ||
                                                    demandInitial?.com_pendencia ? "status interrupted" : '' ||
                                                    demandInitial?.status_pendencias !== "sem pendencia" ? "status pending" : ''                                                    
                                                }
                                            >
                                                <IconStatus />
                                                {
                                                    demandInitial?.status === 'Cancelada' ? "Cancelada" : '' ||
                                                    demandInitial?.atrasada === 'sim' ? "Em atraso" : ''  ||
                                                    demandInitial?.com_pendencia ? "Com pendência" : '' ||
                                                    demandInitial?.status_pendencias !== "sem pendencia" ? "Pendência resolvida" : ''                                                  
                                                }
                                            </div> 
                                }
                                
                            </InfoCard>
                        }

                        {
                            location.state.type === '2' &&
                            user.profile !== 'comum' &&
                            <InfoCard>
                                <span>Status:</span>
                                {   
                                   <div 
                                        className={
                                            demandInitial?.status === 'Concluida' ? "status pending" : '' ||
                                            demandInitial?.status_orientacao === 'Aguardando Ciência' ? "status pending" : '' ||
                                            (demandInitial?.com_pendencia && demandInitial?.status_pendencias !== 'a resolver por operador') ? "status interrupted" : '' ||
                                            demandInitial?.status_pendencias !== "sem pendencia" ? "status pending" : '' ||
                                            demandInitial?.status === 'Em Andamento' ? "status progress" : '' 
                                        }
                                    >
                                        <IconStatus />
                                        {
                                            demandInitial?.status === 'Concluida' ? "Concluida" : ''  ||
                                            demandInitial?.status_orientacao === 'Aguardando Ciência' ? "Aguardando ciência" : '' ||
                                            (demandInitial?.com_pendencia && demandInitial?.status_pendencias !== 'a resolver por operador') ? "Com pendência" : '' ||
                                            demandInitial?.status_pendencias !== "sem pendencia" ? "Pendência resolvida" : '' ||
                                            demandInitial?.status === 'Em Andamento' ? "Em andamento" : '' 
                                        }
                                    </div> 
                                }                                
                            </InfoCard>
                        }

                        {
                            location.state.type === '2' &&
                            user.profile === 'comum' &&
                            <InfoCard>
                                <span>Status:</span>
                                {
                                    <div
                                        className={
                                            demandInitial?.status === 'Em Andamento' ? "status progress" : '' ||
                                            demandInitial?.status === 'Pendente de Orientacao' ? "status progress" : '' ||
                                            demandInitial?.com_pendencia ? "status interrupted" : '' ||
                                            demandInitial?.status_pendencias !== "sem pendencia" ? "status pending" : '' ||
                                            demandInitial?.status === 'Concluida' ? "status accepted" : '' 
                                        }
                                    >
                                        <IconStatus />
                                        {
                                            demandInitial?.status === 'Em Andamento' ? "Em andamento" : '' ||
                                            demandInitial?.status === 'Pendente de Orientacao' ? "Em andamento" : '' ||
                                            demandInitial?.com_pendencia ? "Com pendência" : '' ||
                                            demandInitial?.status_pendencias !== "sem pendencia" ? "Pendência resolvida" : '' ||
                                            demandInitial?.status === 'Concluida' ? "Concluída" : '' 
                                        }
                                    </div>
                                }
                            </InfoCard>
                        }

                        {
                            location.state.type === '3' &&
                            user.profile !== 'comum' &&
                            <InfoCard>
                                <span>Status:</span>
                              
                                    <div 
                                        className={                                            
                                            (demandInitial?.status === 'Finalizada' && demandInitial?.status_finalizacao === 'Aceita') ? "status finished accepted" : '' ||
                                            demandInitial?.status === 'Cancelada' ? "status canceled" : '' ||                                            
                                            (demandInitial?.status === 'Finalizada' && demandInitial?.status_finalizacao === 'Recusada') ? "status finished rejected" : ''
                                        }
                                    >
                                        <IconStatus />
                                        {
                                            (demandInitial?.status === 'Finalizada' && demandInitial?.status_finalizacao === 'Aceita') ? "Finalizada com aceite" : '' ||
                                            demandInitial?.status === 'Cancelada' ? "Cancelada" : ''  ||
                                            (demandInitial?.status === 'Finalizada' && demandInitial?.status_finalizacao === 'Recusada') ? "Finalizada com recusa" : ''                                            
                                        }
                                    </div>                                 
                                
                            </InfoCard>
                        }

                        {
                            location.state.type === '3' &&
                            user.profile === 'comum' &&
                            <InfoCard>
                                <span>Status:</span>
                              
                                    <div 
                                        className={                                            
                                            (demandInitial?.status === 'Finalizada' && demandInitial?.status_finalizacao === 'Aceita') ? "status finished accepted" : '' ||
                                            demandInitial?.status === 'Cancelada' ? "status canceled" : '' ||                                            
                                            (demandInitial?.status === 'Finalizada' && demandInitial?.status_finalizacao === 'Recusada') ? "status finished rejected" : '' ||
                                            demandInitial?.status === 'Interrompida' ? 'status finished interrupted' : ''
                                        }
                                    >
                                        <IconStatus />
                                        {
                                            (demandInitial?.status === 'Finalizada' && demandInitial?.status_finalizacao === 'Aceita') ? "Finalizada com aceite" : '' ||
                                            demandInitial?.status === 'Cancelada' ? "Cancelada" : ''  ||
                                            (demandInitial?.status === 'Finalizada' && demandInitial?.status_finalizacao === 'Recusada') ? "Finalizada com recusa" : ''  ||
                                            demandInitial?.status === 'Interrompida' ? 'Interrompida' : ''                                          
                                        }
                                    </div>                                 
                                
                            </InfoCard>
                        }



                        <InfoCard>
                            <span>Valor do profissional:</span>
                            <div className="value-profit">
                                <div className="icon"><IconServiceValue /></div>
                                <div className="value">
                                    {   
                                        demandInitial?.valor_profissional !== null 
                                        ?   demandInitial?.valor_profissional.toString().includes('.')
                                            ? <div className="value">R$ {demandInitial?.valor_profissional.toString().replace('.', ',')}</div>
                                            : <div className="value">R$ {demandInitial?.valor_profissional},00</div>   
                                        : 'R$ 0,00'
                                    }                                    
                                </div>
                                {
                                    user.profile !== 'comum' &&
                                    <div className="profit">
                                        {
                                            demandInitial?.lucro_porcentagem.includes('-')
                                                ?   <div className="profit negative">{demandInitial?.lucro_porcentagem.split('.')[0]}% de lucro</div>
                                                :   <div className="profit">+{demandInitial?.lucro_porcentagem.split('.')[0]}% de lucro</div> 
                                                
                                        }                                    
                                    </div>
                                }
                            </div>
                        </InfoCard>

                        <InfoCard>
                            <span>Cliente:</span>
                            <div className="value-profit">
                                <div className="icon"><IconUser /></div>
                                <div className="value">{DTODemand?.nome_cliente.split(' ')[0]}</div>
                                <div className="code">
                                    {
                                        demandInitial?.codigo_cliente.includes('C')
                                        ?   demandInitial?.codigo_cliente
                                        :   `C${demandInitial?.codigo_cliente.replace('c', '')}` 
                                    }
                                </div>
                            </div>
                        </InfoCard>

                        <InfoCard>
                            <span>Profissional:</span>
                            <div className="value-profit">
                                <div className="icon"><IconUser /></div>
                                <div className="value">{professional?.nome.split(' ')[0]}</div>
                                <div className="code">D{demandInitial?.id_usuario}</div>
                                <div
                                    className="button"
                                    onClick={() => setModalContact(true)}
                                >
                                    <IconThreeDotsH />
                                </div>
                            </div>
                        </InfoCard>

                        <DeadlineInfos>
                            <div className="deadline">
                                <span>Solicitada em:</span>
                                <div className="date-hour">
                                    <div className="info"><Moment locale='pt-br' format="DD/MM/YYYY">{demandInitial?.criado}</Moment></div>
                                    <div className="info"><Moment format="HH:mm">{demandInitial?.criado}</Moment></div>
                                </div>
                            </div>
                            <div className="deadline">
                                <span>Prazo para aceite:</span>
                                <div className="date-hour">
                                    <div className="info"><Moment locale='pt-br' format="DD/MM/YYYY">{demandInitial?.prazo_aceite}</Moment></div>
                                    <div className="info"><Moment format="HH:mm">{demandInitial?.prazo_aceite}</Moment></div>
                                </div>
                            </div>
                            <div className="deadline">
                                <span>Iniciada em:</span>
                                <div className="date-hour">
                                    <div className="info">
                                        {
                                            demandInitial?.data_inicio
                                                ? <Moment locale='pt-br' format="DD/MM/YYYY">{demandInitial?.data_inicio}</Moment>
                                                : 'Aguardando'
                                        }
                                    </div>
                                    <div className="info">
                                        {
                                            demandInitial?.data_inicio
                                                ? <Moment format="HH:mm">{demandInitial?.data_inicio}</Moment>
                                                : 'aceite'
                                        }

                                    </div>
                                </div>
                            </div>
                            <div className="deadline">
                                <span>Prazo para conclusão:</span>
                                <div className="date-hour">
                                    <div className="info"><Moment locale='pt-br' format="DD/MM/YYYY">{demandInitial?.prazo_para_conclusao}</Moment></div>
                                    <div className="info"><Moment format="HH:mm">{demandInitial?.prazo_para_conclusao}</Moment></div>
                                </div>
                            </div>
                        </DeadlineInfos>

                    </DemandInfos>
                </DemandDetails>
            }

            {/* Modal contatos do profissional */}
            <ModalDefault
                id={'overlayModal'}
                onClose={() => setModalContact((modalContact: any) => !modalContact)}
                openModal={modalContact}
                width="520px"
            >
                <ModalContactHead>
                    <div>
                        Entre em contato
                    </div>

                    <div
                        className="close"
                        onClick={() => setModalContact(false)}
                    >
                        <IconClose />
                    </div>
                </ModalContactHead>


                <ModalcontactMain>
                    <div>{professional?.email}</div>
                    <div>{professional?.celular.replace(/\D/g, '')
                        .replace(/(\d{2})(\d)/, '($1) $2')
                        .replace(/(\d{5})(\d)/, '$1-$2')
                        .replace(/(-\d{4})\d+?$/, '$1')}
                    </div>
                    <div>{professional?.tel_fixo
                        ? professional.tel_fixo.replace(/\D/g, '')
                            .replace(/(\d{2})(\d)/, '($1) $2')
                            .replace(/(\d{4})(\d)/, '$1-$2')
                            .replace(/(-\d{4})\d+?$/, '$1')
                        : 'sem telefone fixo'}
                    </div>
                    <div>{professional?.tel_recado
                        ? professional.tel_recado.replace(/\D/g, '')
                            .replace(/(\d{2})(\d)/, '($1) $2')
                            .replace(/(\d{4})(\d)/, '$1-$2')
                            .replace(/(-\d{4})\d+?$/, '$1')
                        : 'sem telefone de recado'}
                    </div>
                </ModalcontactMain>
            </ModalDefault>

            {/* Modal finalizar demanda */}
            <ModalDefault
                id={'overlayModal'}
                onClose={() => setFinishDemand((finishDemand: any) => !finishDemand)}
                openModal={finishDemand}
                width="520px"
            >

                <ModalWrapper>
                    <ModalTitle>
                        <div className="points">
                            Finalizar demanda
                            <span>{secondStage ? <IconActivityTwo /> : <IconActivityOne />}</span>
                        </div>
                        <div
                            className="close"
                            onClick={() => setFinishDemand(false)}
                        >
                            <IconClose />
                        </div>
                    </ModalTitle>

                    {
                        demandResult === '' &&
                        <ModalResult>
                            <div 
                                className="button"
                                onClick={() => { setDemandResult('accept'); setSecondStage(true) }}
                            >
                                <div className="title accept"><IconAccept /> Aceitar</div>
                                <div className="subtitle">Resultado da demanda está adequado.</div>
                            </div>
                            <div 
                                className="button"
                                onClick={() => { setDemandResult('reject'); setSecondStage(true) }}
                            >
                                <div className="title reject"><IconWrong /> Recusar</div>
                                <div className="subtitle">Resultado da demanda está inadequado.</div>
                            </div>
                        </ModalResult>
                    }

                    {
                        demandResult === 'accept' &&
                        <ModalResult>
                            <div className="accept-card">
                                <div className="title accept"><IconAccept /> Aceitar</div>
                                <div className="subtitle">Resultado da demanda está adequado.</div>
                            </div>

                            <div className="text-title">Gostaria de dizer algo para {professional?.nome.split(' ')[0]} D{demandInitial?.id_usuario}?</div>

                            <ModalJustification>
                                <textarea
                                    name="justify"
                                    id="1"
                                    className="text-area"
                                    placeholder="Escreva aqui sua mensagem"
                                    onChange={(e) => setJustificationText(e.target.value)}
                                />
                            </ModalJustification>

                            {/* <div className="radio-input">
                                <input
                                    type="radio"
                                    value='Autorizar'
                                    checked={allowPayment === 'Autorizar' ? true : false}
                                    onChange={radioChangeHandler}
                                    placeholder="Autorizar pagamento"
                                />
                                <span>Autorizar pagamento</span>
                            </div>     */}

                            <ModalButtons>

                                <ButtonDefault
                                    className="cancel"
                                    onClick={() => {setDemandResult(''); setSecondStage(false); setAllowPayment('')}}
                                >
                                    Voltar
                                </ButtonDefault>
                                <ButtonDefault
                                    className="continue"
                                    onClick={() => endDemand('yes')}
                                >
                                    Finalizar
                                </ButtonDefault>

                            </ModalButtons>                        
                        </ModalResult>
                    }

                    {
                        demandResult === 'reject' &&
                        <ModalResult>
                            <div className="reject-card">
                                <div className="title reject"><IconWrong /> Recusar</div>
                                <div className="subtitle">Resultado da demanda está inadequado.</div>
                            </div>

                            <div className="text-title">Justifique a recusa:</div>

                            <ModalJustification>
                                <textarea
                                    name="justify"
                                    id="1"
                                    className="text-area"
                                    placeholder="Escreva aqui sua mensagem"
                                    onChange={(e) => setJustificationText(e.target.value)}
                                />
                            </ModalJustification>

                            {/* <div className="radio-input">
                                <input
                                    type="radio"
                                    value='Autorizar'
                                    checked={allowPayment === 'Autorizar' ? true : false}
                                    onChange={radioChangeHandler}
                                    placeholder="Autorizar pagamento"
                                />
                                <span>Autorizar pagamento</span>
                            </div> */}

                            <ModalButtons>

                                <ButtonDefault
                                    className="cancel"
                                    onClick={() => { setDemandResult(''); setSecondStage(false); setAllowPayment('') }}
                                >
                                    Voltar
                                </ButtonDefault>
                                <ButtonDefault
                                    className="continue"
                                    onClick={() => endDemand('reject')}
                                >
                                    Finalizar
                                </ButtonDefault>

                            </ModalButtons>
                        </ModalResult>
                    }                   

                </ModalWrapper>

            </ModalDefault>            

            {/* Modal para entregar demanda*/}
            <ModalDefault
                id={'overlayModal'}
                onClose={() => setHandDemand((handDemand: any) => !handDemand)}
                openModal={handDemand}
                width="600px"
            >

                <ModalWrapper>
                    <ModalTitle>
                        <div className="points">
                            Entregar demanda
                            <span>{secondStage ? <IconActivityTwo /> : <IconActivityOne />}</span>
                        </div>
                        <div
                            className="close"
                            onClick={() => setHandDemand(false)}
                        >
                            <IconClose />
                        </div>
                    </ModalTitle>

                    {
                        demandResult === '' &&
                        <ModalResult>
                            <div
                                className="button"
                                onClick={() => { setDemandResult('accept'); setSecondStage(true) }}
                            >
                                <div className="title accept"><IconAccept />Concluir</div>
                                <div className="subtitle">A demanda foi realizada conforme o combinado.</div>
                            </div>
                            <div
                                className="button"
                                onClick={() => { setDemandResult('reject'); setSecondStage(true) }}
                            >
                                <div className="title reject"><IconWrong />Interromper</div>
                                <div className="subtitle">Não é possível continuar com a demanda.</div>
                            </div>
                        </ModalResult>
                    }

                    {
                        demandResult === 'accept' &&
                        <ModalResult>
                            
                            <div className="upload conclusion">
                                <>
                                    <div className="title">Caso necessite, faça download do arquivo de conclusão abaixo:</div>
                                    <a href="/formulario-conclusao.docx" target="_blank" rel="noreferrer">
                                        <div className="download">
                                            Arquivo de conclusão
                                            <IconDownload />
                                        </div>
                                    </a>  

                                    <div className="add">
                                        <IconUpload />
                                        <input
                                            type="file"
                                            name="Files"
                                            className="file-input"
                                            onChange={sendFiles}
                                            id="conclusion"
                                        />
                                    </div> 
                                    {
                                        demandInitial.descricao_categoria_servico === 'Audiências' &&
                                        <div className="add">
                                            <IconUpload />
                                            <input
                                                type="file"
                                                name="Files"
                                                className="file-input-two"
                                                onChange={sendFiles}
                                                id="finish"
                                            />
                                        </div>  
                                    }

                                    {
                                        demandInitial.descricao_categoria_servico !== 'Audiências' &&
                                        <div className="add">
                                            <IconUpload />
                                            <input
                                                type="file"
                                                name="Files"
                                                className="file-input-three"
                                                onChange={sendFiles}
                                                id="finish"
                                            />
                                        </div>  
                                    }

                                    <div className="files">
                                        <div className="flex">
                                            <span>Arquivos de conclusão enviados:</span>
                                        </div>
                                        {
                                            conclusionFiles.length > 0 &&
                                            conclusionFiles.map((row: any) => (
                                                <div className="file-info" key={row}>
                                                    <div className="name-file">
                                                        {row}
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    
                                    <ModalJustification>
                                        <textarea
                                            name="justify"
                                            id="1"
                                            className="text-area bold"
                                            placeholder="Escreva aqui sua mensagem de conclusão"
                                            onChange={(e) => setJustificationText(e.target.value)}
                                        />
                                    </ModalJustification>
                                </>                                    
                                
                                
                            </div>
                            

                            <ModalButtons>

                                <ButtonDefault
                                    className="cancel"
                                    onClick={() => { setDemandResult(''); setSecondStage(false);}}
                                >
                                    Voltar
                                </ButtonDefault>
                                <ButtonDefault
                                    className="continue"
                                    onClick={() => concludeDemand('Concluida')}                                    
                                >
                                    Concluir
                                </ButtonDefault>

                            </ModalButtons>
                        </ModalResult>
                    }

                    {
                        demandResult === 'reject' &&
                        <ModalResult>
                            <div className="reject-card">
                                <div className="title reject"><IconWrong />Interromper</div>
                                <div className="subtitle">Não é possível continuar com a demanda.</div>
                            </div>

                            <div className="disclaimer">
                                <div className="bold">
                                    Neste caso, <span>você pode perder o direito a receber o valor pela realização da demanda.</span>
                                </div>
                                <p>Só interrompa a demanda caso haja tempo hábil para recontratação.</p>

                            </div>

                            <div className="text-title">Justifique a desistência:</div>

                            <ModalJustification>
                                <textarea
                                    name="justify"
                                    id="1"
                                    className="text-area bold"
                                    placeholder="Escreva aqui sua mensagem"
                                    onChange={(e) => setJustificationText(e.target.value)}
                                />
                            </ModalJustification>

                            <ModalButtons>

                                <ButtonDefault
                                    className="cancel"
                                    onClick={() => { setDemandResult(''); setSecondStage(false); }}
                                >
                                    Voltar
                                </ButtonDefault>
                                <ButtonDefault
                                    className="danger"
                                    onClick={() => concludeDemand('Interrompida')}
                                >
                                    Interromper
                                </ButtonDefault>

                            </ModalButtons>
                        </ModalResult>
                    }

                </ModalWrapper>

            </ModalDefault>  

            {/* Modal Tags */}
            <ModalDefault
                id={'overlayModal'}
                onClose={() => setModalTags((modalTags: any) => !modalTags)}
                openModal={modalTags}
                width="520px"
            >

                <ModalWrapper>
                    <ModalTitle>
                        <div className="points">
                            Adicionar detalhes
                        </div>
                        <div
                            className="close"
                            onClick={() => setModalTags(false)}
                        >
                            <IconClose />
                        </div>
                    </ModalTitle>

                    <ModalSearch>
                        <div className="title">Selecione ou crie tags para descrever o serviço:</div>

                        <div className="search">
                            <div
                                className="buscar"
                                onClick={() => getTags()}
                            >
                                <IconSearch />
                            </div>
                            <input
                                placeholder="Nome da tag"
                                onChange={(e) => setTag(e.target.value)}
                                onKeyPress={(e) => searchTags(e)}
                                value={tag}
                            />
                            {
                                filteredTags && !loading &&
                                <div
                                    className="clean-search"
                                    onClick={() => { setFilteredTags(''); setTag('') }}
                                >
                                    <IconClose />
                                </div>
                            }

                            <div className={filteredTags ? 'show-tags' : 'show-tags off'}>
                                {
                                    filteredTags?.length > 0
                                        ? <span>Selecione uma das tags:</span>
                                        : <span className="empty">0 tags encontradas para a sua pesquisa.</span>
                                }

                                <div className="tag-arrays">
                                    {
                                        filteredTags &&
                                        filteredTags.map((row: any) => (
                                            <div
                                                className="tag"
                                                onClick={() => setNewTags(row.tag)}
                                                key={row.id_tag}
                                            >
                                                {row.tag}
                                            </div>
                                        ))
                                    }
                                </div>

                                {
                                    filteredTags &&
                                        filteredTags?.find((obj: { tag: any; }) => obj.tag === tag)
                                        ? ''
                                        : <>
                                            <span className="span-no-tag">Crie uma nova tag para:</span>
                                            <div className="no-tags">
                                                <div
                                                    className="tag"
                                                    onClick={() => { setNewTags(tag); setTag(''); setFilteredTags(''); }}
                                                >
                                                    {tag}
                                                </div>
                                            </div>
                                        </>
                                }


                            </div>
                        </div>
                    </ModalSearch>


                </ModalWrapper>

            </ModalDefault>

            {/* Modal cancelar demanda */}
            <ModalDefault
                id={'overlayModal'}
                onClose={() => setCancelDemandModal((cancelDemandModal: any) => !cancelDemandModal)}
                openModal={cancelDemandModal}
                width="520px"
            >

                <ModalWrapper>
                    <ModalTitle>
                        <div className="points">
                            Deseja cancelar esta demanda?
                            <span>{secondStage ? <IconDeleteTwo /> : <IconDeleteOne />}</span>
                        </div>
                        <div
                            className="close"
                            onClick={() => setCancelDemandModal(false)}
                        >
                            <IconClose />
                        </div>
                    </ModalTitle>

                    {
                        secondStage !== true &&
                        <div className="justify">
                            <p>Esta ação não pode ser desfeita.</p>
                            <span>Justifique sua decisão:</span>
                        </div>
                    }

                    {
                        secondStage === true &&
                        <div className="justify cancel">
                            <p>A demanda {proposalId} será cancelada.</p>
                            <span>Gostaria de reaproveitar as definições desta demanda na criação de uma nova?</span>
                        </div>
                    }


                    {
                        secondStage !== true &&
                        <ModalJustification>
                            <textarea
                                name="justify"
                                id="1"
                                className="text-area"
                                onChange={(e) => setJustificationText(e.target.value)}

                            />
                        </ModalJustification>
                    }

                    <ModalButtons>

                        {
                            secondStage
                                ? <>
                                    <ButtonDefault
                                        className="cancel"
                                        onClick={cancelDemand}
                                    >
                                        Apenas cancelar
                                    </ButtonDefault>
                                    <ButtonDefault
                                        className="continue"
                                        onClick={duplicateDemand}
                                    >
                                        Duplicar demanda
                                    </ButtonDefault>
                                </>
                                : <>
                                    <ButtonDefault
                                        className="cancel"
                                        onClick={() => setCancelDemandModal(false)}
                                    >
                                        Voltar
                                    </ButtonDefault>
                                    <ButtonDefault
                                        className="continue"
                                        // disabled={justicationText === undefined ? true : false}
                                        onClick={() => beforeCancelDemand()}
                                    >
                                        Continuar
                                    </ButtonDefault>
                                </>
                        }


                    </ModalButtons>

                </ModalWrapper>

            </ModalDefault>

            {/* Modal add pendência */}
            <ModalDefault
                id={'overlayModal'}
                onClose={() => setModalPendencies((modalPendencies: any) => !modalPendencies)}
                openModal={modalPendencies}
                width="520px"
            >
                <ModalContactHead>
                    <div>
                        Adicionar pendência
                    </div>

                    <div
                        className="close-pendencie"
                        onClick={() => setModalPendencies(false)}
                    >
                        <IconClose />
                    </div>
                </ModalContactHead>
                
                <ModalWrapper>

                    {
                        pendencieType === '' &&
                        <ModalPendenciesType>
                            {
                                user.profile !== 'comum' &&
                                <div 
                                    className="type"
                                    onClick={() => setPendencieType('correct')}
                                >
                                    <IconEdit />
                                    Correção
                                </div>
                            }
                            <div 
                                className="type"
                                onClick={() => setPendencieType('info')}
                            >
                                <IconInstruction />
                                Informação
                            </div>
                            <div 
                                className="type"
                                onClick={() => setPendencieType('doc')}
                            >
                                <IconAttachment />
                                Documentação
                            </div>
                        </ModalPendenciesType>
                    }

                    {
                        pendencieType === 'correct' &&
                        <ModalPendenciesType>
                            <div
                                className="show-type"
                            >
                                <IconEdit />
                                Correção
                            </div>

                            <div className="description">
                                <span>Descreva a alteração necessária:</span>

                                <div className="text">
                                    <textarea
                                        name="justify"
                                        id="1"
                                        className="text-area"
                                        onChange={(e) => setJustificationText(e.target.value)}
                                    />
                                </div>
                            </div>

                        </ModalPendenciesType>
                    }

                    {
                        pendencieType === 'info' &&
                        <ModalPendenciesType>
                            <div
                                className="show-type"
                            >
                                <IconEdit />
                                Informação
                            </div>

                            <div className="description">
                                <span>Descreva a instrução necessária:</span>

                                <div className="text">
                                    <textarea
                                        name="justify"
                                        id="1"
                                        className="text-area"
                                        onChange={(e) => setJustificationText(e.target.value)}
                                    />
                                </div>
                            </div>

                        </ModalPendenciesType>
                    }

                    {
                        pendencieType === 'doc' &&
                        <ModalPendenciesType>
                            <div
                                className="show-type"
                            >
                                <IconAttachment />
                                Documentação
                            </div>

                            <div className="description">
                                <span>Descreva a solicitação:</span>

                                <div className="text">
                                    <textarea
                                        name="justify"
                                        id="1"
                                        className="text-area"
                                        onChange={(e) => setJustificationText(e.target.value)}
                                    />
                                </div>
                            </div>

                        </ModalPendenciesType>
                    }

                    {
                        pendencieType !== '' &&
                        <ModalButtons>

                            <ButtonDefault
                                className="cancel"
                                onClick={() => setPendencieType('')}
                            >
                                Voltar
                            </ButtonDefault>
                            <ButtonDefault
                                className="continue"
                                disabled={justicationText === undefined ? true : false}
                                onClick={createPendency}
                            >
                                Solicitar
                            </ButtonDefault>

                        </ModalButtons>
                    }
                </ModalWrapper>

                

            </ModalDefault>

            {/* Modal responder pendência */}
            <ModalDefault
                id={'overlayModal'}
                onClose={() => setModalAnswerPendency((modalAnswerPendency: any) => !modalAnswerPendency)}
                openModal={modalAnswerPendency}
                width="520px"
            >
                <ModalContactHead>
                    <div>
                        Responder pendência
                    </div>

                    <div
                        className="close-pendencie"
                        onClick={() => setModalAnswerPendency(false)}
                    >
                        <IconClose />
                    </div>
                </ModalContactHead>

                {
                    answerType === 'refund' &&
                    <ModalAnswerType>
                        <div className="title">                                
                            {professional?.nome.split(' ')[0]} {demandInitial?.id_usuario} solicitou <span>reembolso</span> no valor de:
                        </div>

                        <div className="value">
                            <div className="icon"><IconServiceValue /></div>
                            {
                                pendencyInfos?.valor.toString().includes('.')
                                    ?   `R$ ${pendencyInfos?.valor.toString().replace('.', ',')}`
                                    :   `R$ ${pendencyInfos?.valor},00`
                            }
                        </div>

                        <div className="justification">Justificado por: “{pendencyInfos?.descricao}”</div>

                        <ModalButtons>

                            <ButtonDefault
                                className="cancel"
                                onClick={() => { setAnswerType(''); setModalAnswerPendency(false); refusePendency(pendencyInfos.id_pendencia); }}
                            >
                                Recusar
                            </ButtonDefault>
                            <ButtonDefault
                                className="continue"
                                onClick={() => resolvePendency(pendencyInfos.id_pendencia)}
                            >
                                Aceitar
                            </ButtonDefault>

                        </ModalButtons>
                    </ModalAnswerType>
                }

                {
                    answerType === 'raise' &&
                    <ModalAnswerType>
                        <div className="title">
                            {professional?.nome.split(' ')[0]} {demandInitial?.id_usuario} solicitou <span>majoração</span> para este serviço.                              
                        </div>

                        <div className="values">
                            <div className="old-price">
                                <p>De:</p>  
                                {
                                    demandInitial?.valor_cliente.toString().includes('.')
                                        ? `R$ ${demandInitial?.valor_cliente.toString().replace('.', ',')}`
                                        : `R$ ${demandInitial?.valor_cliente},00`
                                }
                                <div className="icon">
                                    <IconServiceValue />
                                </div>
                            </div>
                            <div className="new-price">
                                <p>Para:</p>
                                {
                                    raiseValue?.toString().includes('.')
                                    ?   `R$ ${raiseValue.toString().replace('.', ',')}`
                                    :   `R$ ${raiseValue},00`
                                }
                                
                                <div className="icon">
                                    <IconServiceValue />
                                </div>
                            </div>
                        </div>

                        <div className="justification">Justificada por: “{pendencyInfos?.descricao}”</div>

                        <div className="bold">O valor precisa ser adiantado</div>                        
                        
                        <ModalButtons>

                            <ButtonDefault
                                className="cancel"
                                    onClick={() => { setAnswerType(''); setModalAnswerPendency(false); refusePendency(pendencyInfos.id_pendencia); }}
                            >
                                Recusar
                            </ButtonDefault>
                            <ButtonDefault
                                className="continue"
                                onClick={() => resolvePendency(pendencyInfos.id_pendencia)}
                            >
                                Aceitar
                            </ButtonDefault>

                        </ModalButtons>
                    </ModalAnswerType>
                }

                {
                    answerType === 'advance' &&
                    <ModalAnswerType>
                        <div className="title">
                            {professional?.nome.split(' ')[0]} {demandInitial?.id_usuario} solicitou <span>adiantamento</span> no valor de:
                        </div>

                        <div className="value">
                            <div className="icon"><IconServiceValue /></div>
                            {
                                pendencyInfos?.valor.toString().includes('.')
                                    ? `R$ ${pendencyInfos?.valor.toString().replace('.', ',')}`
                                    : `R$ ${pendencyInfos?.valor},00`
                            }
                        </div>

                        <div className="justification">Justificado por: “{pendencyInfos?.descricao}”</div>

                        <ModalButtons>

                            <ButtonDefault
                                className="cancel"
                                onClick={() => { setAnswerType(''); setModalAnswerPendency(false); refusePendency(pendencyInfos.id_pendencia); }}
                            >
                                Recusar
                            </ButtonDefault>
                            <ButtonDefault
                                className="continue"
                                onClick={() => resolvePendency(pendencyInfos.id_pendencia)}
                            >
                                Aceitar
                            </ButtonDefault>

                        </ModalButtons>

                    </ModalAnswerType>
                }
                {
                    answerType === 'doc' &&
                    <ModalAnswerType>
                        <div className="title">
                            {professional?.nome.split(' ')[0]} {demandInitial?.id_usuario} solicitou um <span>documento</span>:
                        </div>

                        <div className="request">“{pendencyInfos?.descricao}”</div>

                        {
                            demandFiles?.length > 0 &&
                            demandFiles.map((row: any) => (
                                <div className="file" key={row}>
                                    <div className={demandInitial?.arquivos?.includes(row) ? "file-details" : 'file-details highlight'} key={row}>
                                        <div className="icon-title">
                                            <IconAttachment />
                                            {row.caminho}
                                        </div>
                                    </div>                                    
                                    {/* <div
                                        className="icon-trash"
                                        onClick={() => setDemandFiles(demandFiles.filter((obj: any) => obj !== row))}
                                    >
                                        <IconTrash />
                                    </div> */}
                                </div>
                            ))
                        }

                        <div className="add">
                            <IconUpload />
                            <input
                                type="file"
                                name="Files"
                                // accept="image/*"
                                className="file-input"
                                onChange={sendFiles}
                            />
                        </div>

                        <ModalButtons>

                            <ButtonDefault
                                className="cancel"
                                onClick={() => { setAnswerType(''); setModalAnswerPendency(false) }}
                            >
                                Voltar
                            </ButtonDefault>
                            <ButtonDefault
                                className="continue"
                                onClick={() => resolvePendency(pendencyInfos.id_pendencia)}
                            >
                                Resolver
                            </ButtonDefault>

                        </ModalButtons>

                    </ModalAnswerType>
                }                
                {
                    answerType === 'info' &&
                    <ModalAnswerType>
                        <div className="title">
                            {professional?.nome.split(' ')[0]} {demandInitial?.id_usuario} solicitou uma <span>informação</span>:
                        </div>

                        <div className="request">“{pendencyInfos?.descricao}”</div>
                        
                        <div className="info-description">Descreva a sua resposta abaixo:</div>

                        <div className="text">
                            <textarea
                                name="justify"
                                id="1"
                                className="text-area"
                                onChange={(e) => setJustificationText(e.target.value)}
                            />
                        </div>

                        <ModalButtons>

                            <ButtonDefault
                                className="cancel"
                                onClick={() => { setAnswerType(''); setModalAnswerPendency(false) }}
                            >
                                Voltar
                            </ButtonDefault>
                            <ButtonDefault
                                className="continue"
                                onClick={() => resolvePendency(pendencyInfos.id_pendencia)}
                            >
                                Resolver
                            </ButtonDefault>

                        </ModalButtons>

                    </ModalAnswerType>
                }                
                {
                    answerType === 'correc' &&
                    <ModalAnswerType>
                        <div className="title">
                            {professional?.nome.split(' ')[0]} {demandInitial?.id_usuario} solicitou uma <span>correção</span>:
                        </div>

                        <div className="request">“{pendencyInfos?.descricao}”</div>
                        
                        <div className="info-description">Descreva a sua resposta abaixo:</div>

                        <div className="text">
                            <textarea
                                name="justify"
                                id="1"
                                className="text-area"
                                onChange={(e) => setJustificationText(e.target.value)}
                            />
                        </div>

                        <ModalButtons>

                            <ButtonDefault
                                className="cancel"
                                onClick={() => { setAnswerType(''); setModalAnswerPendency(false) }}
                            >
                                Voltar
                            </ButtonDefault>
                            <ButtonDefault
                                className="continue"
                                onClick={() => resolvePendency(pendencyInfos.id_pendencia)}
                            >
                                Resolver
                            </ButtonDefault>

                        </ModalButtons>

                    </ModalAnswerType>
                }                

            </ModalDefault>

            {/* Modal add pendência pagto*/}
            <ModalDefault
                id={'overlayModal'}
                onClose={() => setMoneyModal((moneyModal: any) => !moneyModal)}
                openModal={moneyModal}
                width="520px"
            >
                <ModalContactHead>
                    <div className="payment">
                        Pendência de pagamento
                        <IconActivityOne />
                    </div>

                    <div
                        className="close-pendencie"
                        onClick={() => setMoneyModal(false)}
                    >
                        <IconClose />
                    </div>
                </ModalContactHead>

                <ModalWrapper>

                    {
                        pendencieType === '' &&
                        <ModalPendenciesType>
                            
                            <div
                                className="type"
                                onClick={() => setPendencieType('maj')}
                            >
                                <IconAdvanceMoney />
                                Majoração
                            </div>
                            
                            <div
                                className="type"
                                onClick={() => setPendencieType('refund')}
                            >
                                <IconAdvanceMoney />
                                Reembolso
                            </div>

                            <div
                                className="type"
                                onClick={() => setPendencieType('advance')}
                            >
                                <IconAdvanceMoney />
                                Adiantamento
                            </div>
                        </ModalPendenciesType>
                    }

                    {
                        pendencieType === 'maj' &&
                        <ModalPendenciesType>
                            <div
                                className="show-type"
                            >
                                <IconAdvanceMoney />
                                Majoração
                            </div>

                            <div className="value">
                                <span>Valor:</span>
                                <div className="price">
                                    <InputMoney
                                        name="from"
                                        placeholder="R$ 00,00"
                                        mask="R$ 999.999,99"
                                        value={'sem dados'}
                                        onChangeText={(value) => setPendencyValue(value.replace('R$', '').replace(',', '.'))}
                                        className='small'
                                    />
                                    <div className="radio">
                                        <input 
                                            type="radio" 
                                            name="Adiantado" 
                                            id="1" 
                                            onClick={() => setSelectedRadio(!selectedRadio)}
                                            checked={selectedRadio}
                                        />
                                        <span>Adiantado</span>
                                    </div>
                                </div>
                            </div>

                            <div className="description">
                                <span>Descreva a solicitação:</span>

                                <div className="text money">
                                    <textarea
                                        name="justify"
                                        id="1"
                                        className="text-area"
                                        onChange={(e) => setJustificationText(e.target.value)}
                                    />
                                </div>
                            </div>

                        </ModalPendenciesType>
                    }

                    {
                        pendencieType === 'refund' &&
                        <ModalPendenciesType>
                            <div
                                className="show-type"
                            >
                                <IconEdit />
                                Reembolso
                            </div>

                            <div className="value">
                                <span>Valor:</span>
                                <div className="price">
                                    <InputMoney
                                        name="from"
                                        placeholder="R$ 00,00"
                                        mask="R$ 999.999,99"
                                        value={'sem dados'}
                                        onChangeText={(value) => setPendencyValue(value.replace('R$', '').replace(',', '.'))}
                                        className='small'
                                    />
                                    <div className="radio">
                                        <input
                                            type="radio"
                                            name="Adiantado"
                                            id="1"
                                            onClick={() => setSelectedRadio(!selectedRadio)}
                                            checked={selectedRadio}
                                        />
                                        <span>Adiantado</span>
                                    </div>
                                </div>
                            </div>

                            <div className="description">
                                <span>Descreva a solicitação:</span>

                                <div className="text">
                                    <textarea
                                        name="justify"
                                        id="1"
                                        className="text-area"
                                        onChange={(e) => setJustificationText(e.target.value)}
                                    />
                                </div>
                            </div>

                        </ModalPendenciesType>
                    }

                    {
                        pendencieType === 'advance' &&
                        <ModalPendenciesType>
                            <div
                                className="show-type"
                            >
                                <IconAttachment />
                                Adiantamento
                            </div>

                            <div className="value">
                                <span>Valor:</span>
                                <div className="price">
                                    <InputMoney
                                        name="from"
                                        placeholder="R$ 00,00"
                                        mask="R$ 999.999,99"
                                        value={'sem dados'}
                                        onChangeText={(value) => setPendencyValue(value.replace('R$', '').replace(',', '.'))}
                                        className='small'
                                    />
                                    <div className="radio">
                                        <input
                                            type="radio"
                                            name="Adiantado"
                                            id="1"
                                            onClick={() => setSelectedRadio(!selectedRadio)}
                                            checked={selectedRadio}
                                        />
                                        <span>Valor total do serviço</span>
                                    </div>
                                </div>
                            </div>

                            <div className="description">
                                <span>Descreva a solicitação:</span>                              

                                <div className="text">
                                    <textarea
                                        name="justify"
                                        id="1"
                                        className="text-area"
                                        onChange={(e) => setJustificationText(e.target.value)}
                                    />
                                </div>
                            </div>

                        </ModalPendenciesType>
                    }

                    {
                        pendencieType !== '' &&
                        <ModalButtons>

                            <ButtonDefault
                                className="cancel"
                                onClick={() => setPendencieType('')}
                            >
                                Voltar
                            </ButtonDefault>
                            <ButtonDefault
                                className="continue"
                                disabled={justicationText === undefined ? true : false}
                                onClick={createPendency}
                            >
                                Solicitar
                            </ButtonDefault>

                        </ModalButtons>
                    }
                </ModalWrapper>



            </ModalDefault>

            {/* Modal orientation */}
            <ModalDefault
                id={'overlayModal'}
                onClose={() => setModalOrientations((modalOrientations: any) => !modalOrientations)}
                openModal={modalOrientations}
                width="100%"
                height="100vh"
            >
                <ProfessionalOrientations 
                    orientacoes={demandInitial}
                    close={() => setModalOrientations(false)}
                    finish={() => { setModalOrientations(false); acceptOrientation() }}
                />

            </ModalDefault>

            <div onClick={() => toast.remove()}>
                <Toaster />
            </div>
        </Container>
    )
}