/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
// React
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// Librarys
import Select from 'react-select';
import toast, { Toaster } from 'react-hot-toast';
import * as yup from 'yup';
import getValidationErrors from "../../Utils/validationErrors";

//Services
import api from "../../services/api";
import axios from "axios";
import { useAuth } from "../../contexts/UserContext";

// Styles
import { Container, Footer, Header, Hr1, Main, ModalHeader, ModalMain, ModalPrecification } from "./styles";

// Data
import { EstadosBrasileiros } from "../../components/Data/StatesData";
import { brazilianBanks } from "../../components/Data/BrazilianBanks";

// Components
import Checkbox from "../../components/form/InputCheckbox";
import ButtonDefault from "../../components/form/ButtonDefault";
import ModalDefault from "../../components/ModalDefault";
import InputDefault from "../../components/form/InputDefault";
import RegisterPage5 from "../../components/RegisterPage5";
import { PixKeyData } from "../../components/Data/PixKeyData";
import InputMoney from "../../components/form/InputMoney";
import ModalTerms from "../../components/ModalTerms";

//Icons
import { 
    IconAccount, 
    IconAsteristik, 
    IconClose, 
    IconLogoJuridico, 
    IconPadlock, 
    IconSquarePlus, 
    IconProfile, 
    IconPlus, 
    IconTrash, 
    IconPlan, 
    IconEyeOpen, 
    IconClosedEye, 
} from "../../components/icons";
import {clarity} from "react-microsoft-clarity";

interface Errors {
    nome?: string,
    email?: string,
    senha?: string,
    confirmeSenha?: string,
    cpf?: string,
    cnpj?: string,
    celular?: string,
    whatsapp?: string,
    razao_social?: string,
    inscricao_oab?: string,
    tipo_oab?: string,
    estado_oab?: string,    
    tel_fixo?: string,
    cep?: string,
    uf?: string,
    cidade?: string,
    bairro?: string,
    endereco?: string,
    numero?: string,
    estado?: string,
    cidade_comarca?: string,
}

interface CountyErrors {
    uf?: string,
    cidade?: string,
    precificacao?: any,
}

interface AccountErrors {
    nome_titular?: string,
    tipo?: string,
    cnpj?: string,
    banco?: string,
    agencia?: string,
    conta?: string,
    digito?: string,
    tipo_chave_pix?: string,
    chave_pix?: string
}

export default function Register() {

    const history = useHistory(); 
    const [ activePage, setActivePage ] = useState<number>(1);
    const [ openModalConfirmation, setOpenModalConfirmation ] = useState<boolean>(false);
    const [ openModalDeleteComarca, setOpenModalDeleteComarca ] = useState<boolean>(false);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [openPaymentTerms, setOpenPaymentTerms] = useState<boolean>(false);
    const [ checked, setChecked ] = useState<boolean>(false);
    const [ cepState, setCepState ] = useState();
    const [ cepCity, setCepCity ] = useState();
    const [ cepCounty, setCepCounty ] = useState();
    const [ cepAddress, setCepAddress ] = useState();
    const [ advancePageError, setAdvancePageError ] = useState<Errors>({});
    const [ advancePrecificationError, setAdvancePrecificationError ] = useState<any>([
        {
            titulo: false,
            outros_servicos: {
                valor_diligencia: false,
                valor_protocolo: false
            },
            servicos: [
                {
                    key: 0,
                    valor_advogado: false,
                },
                {
                    key: 0,
                    valor_preposto: false,
                },
                {
                    key: 0,
                    valor_total: false,
                },
                {
                    key: 1,
                    valor_advogado: false,
                },
                {
                    key: 1,
                    valor_preposto: false,
                },
                {
                    key: 1,
                    valor_total: false,
                },
                {
                    key: 2,
                    valor_advogado: false,
                },
                {
                    key: 2,
                    valor_preposto: false,
                },
                {
                    key: 2,
                    valor_total: false,
                },
                {
                    key: 3,
                    valor_advogado: false,
                },
                {
                    key: 3,
                    valor_preposto: false,
                },
                {
                    key: 3,
                    valor_total: false,
                },
                {
                    key: 4,
                    valor_advogado: false,
                },
                {
                    key: 4,
                    valor_preposto: false,
                },
                {
                    key: 4,
                    valor_total: false,
                },
                {
                    key: 5,
                    valor_advogado: false,
                },
                {
                    key: 5,
                    valor_preposto: false,
                },
                {
                    key: 5,
                    valor_total: false,
                },
            ]
        }
    ]);
    const [ advanceCountyError, setAdvanceCountyError ] = useState<CountyErrors>({});
    const [ advanceAccountError, setAdvanceAccountError ] = useState<AccountErrors>({});
    const [ cities, setCities ] = useState<any>([]);
    const [ pricingCounty, setPricingCounty ] = useState<any>([]);
    const [ changeVisibility, setChangeVisibility ] = useState<boolean>(true);
    const [ validateUser, setValidateUser ] = useState();
    const [ excludePrecification, setExcludePrecification ] = useState<any>();
    const [ deleteComarca, setDeleteComarca ] = useState<any>();
    const [ precificationItem, setPrecificationItem ] = useState<any>();
    const [ comarcaItem, setComarcaItem ] = useState<any>();
    const [ selected, setSelected ] = useState<any>('cpf'); 

    useEffect(() => {

        if ( activePage === 3 ) {
            const newDTOComarcas:any = []
            DTOServices.map((service:any) => {
                DTOComarcas.map((comarca:any) => {
                    if (service.titulo === comarca.precificacao ) {
                        newDTOComarcas.push(comarca)
                    }
                })
            })
            setDTOComarcas([...newDTOComarcas])
        }

    }, [activePage])

    const oabType = {
        control: (provided: Record<string, unknown>) => ({
            ...provided,
            height: 48, 
            boxShadow: "none",
            cursor: 'pointer',
            border: advancePageError.tipo_oab ? '1px solid #FDB141' : '1px solid #EAEEF3',
            '&:hover': {
                border: '1px solid #04FDD0',
            }, 
        }),
        placeholder: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',  
            fontSize: '16px',  
            fontWeight: '400',        
        }),
        singleValue: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
        }),
        option: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#5A646E',
            fontSize: '16px',
            fontWeight: '500',
        }),
    };

    const oabState = {
        control: (provided: Record<string, unknown>) => ({
            ...provided,
            height: 48,
            boxShadow: "none",
            cursor: 'pointer',
            border: advancePageError.estado_oab ? '1px solid #FDB141' : '1px solid #EAEEF3',
            '&:hover': {
                border: '1px solid #04FDD0',
            },
        }), 
        placeholder: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
            fontWeight: '400',
        }),
        singleValue: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
        }),
        option: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#5A646E',
            fontSize: '16px',
            fontWeight: '500',
        }),
    }

    const oabPrice = {
        control: (provided: Record<string, unknown>) => ({
            ...provided,
            height: 48,
            boxShadow: "none",
            cursor: 'pointer',
            border: advanceCountyError.precificacao ? '1px solid #FDB141' : '1px solid #EAEEF3',
            '&:hover': {
                border: '1px solid #04FDD0',
            },
        }), 
        placeholder: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
            fontWeight: '400',
        }),
        singleValue: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
        }),
        option: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#5A646E',
            fontSize: '16px',
            fontWeight: '500',
        }),
    }

    const countyState = {
        control: (provided: Record<string, unknown>) => ({
            ...provided,
            height: 48,
            boxShadow: "none",
            cursor: 'pointer',
            border: advanceCountyError.uf ? '1px solid #FDB141' : '1px solid #EAEEF3',
            '&:hover': {
                border: '1px solid #04FDD0',
            },
        }), 
        placeholder: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
            fontWeight: '400',
        }),
        singleValue: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
        }),
        option: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#5A646E',
            fontSize: '16px',
            fontWeight: '500',
        }),
    }

    const countyCity = {
        control: (provided: Record<string, unknown>) => ({
            ...provided,
            height: 48,
            boxShadow: "none",
            cursor: 'pointer',
            border: advanceCountyError.cidade ? '1px solid #FDB141' : '1px solid #EAEEF3',
            '&:hover': {
                border: '1px solid #04FDD0',
            },
        }), 
        placeholder: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
            fontWeight: '400',
        }),
        singleValue: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
        }),
        option: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#5A646E',
            fontSize: '16px',
            fontWeight: '500',
        }),
    }

    const accountSelect = {
        control: (provided: Record<string, unknown>) => ({
            ...provided,
            height: 48,
            boxShadow: "none",
            cursor: 'pointer',
            border: advanceAccountError.tipo ? '1px solid #FDB141' : '1px solid #EAEEF3',
            '&:hover': {
                border: '1px solid #04FDD0',
            },
        }),
        placeholder: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
            fontWeight: '400',
        }),
        singleValue: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
        }),
        option: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#5A646E',
            fontSize: '16px',
            fontWeight: '500',
        }),
    }

    const bankSelect = {
        control: (provided: Record<string, unknown>) => ({
            ...provided,
            height: 48,
            boxShadow: "none",
            cursor: 'pointer',
            border: advanceAccountError.banco ? '1px solid #FDB141' : '1px solid #EAEEF3',
            '&:hover': {
                border: '1px solid #04FDD0',
            },
        }),
        placeholder: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
            fontWeight: '400',
        }),
        singleValue: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
        }),
        option: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#5A646E',
            fontSize: '16px',
            fontWeight: '500',
        }),
    }

    const pixKey = {
        control: (provided: Record<string, unknown>) => ({
            ...provided,
            height: 48,
            boxShadow: "none",
            cursor: 'pointer',
            border: advanceAccountError.tipo_chave_pix ? '1px solid #FDB141' : '1px solid #EAEEF3',
            '&:hover': {
                border: '1px solid #04FDD0',
            },
        }),
        placeholder: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
            fontWeight: '400',
        }),
        singleValue: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#353A40',
            fontSize: '16px',
        }),
        option: (provided: Record<string, unknown>) => ({
            ...provided,
            color: '#5A646E',
            fontSize: '16px',
            fontWeight: '500',
        }),
    }

    const inscription = [
        { value: 'principal', label: 'Inscrição principal' },
        { value: 'suplementar', label: 'Inscrição suplementar' },
        { value: 'transferência', label: 'Inscrição por transferência' },
        { value: 'estagiário', label: 'Estagiário' },
    ];  
    
    const accounts = [
        { value: 'pj', label: 'Conta PJ' },
        { value: 'corrente', label: 'Conta Corrente' },
        { value: 'poupança', label: 'Conta Poupança' },
        { value: 'pf', label: 'Conta Pessoa Física' },
    ];  
    
    const [DTOUser, setDTOUser] = useState({
        nome: '',
        email: '',
        senha: '',
        confirmeSenha: '',
        cpf: '',
        cnpj: '',
        celular: '',
        whatsapp: '',
        razao_social: '',
        inscricao_oab: '',
        tipo_oab: '',
        estado_oab: '',
        tel_recado: '',
        tel_fixo: '',
        cep: '',
        uf: cepState,
        cidade: cepCity,
        bairro: cepCounty,
        endereco: cepAddress,
        numero: '',
        complemento: '',
        termos: '',  
        id_plano: 1,    
    })

    const ServicesDefault = 
    {
        id: 1,
        titulo: '',
        servicos: [
            {
                id_servico: 2,
                valor_advogado: '',
                valor_preposto: '',
                valor_total: '',        
            },
            {
                id_servico: 18,
                valor_advogado: '',
                valor_preposto: '',
                valor_total: '',        
            },
            {
                id_servico: 1,
                valor_advogado: '',
                valor_preposto: '',
                valor_total: '',        
            },
            {
                id_servico: 17,
                valor_advogado: '',
                valor_preposto: '',
                valor_total: '',        
            },                
            {
                id_servico: 3,
                valor_advogado: '',
                valor_preposto: '',
                valor_total: '',        
            },                
            {
                id_servico: 19,
                valor_advogado: '',
                valor_preposto: '',
                valor_total: '',        
            },                
        ],
        outros_servicos: {
            valor_diligencia: '',
            valor_protocolo: ''
        }
    }
    
    const [ DTOServices, setDTOServices ] = useState<any>([ServicesDefault])

    const ComarcaDefault = {
        uf: '',
        cidade: '',
        principal: '',
        precificacao: '',
    }
    const [ DTOComarcas, setDTOComarcas ] = useState<any>([ComarcaDefault])

    const [ DTOAccount, setDTOAccount ] = useState({
        nome_titular: "",
        tipo: "",
        cnpj_cpf: "",
        banco: "",
        agencia: "",
        conta: "",
        digito: "",
        tipo_chave_pix: "",
        chave_pix: ""    
    })

    useEffect(() => {     

        handleRemovePrecification(precificationItem);

    }, [excludePrecification])

    useEffect(() => {     

        handleRemoveComarca(comarcaItem);

    }, [deleteComarca])

    useEffect(() => {
        console.log('log do DTO', DTOUser)
    }, [DTOUser])

    function handleOnChange(name: any, value: any) {
        
        if (activePage === 1) {
            
            if (name === 'cpf' || name === 'cnpj' || name === 'celular' || name === 'whatsapp' ) {
                let newValue = value.replace(/\D/g, '');

                const newDTO: any = DTOUser
                newDTO[name] = newValue
                setDTOUser({ ...newDTO })
            } else {

                const newDTO: any = DTOUser
                newDTO[name] = value
                setDTOUser({ ...newDTO })

            }           
            

        }

        if (activePage === 4) {
            
            const newDTO: any = DTOAccount
            newDTO[name] = value
            setDTOAccount({ ...newDTO })            
            
        }

        if (activePage === 5) {

            if (value === 1) {
                toast.success('Plano 123JUS Free selecionado.', {
                    style: {
                        padding: '16px',
                        color: '#FA9604',
                    },
                    iconTheme: {
                        primary: '#02E8BE',
                        secondary: '#FFFAEE',
                    },
                });
            }
            if (value === 2) {
                toast.success('Plano 123JUS Pro selecionado.', {
                    style: {
                        padding: '16px',
                        color: '#FA9604',
                    },
                    iconTheme: {
                        primary: '#02E8BE',
                        secondary: '#FFFAEE',
                    },
                });
            }
            if (value === 3) {
                toast.success('Plano 123JUS Premium selecionado.', {
                    style: {
                        padding: '16px',
                        color: '#FA9604',
                    },
                    iconTheme: {
                        primary: '#02E8BE',
                        secondary: '#FFFAEE',
                    },
                });
            }

            const newDTO: any = DTOUser
            newDTO[name] = value
            setDTOUser({ ...newDTO })       

        }

        if (activePage === 6) {
            
            const newDTO: any = DTOUser
            newDTO[name] = value
            setDTOUser({ ...newDTO })

        }
        
        
    }

    function handleOnChangeComarcas(key:number, name:any, value:string){        

        if ( name === 'principal' ){
            DTOComarcas.map((row:any, key:number) => {
                row.principal = false
            })
        }

        const newDTO:any = DTOComarcas
        newDTO[key][name] = value
        setDTOComarcas([...newDTO])

        if ( name === 'uf' ) getCitiesForUF(DTOComarcas)
    }

    function getCitiesForUF(DTOComarcas:any)
    {
        const newCities:any = []
        DTOComarcas.map((row:any, key:number) => {
            const cityName: any = {
                uf: row.uf,
                docs: []
            }
            axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${row.uf}/municipios`).then(response => {
                response.data.map((row: any) => {
                    cityName.docs.push({
                        value: row.nome,
                        label: row.nome
                    })
                })
                newCities.push(cityName)
                setCities([...newCities])
            });
        })
    }

    function handleOnChangeServices(id: any, name: any, value: any, keyObj: any) {
        
        let newValue = value.replace(/\D/g, '');

        if (newValue.charAt(0) === '0') {
            newValue = newValue.substring(1)               
        }

        if (newValue.length <= 3) {
            // newValue = newValue + '.00'
            const newDto = DTOServices
            if (id > 0) {
                const filterDto = newDto.map((row: any) => row.servicos)[keyObj].filter((obj: any) => obj.id_servico === id);
                filterDto[0][name] = newValue
            }

            newDto.map((row: any, key: any) => {
                if (key === keyObj && name === 'titulo') {
                    row.titulo = value
                } else if (key === keyObj && name === 'protocolo') {
                    row.outros_servicos.valor_protocolo = newValue
                } else if (key === keyObj && name === 'diligencia') {
                    row.outros_servicos.valor_diligencia = newValue
                }
                return null
            })
           
        } else {
            let txtArray = newValue.split('')
            txtArray.splice(txtArray.length - 2, 0, ".");
            var correctedValue = txtArray.join('')
            
            const newDto = DTOServices
            
            if(id > 0) {
                const filterDto = newDto.map((row: any) => row.servicos)[keyObj].filter((obj: any) => obj.id_servico === id);
                filterDto[0][name] = correctedValue
            }

            newDto.map((row: any, key: any) => {
                if (key === keyObj && name === 'titulo') {
                    row.titulo = value
                } else if (key === keyObj && name === 'protocolo') {
                    row.outros_servicos.valor_protocolo = correctedValue
                } else if (key === keyObj && name === 'diligencia') {
                    row.outros_servicos.valor_diligencia = correctedValue
                }
                return null
            })
            
        }        
        
    }

    function handleSelect(selected: any) {

        if(activePage === 1) {
            if (selected.value.length > 2) {
                handleOnChange('tipo_oab', selected.value)
            } else {
                handleOnChange('estado_oab', selected.value)
            }
        }

        if (activePage === 3) {
                        
            handleOnChange('cidade', selected.value)
            
        }

        if (activePage === 4) {
            if (selected.value === 'corrente' || selected.value === 'pj' || selected.value === 'poupança' || selected.value === 'pf') {
                handleOnChange('tipo', selected.value)
            } else if (selected.value === 'celular' || selected.value === 'chave aleatória' || selected.value === 'email' || selected.value === 'CPF' || selected.value === 'CPNJ') {
                handleOnChange('tipo_chave_pix', selected.value)
            } else {
                handleOnChange('banco', selected.label)
            }
        }

    }

    const { signIn } = useAuth();

    async function handleSubmit() {
        
        try {
            
            const user:any = {
                usuario: DTOUser,
                comarcas: DTOComarcas,
                conta: DTOAccount,
                precificacoes: DTOServices
            }

            clarity.setEvent('cria-profissional')

            const response = await api.post('usuario', user)

            if (response.data.status === 'error') {
                throw response.data.result.map((row:any) => {
                    throw row.error
                })
            }

            // await signIn({
            //     email: user.usuario.email,
            //     senha: user.usuario.senha
            // })

            history.push({
                pathname: '/cadastro-completo',
                state: {
                    userName: DTOUser.nome
                }
            })

        } catch (error) {

            console.log('log do erro submit', error)
            
        }

        
    }

    function scrollTop() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }

    async function advancePage() {

        try {

            if (activePage === 1) {
                if (cepState !== '' ) {
                    handleOnChange('uf', cepState);
                }
                if (cepCity !== '') {
                    handleOnChange('cidade', cepCity);
                }
                if (cepAddress !== '') {
                    handleOnChange('endereco', cepAddress);                     
                }
                if (cepCounty !== '') {
                    handleOnChange('bairro', cepCounty);
                }


                const schema = yup.object().shape({
                    nome: yup.string().required('Nome é obrigatório'),
                    cpf: yup.string().required('CPF é obrigatório').min(11).max(14),
                    razao_social: yup.string(),
                    inscricao_oab: yup.string().required('Inscrição OAB é obrigatório'),
                    estado_oab: yup.string().required('Estado em que a OAB é registrado é obrigatório'),
                    tipo_oab: yup.string().required('Tipo de inscrição é obrigatório'),
                    celular: yup.string().required('Celular é obrigatório').min(11).max(11),
                    email: yup.string().required('Email é obrigatório').email("Digite um e-mail válido"),
                    whatsapp: yup.string().required('Whatsapp é obrigatório').min(11).max(11),
                    cep: yup.string().required('CEP é obrigatório'),
                    uf: yup.string().required('Estado é obrigatório'),
                    cidade: yup.string().required('Cidade é obrigatório'),
                    bairro: yup.string().required('Bairro é obrigatório'),
                    endereco: yup.string().required('Endereço é obrigatório'),
                    numero: yup.number().required('Numero é obrigatório').integer(),
                });
                await schema.validate(DTOUser, {
                    abortEarly: false,
                });                

                if (checked === false) {

                    throw new Error("É necessário ler e concordar com os termos de uso e condições.")

                } else {
                    handleOnChange('termos', 'sim')
                }

                if (DTOUser.cnpj.length < 14) {
                    handleOnChange('cnpj', '')
                }

                if (DTOUser.tel_recado.length < 13) {
                    handleOnChange('tel_recado', '')
                }

                if (DTOUser.tel_fixo.length < 13) {
                    handleOnChange('tel_fixo', '')
                }
                
                setChecked(false);
            }

            if (activePage === 2) {
                const DTOErrors:any = []

                DTOServices.map((row:any, key:number) => {
                    DTOErrors[key] = {
                        servicos: [],
                        outros_servicos: {}
                    }
                    if ( !row.titulo ) DTOErrors[key].titulo = true
                    if ( !row.outros_servicos.valor_diligencia ) DTOErrors[key].outros_servicos.valor_diligencia = true
                    if ( !row.outros_servicos.valor_protocolo ) DTOErrors[key].outros_servicos.valor_protocolo = true
                    row.servicos.map((servico:any, servicoKey:any) => {
                        if ( servico.valor_advogado <= 0) DTOErrors[key].servicos.push({
                            valor_advogado: true,
                            key: servicoKey                            
                        })
                        if ( servico.valor_preposto <= 0 ) DTOErrors[key].servicos.push({
                            valor_preposto: true,
                            key: servicoKey
                        })
                        if ( servico.valor_total <= 0 ) DTOErrors[key].servicos.push({
                            valor_total: true,
                            key: servicoKey
                        })
                        
                    })
                })

                setAdvancePrecificationError(DTOErrors)  
                DTOErrors.map((row:any) => {
                    if ( 
                        row.titulo === true || 
                        row.servicos.length > 0 || 
                        row.outros_servicos.valor_diligencia === true || 
                        row.outros_servicos.valor_protocolo === true 
                    ) 
                    throw new Error ("Para avançar é necessário preencher com valores válidos todos os campos obrigatórios.")
                })

                const pricing: { value: any; label: any; }[] = []

                DTOServices.map((row: any, key: any) => {
                    pricing.push({
                        value: row.titulo,
                        label: row.titulo
                    })
                })

                const DataPrecification = {
                    precificacoes: DTOServices
                }

                const response = await api.post('precificacao-validar', DataPrecification)
                
                if (response.data.status !== "success") {
                    throw new Error (response.data.message)
                } 
                
                setPricingCounty(pricing)

                if (DTOServices.length < 1) {
                    throw new Error ('É necessário adicionar pelo menos uma precificação')
                }                    
            }

            if (activePage === 3) {
                
                if (DTOComarcas.length < 1) {
                    throw new Error ('É necessário adicionar pelo menos uma Comarca')
                }

                const ComarcaSchema = yup.object().shape({
                    uf: yup.string().required('Escolha um estado'),
                    cidade: yup.string().required('Escolha uma cidade'),
                    precificacao: yup.string().required('Escolha uma precificação'),
                });

                await ComarcaSchema.validate(DTOComarcas[0], {
                    abortEarly: false,
                });

                const Data = {
                    comarcas: DTOComarcas
                }

                const response = await api.post('comarca-validar', Data)

                if (response.data.status !== "success") {
                    throw response.data.message
                }                
                setActivePage(activePage + 1);

                
            }

            if (activePage === 4) {
                
                const AccountSchema = yup.object().shape({
                    nome_titular: yup.string().required('Nome do titular obrigatório'),
                    tipo: yup.string().required('Tipo de conta obrigatório'),
                    banco: yup.string().required('Banco obrigatório'),
                    agencia: yup.string().required('Agência obrigatória'),
                    conta: yup.string().required('Conta obrigatória'),
                    digito: yup.string().required('Digito obrigatório'),
                    tipo_chave_pix: yup.string().required('Tipo de chave pix obrigatório'),
                    chave_pix: yup.string().required('Chave pix obrigatória'),
                });

                await AccountSchema.validate(DTOAccount, {
                    abortEarly: false,
                });
                
                const response = await api.post('conta-validar', DTOAccount)

                if (response.data.status !== "success") {
                    throw response.data.message
                }   
                
                if (selected === 'cpf' && DTOAccount.cnpj_cpf.length < 11) {
                    handleOnChange('cnpj_cpf', '')
                }
                
                setActivePage(activePage + 1);
                

            }

            if (activePage === 5) {

                if (!checked) {
                    throw new Error('É necessário ler e concordar com os termos de pagamento e condições.')
                }

                if (DTOUser.id_plano < 1) {
                    throw new  Error('Escolha um plano antes de continuar')
                }
            }

            if (activePage === 6) {
                const schemaSix = yup.object().shape({
                    senha: yup.string()
                            .required('Senha é obrigatória')
                            .min(6)
                            .matches(
                                /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/,
                                "Crie uma senha forte. Conforme texto acima"
                            ),
                    confirmeSenha:
                        yup.string()
                            .required('Confirmação de senha é obrigatória')
                            .oneOf([yup.ref('senha')], 'As senhas não conferem!'), 
                });                
                
                await schemaSix.validate(DTOUser, {
                    abortEarly: false,
                });

                const response = await api.post('usuario-validar', DTOUser)
                if (response.data.status === 'error') {
                    throw response.data.result.map((row:any) => {
                        throw row.error
                    })
                }
                
                setValidateUser(response.data.status)
                handleSubmit();
            }

            //se passar de todas as validações ele faz isso.
            scrollTop();
            setActivePage(activePage + 1);
            setAdvancePageError({})
            setAdvanceAccountError({})
            setAdvanceCountyError({})
            setAdvancePrecificationError({})


        } catch (err: any) {

            if (err instanceof yup.ValidationError) {
                const errors = getValidationErrors(err);

                setAdvancePageError(errors)
                
                if(activePage === 3) {                    
                    setAdvanceCountyError(errors)                    
                }

                if(activePage === 4) {
                    setAdvanceAccountError(errors)                    
                }

                toast.error('Preencha os campos corretamente.', {
                    duration: 3000,
                    className: 'toasted',
                    style: {
                        padding: '16px',
                        color: '#1A2858',
                    },
                    iconTheme: {
                        primary: '#F24343',
                        secondary: '#FFFAEE',
                    },
                });
                
            } else {
                if(activePage === 2) {
                    toast.error(err.message, {
                        duration: 2500,
                        className: 'toasted',
                        style: {
                            padding: '16px',
                            color: '#1A2858',
                        },
                        iconTheme: {
                            primary: '#F24343',
                            secondary: '#FFFAEE',
                        },
                    });
                } else if(activePage === 3) {
                    toast.error(err.message, {
                        duration: 2500,
                        className: 'toasted',
                        style: {
                            padding: '16px',
                            color: '#1A2858',
                        },
                        iconTheme: {
                            primary: '#F24343',
                            secondary: '#FFFAEE',
                        },
                    });
                } else if(activePage === 5) {
                    toast.error(err.message, {
                        duration: 2500,
                        className: 'toasted',
                        style: {
                            padding: '16px',
                            color: '#1A2858',
                        },
                        iconTheme: {
                            primary: '#F24343',
                            secondary: '#FFFAEE',
                        },
                    });
                } else {
                    toast.error(err.toString(), {
                        duration: 2500,
                        className: 'toasted',
                        style: {
                            padding: '16px',
                            color: '#1A2858',
                        },
                        iconTheme: {
                            primary: '#F24343',
                            secondary: '#FFFAEE',
                        },
                    });
                }
                
            }

        }
    }

    async function consultCep(name: any, value: any) {
        
        try {
            
            if (value.replace(/[^\d]/g, '').length > 7) {

                handleOnChange('cep', value);
                const response = await axios.get(`https://viacep.com.br/ws/${value}/json/`)
                const data = response.data
                setCepState(data.uf);
                setCepCity(data.localidade);
                setCepCounty(data.bairro);
                setCepAddress(data.logradouro);            
                
            }


        } catch (err: any) {

            console.log('err, cep', err)
            
        }

    }  
    
    function handleAddPrecification()
    {
        DTOServices.push(ServicesDefault)
        setDTOServices([...DTOServices])
    }

    function confirmDeletePrecification(item: any) {

        setOpenModalConfirmation(true)
        setPrecificationItem(item)       

    }

    function handleRemovePrecification(indice:number)
    {   
        if (excludePrecification === true) {
            
            const newDTO:any = []
            DTOServices.map((row:any, key:number) => {
                if ( key !== indice  ) newDTO.push(row)
            })
            setDTOServices([...newDTO])
            setOpenModalConfirmation(false)
            setExcludePrecification(false)
        }

    }

    function handleAddComarca()
    {
        DTOComarcas.push(ComarcaDefault)
        setDTOComarcas([...DTOComarcas])
    }

    function confirmDeleteComarca(item: any) {

        setOpenModalDeleteComarca(true)
        setComarcaItem(item)

    }

    function handleRemoveComarca(indice:number)
    {
        const newDTO:any = []
        DTOComarcas.map((row:any, key:number) => {
            if ( key !== indice  ) newDTO.push(row)
        })
        setDTOComarcas([...newDTO])
        setOpenModalDeleteComarca(false);
        setDeleteComarca(false);
    }

    return (
        <Container>                          

            <Header>
                <div className="logo"><IconLogoJuridico /></div>

                <div className="status">
                    <Hr1 
                        className={
                            activePage === 1 ? "first" : '' ||
                            activePage === 2 ? "second" : '' ||
                            activePage === 3 ? "third" : '' ||
                            activePage === 4 ? "fourth" : '' ||
                            activePage === 5 ? "fifth" : '' ||
                            activePage === 6 ? "sixth" : '' 
                        } 
                    />
                    <div 
                        className="status-square first"                        
                    >
                        <div className={"square active"}>
                            <IconProfile />
                        </div>
                        <p className="first">Seus dados</p>
                    </div>
                    <div 
                        className="status-square"
                    >
                        <div className={activePage > 1 ? "square active" : 'square'}>
                            <IconSquarePlus />
                        </div>
                        <p className={activePage > 1 ? 'on' : ''} >Serviços</p>
                    </div>
                    <div 
                        className="status-square"
                    >
                        <div className={activePage > 3 ? "square active" : 'square'}>
                            <IconAccount />
                        </div>
                        <p className={activePage > 3 ? 'on' : ''} >Conta</p>
                    </div>
                    <div 
                        className="status-square"
                    >
                        <div className={activePage > 4 ? "square active" : 'square'}>
                            <IconPlan />
                        </div>
                        <p className={activePage > 4 ? 'on' : ''} >Plano</p>
                    </div>                    
                    <div 
                        className="status-square"
                    >
                        <div className={activePage > 5 ? "square active" : 'square'}>
                            <IconPadlock />
                        </div>
                        <p className={activePage > 5 ? 'on' : ''} >Acesso</p>
                    </div>                    
                </div>
            </Header>

            <Main onClick={() => toast.remove()}>
                <div className="top">
                    {
                        activePage === 1 &&
                        <div className="one" >
                            <div className="register underline">
                                <h3>Cadastre-se como <span>profissional jurídico!</span></h3>
                                <p>Informe abaixo seus dados e confira nossos <span
                                    onClick={() => setOpenModal(true)}
                                >
                                    termos de uso e condições
                                </span>.
                                </p>
                            </div>                        
                        </div>
                    }

                    {
                        activePage === 2 &&
                        <div className="two">
                            <div className="register services">
                                <h3>Precifique seus serviços!</h3>
                                    <p className="title">Entendemos que seu valor pode variar de acordo com a comarca atendida!</p>
                                    <p>
                                        Por este motivo, você pode <span>atribuir uma precificação diferente para cada região!</span> Ex: preços para capital, região metropolitana e interior.
                                    </p>
                            </div>
                        </div>
                    }

                    {
                        activePage === 3 &&
                        <div className="three">
                            <div className="register">
                                <h3>Adicione comarcas!</h3>
                                <div className="subtitle">
                                    <span>Adicione as comarcas</span>  que deseja atender, <span>atribua a precificação</span> mais adequada a cada uma e <span>indique a sua principal comarca</span> de atuação.
                                </div>
                            </div>
                        </div>
                    }

                    {
                        activePage === 4 &&
                        <div className="four">
                            <div className="register">
                                <h3>Adicione uma conta!</h3>
                                <p>Nos informe abaixo <span>sua conta</span> e <span>chave PIX</span> para receber valores referentes aos serviços prestados:
                                </p>
                            </div>
                        </div>
                    }

                    {
                        activePage === 5 &&
                        <div className="five">
                            <div className="register">
                                <h3>Escolha o melhor plano para você!</h3>

                                <div>
                                    <p>Entraremos em contato pare acertar os detalhes de pagamento.</p> 
                                    <p className="terms">Confira nossos <span
                                        onClick={() => setOpenPaymentTerms(true)}
                                    >termos de pagamento.</span></p>
                                    <p>Em caso de dúvidas, entre em contato: (31) 3565-7918.</p>
                                </div>  
                            </div>
                        </div>
                    }

                    {
                        activePage === 6 &&
                        <div className="six">
                            <div className="register">
                                <h3>Defina uma senha de acesso!</h3>

                                <div>
                                    <p>Escolha um email para efetuar o login:</p> 
                                </div>  
                            </div>
                        </div>
                    }

                    {
                        Object.keys(advancePageError).length > 0 ||
                        Object.keys(advanceAccountError).length > 0 ||
                        Object.keys(advanceCountyError).length > 0 
                        ?
                        <div>
                            <div className="attention">
                                <div className="star"><IconAsteristik /></div>
                                <span>Atenção:</span>
                                <p>Existem campos obrigatórios a serem preenchidos</p>
                            </div>
                        </div>
                        : ''
                    }
                </div>
                

                {   //   pagina 1 - cadastro      
                    activePage === 1 &&
                    <form className="register-form" >

                        <div className="name">
                            <InputDefault
                                title="Seu nome:"
                                name='nome'
                                value={DTOUser.nome}
                                onChangeText={(value) => handleOnChange('nome', value)}
                                className={advancePageError.nome ? 'big error' : 'big'}
                                error={advancePageError.nome ? '*' : ''}
                            />

                            <InputDefault
                                title="CPF:"
                                name="cpf"
                                mask="999.999.999-99"
                                value={DTOUser.cpf}
                                onChangeText={(value) => handleOnChange('cpf', value)}
                                className={advancePageError.cpf ? 'small error' : 'small'}
                                error={advancePageError.cpf ? '*' : ''}
                            />

                            <InputDefault
                                title="Razão Social:"
                                name="razao_social"
                                value={DTOUser.razao_social}
                                onChangeText={(value) => handleOnChange('razao_social', value)}
                                className='big'                                
                            />

                            <InputDefault
                                title="CNPJ:"
                                name="cnpj"
                                mask="99.999.999/9999-99"
                                value={DTOUser.cnpj}
                                onChangeText={(value) => handleOnChange('cnpj', value)}
                                className='small'                               
                            />

                            <InputDefault
                                title="Inscrição na OAB:"
                                name="inscricao_oab"
                                value={DTOUser.inscricao_oab}
                                onChangeText={(value) => handleOnChange('inscricao_oab', value)}
                                className={advancePageError.inscricao_oab ? 'oab error' : 'oab'}
                                error={advancePageError.inscricao_oab ? '*' : ''}
                            />

                            <div className="label-oab">
                                <p>Tipo da inscrição:</p>
                                <Select
                                    name="tipo_oab"
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    styles={oabType}
                                    placeholder='Selecionar'                                    
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 8,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#DDFAF4',
                                            primary50: '#DDFAF4',
                                            primary: '#02E8BE',
                                        },
                                    })}
                                    defaultValue={DTOUser.tipo_oab !== '' ? { value: DTOUser.tipo_oab, label: DTOUser.tipo_oab } : ''}
                                    onChange={handleSelect}
                                    options={inscription}
                                />
                                <span className="error">{advancePageError.tipo_oab ? '*' : ''}</span>
                            </div>

                            <div className="label-state">
                                <p>Estado da OAB:</p>
                                <Select
                                    name="estado_oab"
                                    styles={oabState}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 8,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#DDFAF4',
                                            primary50: '#DDFAF4',
                                            primary: '#02E8BE',
                                        },
                                    })}
                                    placeholder='Selecionar'
                                    defaultValue={DTOUser.estado_oab !== '' ? { value: DTOUser.estado_oab, label: DTOUser.estado_oab } : ''}
                                    onChange={handleSelect}
                                    options={EstadosBrasileiros}
                                />
                                <span className="error">{advancePageError.estado_oab ? '*' : ''}</span>
                            </div>
                        </div>

                        <div className="fone">
                            <InputDefault
                                title="Celular com DDD:"
                                name="celular"
                                mask="(99) 99999-9999"
                                value={DTOUser.celular}
                                onChangeText={(value) => handleOnChange('celular', value)}
                                className={advancePageError.celular ? 'small error' : 'small'}
                                error={advancePageError.celular ? '*' : ''}
                            />

                            <InputDefault
                                title="Telefone para recado:"
                                name="tel_recado"
                                mask="(99) 9999-9999"
                                value={DTOUser.tel_recado}
                                onChangeText={(value) => handleOnChange('tel_recado', value)}
                                className='small'
                            />

                            <InputDefault
                                title="Telefone fixo:"
                                name="tel_fixo"
                                mask="(99) 9999-9999"
                                value={DTOUser.tel_fixo}
                                onChangeText={(value) => handleOnChange('tel_fixo', value)}
                                className='small'
                            />

                            <InputDefault
                                title="E-mail:"
                                name="email"
                                value={DTOUser.email}
                                onChangeText={(value) => handleOnChange('email', value)}
                                className={advancePageError.email ? 'big error' : 'big'}
                                error={advancePageError.email ? '*' : ''}
                            />

                            <InputDefault
                                title="Whatsapp:"
                                name="whatsapp"
                                mask="(99) 99999-9999"
                                value={DTOUser.whatsapp}
                                onChangeText={(value) => handleOnChange('whatsapp', value)}
                                className={advancePageError.whatsapp ? 'small error' : 'small'}
                                error={advancePageError.whatsapp ? '*' : ''}
                            />
                        </div>

                        <div className="address">
                            <InputDefault
                                title="CEP:"
                                name="cep"
                                mask="99999-999"
                                value={DTOUser.cep}
                                onChangeText={(value) => consultCep('cep', value)}                                
                                className={advancePageError.cep ? 'small error' : 'small'}
                                error={advancePageError.cep ? '*' : ''}
                            />

                            <InputDefault
                                title="Estado:"
                                name="uf"
                                value={cepState || DTOUser.uf}
                                // disabled={cepState ? true : false}
                                onChangeText={(value) => handleOnChange('uf', cepState || value)}
                                className={advancePageError.uf ? 'small error' : 'small'}
                                error={advancePageError.uf ? '*' : ''}
                            />


                            <InputDefault
                                title="Cidade:"
                                name="cidade"
                                value={cepCity || DTOUser.cidade}
                                // disabled={cepCity ? true : false}
                                onChangeText={(value) => handleOnChange('cidade', cepCity || value)}
                                className={advancePageError.cidade ? 'small error' : 'small'}
                                error={advancePageError.cidade ? '*' : ''}
                            />

                            <InputDefault
                                title="Bairro:"
                                name="bairro"
                                value={cepCounty || DTOUser.bairro}
                                // disabled={cepCounty ? true : false}
                                onChangeText={(value) => handleOnChange('bairro', cepCounty || value)}
                                className={advancePageError.bairro ? 'small error' : 'small'}
                                error={advancePageError.bairro ? '*' : ''}
                            />

                            <InputDefault
                                title="Endereço:"
                                name="endereco"
                                value={cepAddress || DTOUser.endereco}
                                // disabled={cepAddress ? true : false}
                                onChangeText={(value) => handleOnChange('endereco', cepAddress || value)}
                                className={advancePageError.endereco ? 'big error' : 'big'}
                                error={advancePageError.endereco ? '*' : ''}
                            />

                            <InputDefault
                                title="Número:"
                                name="numero"
                                value={DTOUser.numero}
                                onChangeText={(value) => handleOnChange('numero', value)}
                                className={advancePageError.numero ? 'number error' : 'number'}
                                error={advancePageError.numero ? '*' : ''}
                            />

                            <InputDefault
                                title="Complemento:"
                                name="complemento"
                                value={DTOUser.complemento}
                                className="comp"
                                onChangeText={(value) => handleOnChange('complemento', value)}
                            />
                        </div>

                        <div className="check-flex">
                            <Checkbox
                                label="Concordo com os"
                                name="terms"
                                value={checked}
                                onClick={() => setChecked(!checked)}
                            />
                            <div
                                className="terms-check"
                                onClick={() => setOpenModal(true)}
                            >
                                termos de uso e condições
                            </div>
                        </div>

                    </form >
                    
                }

                {   //   pagina 2 - Precificação
                    activePage === 2 &&
                    <form className='services-form' id="services-form">

                        { DTOServices.map((service:any, serviceKey:number) =>
                            <div className={`services ${serviceKey > 0 ? 'line' : 'none'}`}>
                                <div className="services-top">
                                    <InputDefault
                                        title="Título da precificação:"
                                        name="titulo"
                                        placeholder="Ex: Capital ou Interior"
                                        value={DTOServices[serviceKey].titulo}
                                        onChangeText={(value) => handleOnChangeServices(0, 'titulo', value, serviceKey)}
                                        className={advancePrecificationError[serviceKey]?.titulo ? 'big error' : 'big'}
                                        error={advancePrecificationError[serviceKey]?.titulo ? '*' : ''}
                                    />

                                    <button 
                                        type="button"
                                        onClick={() => confirmDeletePrecification(serviceKey)}
                                    ><IconTrash /> Excluir precificação</button>
                                </div>

                                <div className="services-hearing">
                                    <p className="hearing-title">AUDIÊNCIA TRABALHISTA PRESENCIAL</p>
                                    
                                    <div className="hearing-fields">
                                        <InputMoney 
                                            title="Somente Advogado:"
                                            name="presencial1"
                                            placeholder="R$ 00,00"
                                            value={DTOServices[serviceKey].servicos[0].valor_advogado}
                                            onChangeText={(value) => handleOnChangeServices(2, 'valor_advogado', value, serviceKey)}
                                            mask="R$ 999.999,99"
                                            className={advancePrecificationError[serviceKey]?.servicos.filter((obj: any) => obj.key === 0 && obj.valor_advogado === true).length > 0 ? 'mid error' : 'mid'}
                                            error={advancePrecificationError[serviceKey]?.servicos.filter((obj: any) => obj.key === 0 && obj.valor_advogado === true).length > 0 ? '*' : ''}
                                        />

                                        <InputMoney
                                            title="Somente Preposto:"
                                            name="preposto1"
                                            placeholder="R$ 00,00"
                                            mask="R$ 999.999,99"
                                            value={DTOServices[serviceKey].servicos[0].valor_preposto}
                                            onChangeText={(value) => handleOnChangeServices(2, 'valor_preposto', value, serviceKey)}                                            
                                            className={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 0 && obj.valor_preposto === true).length > 0 ? 'mid error' : 'mid'}
                                            error={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 0 && obj.valor_preposto === true).length > 0 ? '*' : ''}
                                        />
                                        
                                        <InputMoney
                                            title="Advogado + Preposto:"
                                            name="advprep1"
                                            placeholder="R$ 00,00"
                                            mask="R$ 999.999,99"
                                            value={DTOServices[serviceKey].servicos[0].valor_total}
                                            onChangeText={(value) => handleOnChangeServices(2, 'valor_total', value, serviceKey)}                                            
                                            className={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 0 && obj.valor_total === true).length > 0 ? 'mid error' : 'mid'}
                                            error={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 0 && obj.valor_total === true).length > 0 ? '*' : ''}
                                        />
                                    </div>

                                </div>

                                <div className="services-hearing">
                                    <p className="hearing-title">AUDIÊNCIA TRABALHISTA VIRTUAL</p>

                                    <div className="hearing-fields">
                                        <InputMoney
                                            title="Somente Advogado:"
                                            name="virtual1"
                                            placeholder="R$ 00,00"
                                            mask="R$ 999.999,99"
                                            value={DTOServices[serviceKey].servicos[1].valor_advogado}
                                            onChangeText={(value) => handleOnChangeServices(18, 'valor_advogado', value, serviceKey)}                                            
                                            className={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 1 && obj.valor_advogado === true).length > 0 ? 'mid error' : 'mid'}
                                            error={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 1 && obj.valor_advogado === true).length > 0 ? '*' : ''}
                                        />
                                        <InputMoney
                                            title="Somente Preposto:"
                                            name="preposto2"
                                            placeholder="R$ 00,00"
                                            mask="R$ 999.999,99"
                                            value={DTOServices[serviceKey].servicos[1].valor_preposto}
                                            onChangeText={(value) => handleOnChangeServices(18, 'valor_preposto', value, serviceKey)}                                            
                                            className={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 1 && obj.valor_preposto === true).length > 0 ? 'mid error' : 'mid'}
                                            error={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 1 && obj.valor_preposto === true).length > 0 ? '*' : ''}
                                        />
                                        <InputMoney
                                            title="Advogado + Preposto:"
                                            placeholder="R$ 00,00"
                                            mask="R$ 999.999,99"
                                            name="advprep2"
                                            value={DTOServices[serviceKey].servicos[1].valor_total}
                                            onChangeText={(value) => handleOnChangeServices(18, 'valor_total', value, serviceKey)}                                            
                                            className={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 1 && obj.valor_total === true).length > 0 ? 'mid error' : 'mid'}
                                            error={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 1 && obj.valor_total === true).length > 0 ? '*' : ''}
                                        />
                                    </div>

                                </div>

                                <div className="services-hearing">
                                    <p className="hearing-title">AUDIÊNCIA CÍVEL PRESENCIAL</p>
                                    
                                    <div className="hearing-fields">
                                        <InputMoney
                                            title="Somente Advogado:"
                                            name="presencial2"
                                            placeholder="R$ 00,00"
                                            mask="R$ 999.999,99"
                                            value={DTOServices[serviceKey].servicos[2].valor_advogado}
                                            onChangeText={(value) => handleOnChangeServices(1, 'valor_advogado', value, serviceKey)}                                            
                                            className={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 2 && obj.valor_advogado === true).length > 0 ? 'mid error' : 'mid'}
                                            error={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 2 && obj.valor_advogado === true).length > 0 ? '*' : ''}
                                        />
                                        <InputMoney
                                            title="Somente Preposto:"
                                            placeholder="R$ 00,00"
                                            mask="R$ 999.999,99"
                                            name="preposto3"
                                            value={DTOServices[serviceKey].servicos[2].valor_preposto}
                                            onChangeText={(value) => handleOnChangeServices(1, 'valor_preposto', value, serviceKey)}                                            
                                            className={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 2 && obj.valor_preposto === true).length > 0 ? 'mid error' : 'mid'}
                                            error={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 2 && obj.valor_preposto === true).length > 0 ? '*' : ''}
                                        />
                                        <InputMoney
                                            title="Advogado + Preposto:"
                                            placeholder="R$ 00,00"
                                            mask="R$ 999.999,99"
                                            name="advprep3"
                                            value={DTOServices[serviceKey].servicos[2].valor_total}
                                            onChangeText={(value) => handleOnChangeServices(1, 'valor_total', value, serviceKey) }                                            
                                            className={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 2 && obj.valor_total === true).length > 0 ? 'mid error' : 'mid'}
                                            error={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 2 && obj.valor_total === true).length > 0 ? '*' : ''}
                                        />
                                    </div>
                                </div>

                                <div className="services-hearing">
                                    <p className="hearing-title">AUDIÊNCIA CÍVEL VIRTUAL</p>

                                    <div className="hearing-fields">
                                        <InputMoney
                                            title="Somente Advogado:"
                                            name="virtual2"
                                            placeholder="R$ 00,00"
                                            mask="R$ 999.999,99"
                                            value={DTOServices[serviceKey].servicos[3].valor_advogado}
                                            onChangeText={(value) => handleOnChangeServices(17, 'valor_advogado', value, serviceKey)}                                            
                                            className={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 3 && obj.valor_advogado === true).length > 0 ? 'mid error' : 'mid'}
                                            error={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 3 && obj.valor_advogado === true).length > 0 ? '*' : ''}
                                        />
                                        <InputMoney
                                            title="Somente Preposto:"
                                            placeholder="R$ 00,00"
                                            mask="R$ 999.999,99"
                                            name="preposto4"
                                            value={DTOServices[serviceKey].servicos[3].valor_preposto}
                                            onChangeText={(value) => handleOnChangeServices(17, 'valor_preposto', value, serviceKey)}                                            
                                            className={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 3 && obj.valor_preposto === true).length > 0 ? 'mid error' : 'mid'}
                                            error={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 3 && obj.valor_preposto === true).length > 0 ? '*' : ''}
                                        />
                                        <InputMoney
                                            title="Advogado + Preposto:"
                                            placeholder="R$ 00,00"
                                            mask="R$ 999.999,99"
                                            name="advprep4"
                                            value={DTOServices[serviceKey].servicos[3].valor_total}
                                            onChangeText={(value) => handleOnChangeServices(17, 'valor_total', value, serviceKey)}                                            
                                            className={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 3 && obj.valor_total === true).length > 0 ? 'mid error' : 'mid'}
                                            error={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 2 && obj.valor_total === true).length > 0 ? '*' : ''}
                                        />
                                    </div>
                                </div>

                                <div className="services-hearing">
                                    <p className="hearing-title">AUDIÊNCIA PENAL PRESENCIAL</p>

                                    <div className="hearing-fields">
                                        <InputMoney
                                            title="Somente Advogado:"
                                            name="virtual2"
                                            placeholder="R$ 00,00"
                                            mask="R$ 999.999,99"
                                            value={DTOServices[serviceKey].servicos[4].valor_advogado}
                                            onChangeText={(value) => handleOnChangeServices(3, 'valor_advogado', value, serviceKey)}                                            
                                            className={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 3 && obj.valor_advogado === true).length > 0 ? 'mid error' : 'mid'}
                                            error={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 3 && obj.valor_advogado === true).length > 0 ? '*' : ''}
                                        />
                                        <InputMoney
                                            title="Somente Preposto:"
                                            placeholder="R$ 00,00"
                                            mask="R$ 999.999,99"
                                            name="preposto4"
                                            value={DTOServices[serviceKey].servicos[4].valor_preposto}
                                            onChangeText={(value) => handleOnChangeServices(3, 'valor_preposto', value, serviceKey)}                                            
                                            className={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 3 && obj.valor_preposto === true).length > 0 ? 'mid error' : 'mid'}
                                            error={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 3 && obj.valor_preposto === true).length > 0 ? '*' : ''}
                                        />
                                        <InputMoney
                                            title="Advogado + Preposto:"
                                            placeholder="R$ 00,00"
                                            mask="R$ 999.999,99"
                                            name="advprep4"
                                            value={DTOServices[serviceKey].servicos[4].valor_total}
                                            onChangeText={(value) => handleOnChangeServices(3, 'valor_total', value, serviceKey)}                                            
                                            className={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 3 && obj.valor_total === true).length > 0 ? 'mid error' : 'mid'}
                                            error={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 2 && obj.valor_total === true).length > 0 ? '*' : ''}
                                        />
                                    </div>
                                </div>

                                <div className="services-hearing">
                                    <p className="hearing-title">AUDIÊNCIA PENAL VIRTUAL</p>

                                    <div className="hearing-fields">
                                        <InputMoney
                                            title="Somente Advogado:"
                                            name="virtual2"
                                            placeholder="R$ 00,00"
                                            mask="R$ 999.999,99"
                                            value={DTOServices[serviceKey].servicos[5].valor_advogado}
                                            onChangeText={(value) => handleOnChangeServices(19, 'valor_advogado', value, serviceKey)}                                            
                                            className={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 3 && obj.valor_advogado === true).length > 0 ? 'mid error' : 'mid'}
                                            error={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 3 && obj.valor_advogado === true).length > 0 ? '*' : ''}
                                        />
                                        <InputMoney
                                            title="Somente Preposto:"
                                            placeholder="R$ 00,00"
                                            mask="R$ 999.999,99"
                                            name="preposto4"
                                            value={DTOServices[serviceKey].servicos[5].valor_preposto}
                                            onChangeText={(value) => handleOnChangeServices(19, 'valor_preposto', value, serviceKey)}                                            
                                            className={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 3 && obj.valor_preposto === true).length > 0 ? 'mid error' : 'mid'}
                                            error={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 3 && obj.valor_preposto === true).length > 0 ? '*' : ''}
                                        />
                                        <InputMoney
                                            title="Advogado + Preposto:"
                                            placeholder="R$ 00,00"
                                            mask="R$ 999.999,99"
                                            name="advprep4"
                                            value={DTOServices[serviceKey].servicos[5].valor_total}
                                            onChangeText={(value) => handleOnChangeServices(19, 'valor_total', value, serviceKey)}                                            
                                            className={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 3 && obj.valor_total === true).length > 0 ? 'mid error' : 'mid'}
                                            error={advancePrecificationError[serviceKey]?.servicos.filter((obj:any) => obj.key === 2 && obj.valor_total === true).length > 0 ? '*' : ''}
                                        />
                                    </div>
                                </div>

                                <div className="services-hearing">
                                    <p className="hearing-title">OUTROS SERVIÇOS</p>

                                    <div className="hearing-fields">
                                        <InputMoney
                                            title="Diligências:"
                                            name="diligencias"
                                            placeholder="R$ 00,00"
                                            mask="R$ 999.999,99"
                                            value={DTOServices[serviceKey].outros_servicos.valor_diligencia}
                                            onChangeText={(value) => handleOnChangeServices(0, 'diligencia', value, serviceKey) }                                            
                                            className={advancePrecificationError[serviceKey]?.outros_servicos?.valor_diligencia ? 'small error' : 'small'}
                                            error={advancePrecificationError[serviceKey]?.outros_servicos?.valor_diligencia ? '*' : ''}
                                        />
                                        <InputMoney
                                            title="Protocolos:"
                                            placeholder="R$ 00,00"
                                            mask="R$ 999.999,99"
                                            name="protocolos"
                                            value={DTOServices[serviceKey].outros_servicos.valor_protocolo}
                                            onChangeText={(value) => handleOnChangeServices(0, 'protocolo', value, serviceKey) }                                            
                                            className={advancePrecificationError[serviceKey]?.outros_servicos?.valor_protocolo ? 'small error' : 'small'}
                                            error={advancePrecificationError[serviceKey]?.outros_servicos?.valor_protocolo ? '*' : ''}
                                        />                                        
                                    </div>
                                </div>

                            </div>
                        )}

                        <div className="dot-border" onClick={handleAddPrecification}><IconPlus /> Adicionar precificação</div>

                        <div className="footer">
                            <p>Contrataremos a partir das precificações que você acaba de criar!</p>
                            <p>Você pode alterá-las nas configurações da sua conta sempre que quiser.</p>
                        </div>

                    </form>
                }

                { // pagina 3 - comarca
                    activePage === 3 &&
                        <form className='county-form'>

                            { DTOComarcas.map((row:any, key:number) =>
                                <div className={`comarca ${key > 0 ? 'line' : 'none'}`} key={key}>

                                    <div className="county">
                                        <div className="label">
                                            <p>Estado da comarca:</p>
                                            <Select
                                                styles={countyState}
                                                components={{
                                                    IndicatorSeparator: () => null
                                                }}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 8,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: '#DDFAF4',
                                                        primary50: '#DDFAF4',
                                                        primary: '#02E8BE',
                                                    },
                                                })}
                                                name='estado_comarca'
                                                placeholder="Selecionar"
                                                defaultValue={row.uf !== '' ? {value: row.uf, label: row.uf} : ''}
                                                options={EstadosBrasileiros}
                                                onChange={(e:any) => handleOnChangeComarcas(key, 'uf', e.value)}
                                            />
                                            <span className="error">{advanceCountyError.uf ? '*' : ''}</span>
                                        </div>

                                        { cities.filter((obj:any) => obj.uf === row.uf )[0]?.docs.length > 0 &&
                                            <div className="label">
                                                <p>Cidade da comarca:</p>
                                                <Select
                                                    styles={countyCity}
                                                    components={{
                                                        IndicatorSeparator: () => null
                                                    }}
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: 8,
                                                        colors: {
                                                            ...theme.colors,
                                                            primary25: '#DDFAF4',
                                                            primary50: '#DDFAF4',
                                                            primary: '#02E8BE',
                                                        },
                                                    })}
                                                    name='cidade'
                                                    placeholder="Selecionar"
                                                    defaultValue={row.cidade !== '' ? {value: row.cidade, label: row.cidade} : ''}
                                                    options={cities.filter((obj:any) => 
                                                        obj.uf === row.uf
                                                    )[0]?.docs}
                                                    onChange={(e:any) => handleOnChangeComarcas(key, 'cidade', e.value)}
                                                />
                                                <span className="error">{advanceCountyError.cidade ? '*' : ''}</span>
                                            </div>
                                        }
                                    </div>

                                    <div className="pricing">
                                        <div className="label">
                                            <p>Precificação :</p>
                                            <Select
                                                styles={oabPrice}
                                                components={{
                                                    IndicatorSeparator: () => null
                                                }}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 8,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: '#DDFAF4',
                                                        primary50: '#DDFAF4',
                                                        primary: '#02E8BE',
                                                    },
                                                })}
                                                placeholder="Selecionar"
                                                defaultValue={row.precificacao !== '' ? { value: row.precificacao, label: row.precificacao } : ''}
                                                options={pricingCounty}
                                                onChange={(e:any) => handleOnChangeComarcas(key, 'precificacao', e.value)}
                                            />
                                            <span className="error">{advanceCountyError.precificacao ? '*' : ''}</span>
                                        </div>

                                        <div className="right">
                                            <div className="radio-input">
                                                <input 
                                                    type="radio" 
                                                    value="sim"
                                                    name="Principal" 
                                                    checked={row.principal}                                           
                                                    onChange={(e:any) => handleOnChangeComarcas(key, 'principal', 'true')}
                                                    placeholder="Principal" 
                                                />
                                                <span>Principal</span>                                    
                                            </div>

                                            <button onClick={() => confirmDeleteComarca(key)} type="button"><IconTrash /> Excluir comarca</button>
                                        </div>

                                    </div>
                                </div>
                            )}

                            <div className="dot-border" onClick={handleAddComarca}><IconPlus /> Adicionar comarca</div>

                        </form>
                        // <CountyRegister />
                }

                {   // pagina 4 - Conta bancária
                    activePage === 4 &&
                    <form className='bank-account-form' >

                        <div className="account-data">
                            
                            <InputDefault
                                title="Nome do titular:"
                                placeholder="Nome"
                                name="nome_titular"
                                value={DTOAccount.nome_titular}
                                onChangeText={(value) => handleOnChange('nome_titular', value)}
                                className={advanceAccountError.nome_titular ? 'big error' : 'big'}
                                error={advanceAccountError.nome_titular ? '*' : ''}
                            />                            

                            <div className="label">
                                <p>Tipo de conta:</p>
                                <Select
                                    styles={accountSelect}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 8,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#DDFAF4',
                                            primary50: '#DDFAF4',
                                            primary: '#02E8BE',
                                        },
                                    })}
                                    name='tipo'
                                    placeholder="Selecionar"
                                    defaultValue={DTOAccount.tipo !== '' ? { value: DTOAccount.tipo, label: DTOAccount.tipo } : ''}
                                    onChange={handleSelect}
                                    options={accounts}
                                />
                                <span className="error">{advanceAccountError.tipo ? '*' : ''}</span>
                            </div>
                            
                            <div className="select">
                                <div className="title">Escolha:</div>
                                <div className="options">
                                    <div className="input-radio">
                                        <input 
                                            type={'radio'}
                                            placeholder='CPF'
                                            value='CPF'
                                            checked={selected === 'cpf' ? true : false}
                                            onChange={() => setSelected('cpf')}
                                        />
                                        CPF
                                    </div>
                                    <div className="input-radio"> 
                                        <input 
                                            type={'radio'}
                                            placeholder='cnpj'
                                            value='CNPJ'
                                            checked={selected === 'cnpj' ? true : false}
                                            onChange={() => setSelected('cnpj')}
                                        /> 
                                        CNPJ                                        
                                    </div>
                                </div>
                            </div>
                            {
                                selected === 'cnpj' &&
                                <InputDefault
                                    title="CNPJ:"
                                    mask="99.999.999/9999-99"
                                    placeholder="CNPJ"
                                    name="cnpj"
                                    value={DTOAccount.cnpj_cpf}
                                    onChangeText={(value) => handleOnChange('cnpj_cpf', value)}
                                    className={advanceAccountError.cnpj ? 'big error' : 'big'}
                                    error={advanceAccountError.cnpj ? '*' : ''}
                                />
                            }
                            {
                                selected === 'cpf' &&
                                <InputDefault
                                    title="CPF:"
                                    mask="999.999.999-99"
                                    placeholder="CPF"
                                    name="cpf"
                                    value={DTOAccount.cnpj_cpf}
                                    onChangeText={(value) => handleOnChange('cnpj_cpf', value)}
                                    className={advanceAccountError.cnpj ? 'big error' : 'big'}
                                    error={advanceAccountError.cnpj ? '*' : ''}
                                />
                            }
                        </div>

                        <div className="bank-data">
                            <div className="label">
                                <p>Banco:</p>
                                <Select
                                    styles={bankSelect}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 8,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#DDFAF4',
                                            primary50: '#DDFAF4',
                                            primary: '#02E8BE',
                                        },
                                    })}
                                    name='banco'
                                    placeholder="Selecionar"
                                    defaultValue={DTOAccount.banco !== '' ? { value: DTOAccount.banco, label: DTOAccount.banco } : ''}
                                    onChange={handleSelect}
                                    options={brazilianBanks}
                                />
                                <span className="error">{advanceAccountError.banco ? '*' : ''}</span>
                            </div>                            
                            <InputDefault
                                title="Agência:"
                                placeholder="0000"
                                name="agencia"
                                value={DTOAccount.agencia}
                                onChangeText={(value) => handleOnChange('agencia', value)}
                                className={advanceAccountError.agencia ? 'big error' : 'big'}
                                error={advanceAccountError.agencia ? '*' : ''}
                            />
                            <InputDefault
                                title="Conta:"
                                placeholder="0000000"
                                name="conta"
                                value={DTOAccount.conta}
                                onChangeText={(value) => handleOnChange('conta', value)}
                                className={advanceAccountError.conta ? 'big error' : 'big'}
                                error={advanceAccountError.conta ? '*' : ''}
                            />
                            <InputDefault
                                title="Dígito:"
                                placeholder="00"
                                name="digito"
                                value={DTOAccount.digito}
                                onChangeText={(value) => handleOnChange('digito', value)}
                                className={advanceAccountError.digito ? 'small error' : 'small'}
                                error={advanceAccountError.digito ? '*' : ''}
                            />
                        </div>

                        <div className="pix-data">
                            <div className="label">
                                <p>Tipo de chave do PIX:</p>
                                <Select
                                    styles={pixKey}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 8,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#DDFAF4',
                                            primary50: '#DDFAF4',
                                            primary: '#02E8BE',
                                        },
                                    })}
                                    name='tipo_chave_pix'
                                    placeholder="Selecionar"
                                    defaultValue={DTOAccount.tipo_chave_pix !== '' ? { value: DTOAccount.tipo_chave_pix, label: DTOAccount.tipo_chave_pix } : ''}
                                    onChange={handleSelect}
                                    options={PixKeyData}
                                />
                                <span className="error">{advanceAccountError.tipo_chave_pix ? '*' : ''}</span>
                            </div>     

                            <InputDefault
                                title="Chave PIX:"
                                placeholder="Chave pix"
                                name="chave_pix"
                                value={DTOAccount.chave_pix}                                
                                onChangeText={(value) => handleOnChange('chave_pix', value)}
                                className={advanceAccountError.chave_pix ? 'error pix-key' : 'pix-key'}
                                error={advanceAccountError.chave_pix ? '*' : ''}
                            />
                        </div>
                    </form>
                }

                {   // pagina 5 - Escolha de plano
                    activePage === 5 &&
                    <div>
                        <div className="page-five">
                            <Checkbox
                                label="Concordo com os termos de pagamento e condições"
                                name="terms"
                                value={checked}
                                onClick={() => setChecked(!checked)}
                            />
                        </div>
                        <RegisterPage5 selected={DTOUser.id_plano} setCard={(value: any) => handleOnChange('id_plano', value)} />
                    </div>
                }

                {   // pagina 6 - Senha  
                    activePage === 6 &&
                    <form className="login-form">
                        <div className="wrapper-mail">
                            <InputDefault
                                title="E-mail:"
                                placeholder="E-mail"
                                value={DTOUser.email}
                                onChangeText={(value) => handleOnChange('email', value)}
                                className='email'
                            />
                        </div>

                        <div className="pass-text">
                            <p>Crie uma senha forte. Utilize ao menos 6 dígitos, contendo 1 letra maíuscula, 1 número e 1 caractere especial.</p>
                        </div>

                        <div className="email-pass">

                            <div className="password">
                                <InputDefault
                                    title="Crie uma senha:"
                                    placeholder="senha"
                                    name="senha"
                                    value={DTOUser.senha}
                                    type={changeVisibility ? 'password' : 'text'}
                                    onChangeText={(value) => handleOnChange('senha', value)}
                                    className={advancePageError.senha || advancePageError.confirmeSenha ? 'empty' : ''}
                                    error={advancePageError.senha ? advancePageError.senha : ''}                               
                                />
                                <div
                                    className="icon"
                                    onClick={() => setChangeVisibility(!changeVisibility)}
                                >
                                    {changeVisibility ? <IconEyeOpen /> : <IconClosedEye />}
                                </div>
                            </div>
                            
                            <div className="password">
                                <InputDefault
                                    title="Confirme a senha:"
                                    placeholder="senha"
                                    name="confirmeSenha"
                                    type={changeVisibility ? 'password' : 'text'}
                                    value={DTOUser.confirmeSenha}
                                    onChangeText={(value) => handleOnChange('confirmeSenha', value)}
                                    className={advancePageError.confirmeSenha ? 'empty' : ''}
                                    error={advancePageError.confirmeSenha ? advancePageError.confirmeSenha : ''}
                                />
                                <div
                                    className="icon"
                                    onClick={() => setChangeVisibility(!changeVisibility)}
                                >
                                    {changeVisibility ? <IconEyeOpen /> : <IconClosedEye />}
                                </div>
                            </div>                            
                        </div>
                    </form>
                }
               
            </Main>

            <Footer>
                <div className="container">
                    <div className="buttons">
                        {
                            activePage === 1 &&
                                <ButtonDefault
                                    className="cancel"
                                    onClick={() => history.push('/')}
                                >
                                    Cancelar
                                </ButtonDefault>
                        }
                        {
                            activePage > 1 &&
                            <ButtonDefault
                                className="cancel"
                                onClick={() => setActivePage(activePage - 1)}
                            >
                                Voltar
                            </ButtonDefault>
                        }

                        <ButtonDefault
                            className="continue"
                            type="submit"
                            onClick={() => advancePage()}
                        >
                            Continuar
                        </ButtonDefault>
                    </div>

                    <div className="progress">
                        <div className="progressbar">
                            <div className={
                                activePage === 1 ? "percent" : '' ||
                                activePage === 2 ? "percent two" : '' ||
                                activePage === 3 ? "percent three" : '' ||
                                activePage === 4 ? "percent four" : '' ||
                                activePage === 5 ? "percent five" : '' ||
                                activePage === 6 ? "percent six" : ''
                                }>
                            </div>
                        </div>
                        <div>
                            <span>
                                {
                                    activePage === 1 ? "20%" : '' ||
                                    activePage === 2 ? "35%" : '' ||
                                    activePage === 3 ? "50%" : '' ||
                                    activePage === 4 ? "75%" : '' ||  
                                    activePage === 5 ? "90%" : '' ||  
                                    activePage === 6 ? "98%" : ''   
                                }
                            </span> do perfil completo
                        </div>
                    </div>
                </div>
            </Footer>  
            
            {/* Modal termos para cadastro JurídicoJá */}
            <ModalTerms
                id={'overlayModal'}
                onClose={() => setOpenModal((openModal) => !openModal)}
                openModal={openModal}
                width="100%"
            >
                <ModalHeader>
                    <div><IconLogoJuridico /></div>

                    <button
                        onClick={() => setOpenModal(false)}
                    >
                        <IconClose />
                    </button>

                </ModalHeader>

                <ModalMain>
                    <div className="title">
                        <p>Termos e condições de uso e políticas de privacidade</p>
                        para se tornar um <span className="pro">profissional jurídico</span> <span className="now">em 1,2,3!</span>
                    </div>

                    <p className="heavy">POR FAVOR, LEIA COM ATENÇÃO ESTES TERMOS ANTES DE ACESSAR OU USAR OS SERVIÇOS.</p>

                    <p className="two">
                        Os Termos, Condições gerais de uso e política de cookies e privacidade de acordo com a Lei nº 13.709/2018 - Lei de Proteção de Dados, apresentados se aplicam aos serviços prestados pela plataforma 123JUS, doravante denominada EMPRESA.
                    </p>

                    <p className="heavy">
                        DISPOSIÇÕES GERAIS
                    </p>

                    <p className="heavy">
                        CONSIDERANDO QUE:
                    </p>

                    <p>
                        (I) - A empresa 123JUS é uma empresa de tecnologia que criou sistema para intermediar a contratação de profissionais jurídicos e auxiliar na execução dos serviços dos mesmos;
                    </p>

                    <p>
                        (II) - que a plataforma será integrada com chat para comunicação, sistema web e outras ferramentas de tecnologia;
                    </p>

                    <p>
                        (III) - que a intermediação de serviços se dará mediante planos de assinatura, conforme livre escolha do usuário no momento de cadastro;
                    </p>

                    <p>
                        (IV) - que gateway de pagamento irá "distribuir" valores recebidos pelos usuários solicitantes de serviços entre o usuário executor do serviço e a plataforma 123JUS em contraprestação à intermediação da contratação e pagamento do serviço prestado;
                    </p>

                    <p className="two">
                        (V) - que a plataforma contará com os seguintes tipos de usuários: usuários solicitantes de serviço (clientes), usuários prestadores de serviço (profissional jurídico), usuários auditores (jurídico interno de empresas) e usuários representantes regionais (prestam serviço aos profissionais jurídicos intermediando seus serviços), podendo serem criados novos tipos de usuários em que este termo precise ser alterado, valendo desde já para todos que utilizarem os serviços da empresa 123JUS.
                    </p>

                    <p>
                        Assim, ciente das considerações acima e de acordo com as mesmas a EMPRESA disponibiliza para todos os usuários contratantes este termo, condições de uso e políticas de privacidade. Qualquer usuário ou cliente que deseje utilizar os serviços da EMPRESA será denominado USUÁRIO e/ou no plural USUÁRIOS quando os termos forem os mesmo para mais de um tipo de usuário, deve ler atentamente este termo, considerações acima expostas e caso queira deve aceitar os Termos, Condições Gerais e políticas de privacidade com todos os princípios que o regem. A sua aceitação é indispensável à utilização, devendo o usuário ler com atenção, certificar-se de haver entendido e aceitar todas as condições estabelecidas, além de outras regras que possam ser aplicáveis, não se restringindo as aqui descritas, tais como: Lei nº 8.078/1990 (“Código de Defesa do Consumidor”), Lei nº 10.406/2002 (“Código Civil Brasileiro”), Lei nº 13.709/2018 (Lei de Proteção de Dados), Código de Ética e Disciplina da Ordem dos Advogados do Brasil e Lei nº 8.904/1994 (“Estatuto da Ordem dos Advogados do Brasil”), antes de concluir o cadastro e/ou solicitar ou aceitar qualquer serviço.
                    </p>

                    <p>
                        Ao acessar e usar os Serviços você concorda com os presentes termos, forma de pagamento, prazo e condições, que estabelecem o relacionamento contratual entre você, na qualidade de usuário(a), e a EMPRESA. Se você não concorda com estes termos, você não pode acessar nem usar os serviços da EMPRESA. Mediante referido acesso e uso, estes Termos imediatamente encerram, substituem e superam todos os acordos, termos e acertos anteriores. Podendo a EMPRESA encerrar este termo ou serviços em relação a você a qualquer tempo e condição.
                    </p>

                    <p>
                        Ao acessar/cadastrar/utilizar a plataforma o Utilizador declara ser civilmente capaz nos termos da Lei nº 10.406/2002 (“Código Civil Brasileiro”) para compreender, aceitar e cumprir os Termos de Uso e a Política de Privacidade. No caso de pessoas jurídicas, estas devem estar devidamente representadas. No ato da realização do cadastro o Usuário em nome de pessoa jurídica declara estar apto para representá-la nos termos que seguem e em outras ações relacionadas ao uso do site e aplicativo. O Usuário responderá solidariamente perante a Plataforma por violações aos Termos ou das legislações vigentes aplicáveis. O cadastro de utilizador com idade inferior a 18 (dezoito) anos não será de responsabilidade da Plataforma e sim de seus responsáveis legais, respondendo inclusive por danos eventualmente causados e ou serviços contratados.
                    </p>

                    <p>
                        Este termo se aplica a todos advogados(as), estudantes, pessoas físicas, pessoas jurídicas, que busquem ofertar ou contratar serviços. É recomendável que os denominados ESTUDANTES que queira prestar serviços, que possuam a Carteira de Estagiário da Ordem dos Advogados do Brasil (“OAB-E”) para atuação com excelência.
                    </p>

                    <p>
                        De acordo com os Termos, os Usuários assumem a obrigação de usar o site e o aplicativo de forma positiva, respeitando o princípio da boa-fé e sem enviar ao sistema material que seja ameaçador, violento, difamatório, pornográfico, abusivo, ofensivo, racista, discriminatório ou em desacordo com os direitos fundamentais resguardados pelo texto constitucional de 1988 ("Constituição da República Federativa do Brasil") ou outra legislação vigente;
                    </p>
                    
                    <p>
                        O Usuário ainda manifesta que só fará uso de materiais que tenham todas licenças e/ou aprovações necessárias;
                    </p>

                    <p>
                        Não irá incitar condutas que possam ser consideradas ilícitas na esfera civil e criminal ou que viole direito de terceiros em território nacional ou internacional, ou ilegais de qualquer forma;
                    </p>

                    <p>
                       Não fará o uso de fraudes ou dissimulações a fim de viciar as contratações ou escolhas dos Usuários ou funcionamento do sistema, como violações de segurança, protocolos, perfis falsos, ou qualquer outro meio.
                    </p>

                    <p>
                        Os Usuários que não agirem em conformidade e zelo para o bom funcionamento da plataforma serão responsabilizados por quaisquer danos e prejuízos que causarem. A EMPRESA informa que irá cooperar com autoridades que necessitarem identificar Usuários que estejam em desconformidade com os dispostos nestes Termos.
                    </p>

                    <p>
                        O usuário assume total responsabilidade por prejuízos, diretos ou indiretos, incluindo indenizações, lucros cessantes, honorários advocatícios e demais encargos judiciais ou extrajudiciais que a EMPRESA seja obrigado a incorrer em razão de ato ou omissão do Usuário.
                    </p>

                    <p>
                        A EMPRESA terá direito a indenização por danos causados pelo Usuário decorrentes de atividades no site ou por descumprimento dos termos gerais de uso e demais políticas, ou direitos de terceiros, incluindo honorários de advogados.
                    </p>

                    <p>
                        A EMPRESA está em acordo com todas as leis e tratados vigentes em território brasileiro. Caso o Usuário utilize a plataforma em outro local, estará sujeito ao cumprimento das normas locais.
                    </p>

                    <p>
                        O uso do nome “123JUS”, bem como os conteúdos que o acompanhe na prática empresarial como nome empresarial, marca, domínio, programas, banco de dados, documentos, são de propriedade exclusiva da EMPRESA e estão protegidos por leis e tratados, visto registro em órgão competente. A reprodução ou uso sem a devida autorização legal é expressamente proibido, acarretando responsabilidade penal e civil.
                    </p>

                    <p>
                        A página dos usuários pode expor links de outros sites, o que não significa que a EMPRESA seja responsável pelos mesmos. Portanto, a EMPRESA não possui responsabilidade pelo conteúdo, prática e serviços ofertados em site de terceiros, ainda que apresentados em sua plataforma.
                    </p>

                    <p>
                        Os links de outros sites apresentados na plataforma não incidem em relação de sociedade, supervisão, cumplicidade, solidariedade ou posse.
                    </p>

                    <p>
                        Os usuários, expressamente afirmam e reconhecem que não existem vínculos além do negócio jurídico de intermediação, reiterando que não existe relação trabalhista ou societária. 
                    </p>

                    <p className="two">
                        Este documento poderá sofrer mudanças a qualquer momento, bem como as funcionalidades do sistema, sem aviso prévio. O utilizador será notificado a aceitar os termos atualizados e estando em desacordo com as alterações deverá encerrar sua conta imediatamente. Caso não seja feito incidirá em aceitação automática do novo regulamento. A alteração dos termos poderá ser substituída por avisos e notificações dispostos no site e no aplicativo da plataforma. As alterações não vigorarão sob as negociações já iniciadas antes da publicação, sendo, neste caso, vigente a redação anterior.
                    </p>

                    <p className="heavy">
                        DOS SERVIÇOS
                    </p>

                    <p>
                        Ao aceitar este termo você está ciente e declara que a EMPRESA não é fornecedora de serviços, bens, funciona apenas como intermediadora dos serviços dos usuários prestadores e os que solicitam serviços, e, que estes serviços são prestados independentemente da EMPRESA QUE não se responsabiliza pela execução, qualidade, eventuais danos e qualquer outro fato ligado à realização do mesmo.
                    </p>

                    <p>
                        A EMPRESA não se responsabiliza por indisponibilidade decorrente de falhas no sistema, seja em períodos longos ou curtos. Os usuários devem estar cientes de que a qualquer momento o acesso pode ser interrompido temporariamente sem aviso prévio em caso de falhas, manutenção ou alterações no sistema.
                    </p>

                    <p>
                        A EMPRESA não se responsabiliza por danos causados em caso de prejuízo, perda ou danos em função da interrupção do sistema. Para tanto, recomenda-se que os usuários mantenham forma sobressalente de contratação, sendo certo que não haverá indenização ou reparação por parte da EMPESA, sendo isenta a responsabilidade desta.
                    </p>

                    <p>
                        Nas audiências em que os USUÁRIOS prestadores de serviço prestem depoimento como preposto para os USUÁRIOS solicitantes de serviço, deve este buscar o contato do prestador de serviço e fazer as devidas orientações. Ficando ciente que o ideal é sempre enviar preposto da própria empresa e que optar pela contratação de terceiros é um risco, já que estes não vivem o dia a dia da empresa representada, podendo haver má interpretação pelo Poder Judiciário das respostas dada, ou mesmo respostas em desacordo com o esperado. Não tem a EMPRESA qualquer responsabilidade sobre a oitiva de prepostos ou testemunhas orientados ou não pelo USUÁRIO solicitante de serviço.
                    </p>

                    <p>
                        A EMPRESA Já não se responsabiliza por qualquer vírus que possa atacar o equipamento do usuário em decorrência do acesso, utilização ou navegação na internet ou como consequência da transferência de dados, arquivos, imagens, textos vídeos ou áudio. Todavia os Usuários se comprometem a fazer o uso de arquivos livres de vírus ou tecnicamente danosos, incluindo, mas não se limitando a vírus como "cavalo de Tróia", worms, macros, componentes maliciosos, dados corrompidos e outros programas maliciosos ou projetados para alterar o bom funcionamento do sistema ou operações comuns de computadores.
                    </p>

                    <p>
                        O usuário é responsável por adquirir e atualizar os equipamentos e dispositivos necessários para acessar e usar os serviços e aplicativos da EMPRESA. A EMPRESA não garante que os serviços funcionarão em qualquer aparelho. Além disso, os serviços poderão estar sujeitos a mau funcionamento e atrasos inerentes ao uso da Internet e de comunicações eletrônicas.
                    </p>

                    <p>
                        A EMPRESA não será responsável por danos indiretos, incidentais, especiais, punitivos ou emergentes, inclusive lucros cessantes, perda de dados, danos morais ou patrimoniais relacionados, associados ou decorrentes de qualquer uso dos serviços ainda que a EMPRESA tenha sido alertada para a possibilidade desses danos.
                    </p>

                    <p className="two">
                        Para manter a qualidade dos serviços ofertados pela plataforma, a EMPRESA possui o direito de impedir ou interromper acesso de determinado Usuário que atue em desconformidade com o que consta nos Termos e Condições de Uso, da Política de Privacidade ou contratos que se celebrem através do uso da plataforma, sem a necessidade de notificação prévia.
                    </p>

                    <p className="heavy">
                        COMPLIANCE
                    </p>

                    <p>
                        As partes se obrigam, individualmente e em conjunto, a cumprirem com normas e regulamentações aplicáveis, a fim de evitar riscos e garantir a ética e transparência na condução das atividades. No momento do cadastro ou a qualquer momento o usuário poderá ser questionado se tem ações em andamento ou arquivadas contra os clientes da EMPRESA, devendo reportar se existe alguma ação, sendo responsável pela veracidade da informação.
                    </p>

                    <p>
                        O usuário deverá cumprir com todas as leis, regulamentações e normas aplicáveis à prestação dos serviços contratados, incluindo, mas não se limitando a, leis de combate à corrupção, leis de proteção de dados, Código de Ética da OAB, leis trabalhistas e ambientais. A EMPRESA compromete-se a manter registros precisos e atualizados de todas as transações e atividades relacionadas aos serviços contratados, bem como a cooperar com em qualquer investigação relacionada ao cumprimento das normas aplicáveis.
                    </p>

                    <p>
                        Ao aceitar este termo, o usuário afirma que não tem ou já teve ações judiciais ou extrajudiciais contra empresas que vier a atuar como advogado ou preposto devido a serviços solicitados pela EMPRESA. O usuário concorda em não propor ações contra empresas com as quais atuar pela intermediação da EMPRESA, pelo período em que se mantiver cadastrado como profissional. 
                    </p>

                    <p>
                        O usuário se compromete a cumprir o estabelecido no art. 18, inciso III, do Código de Ética e Disciplina da OAB veda ao advogado "advogar contra cliente ou ex-cliente, sem a observância do disposto no art. 19 deste Código". Assim, caso por erro receba proposta de serviço para atuar em favor de cliente que já tenha atuado contra em processos judiciais ou extrajudiciais, deve o usuário recusar a proposta.
                    </p>

                    <p className="two">
                        Caso o usuário desrespeite essa cláusula, a EMPRESA terá o direito de buscar reparação, inclusive danos e perdas, por meio de medidas legais cabíveis.

                    </p>

                    <p className="heavy">
                       DOS PRAZOS PARA REALIZAÇÃO DOS SERVIÇOS
                    </p>

                    <p>
                        Quando o usuário (profissional jurídico) aceitar realizar o serviço este deverá cumprir o prazo estipulado como final para a realização, o não cumprimento no prazo estipulado gera responsabilidade ao mesmo. Em caso de impossibilidade o usuário deverá informar a EMPRESA com no mínimo 24 (vinte e quatro) horas antes do fim do referido prazo.
                    </p>

                    <p className="two">
                        Os prazos poderão sofrer alterações pela EMPRESA, para maior ou menor, em virtude da complexidade do pedido ou da facilidade da diligência, considerando (outros serviços em destacamento, saída para diligência, etc.), bem como em decorrência dos procedimentos administrativos adotados pelos órgãos responsáveis, além dos casos em que para cumprimento da medida, o correspondente tiver que aguardar a devolução do processo e/ou procedimento da conclusão, da ausência de localização, indisponibilidade, entre outros fatores de força maior e caso fortuito.
                    </p>

                    <p className="heavy">
                        DO PREÇO PELOS SERVIÇOS PRESTADOS POR PROFISSIONAIS
                    </p>

                    <p>
                        A EMPRESA indica aos usuários a observância do disposto no Código de Ética e Disciplina da Ordem dos Advogados do Brasil, Lei nº 8.904/1994 (“Estatuto da Ordem dos Advogados do Brasil”) e Tabela de Honorários da Ordem dos Advogados do Brasil.
                    </p>

                    <p>
                        Os usuários são livres para no momento de cadastro estipularem os valores que serão cobrados pelos serviços prestados, não tendo a plataforma qualquer responsabilidade sobre os mesmos. O valor se dá em observância ao tempo e complexidade do serviço.
                    </p>

                    <p>
                        Os preços das diligências e serviços obedecem às tabelas da Ordem dos Advogados quando no Estado correspondente ao serviço houver, levando em conta ainda fatores como local, dificuldade de acesso, valores praticados pelos demais advogados, simplicidade ou complexidade do ato. Podendo haver desconto por eventual simplicidade do ato praticado.
                    </p>

                    <p className="two">
                        A EMPRESA declara que não há relação trabalhista entre as partes que pactuam entre si, bem como não há vínculo societário, agenciamento, consórcio, ou relação de qualquer natureza que não seja apenas o negócio jurídico.
                    </p>

                    <p className="heavy">DO CADASTRO</p>

                    <p>
                        Cada usuário poderá manter somente um cadastro junto a plataforma 123JUS.
                    </p>

                    <p>
                        O cadastro é pessoal e intransferível. Em nenhuma hipótese será permitida a cessão, venda, aluguel ou outra forma de transferência da conta. Também não se permitirá a criação de novos cadastros por pessoas cujos cadastros originais tenham sido suspensos de alguma forma por infração às políticas do site.
                    </p>

                    <p>
                        O cadastro é confirmado após o usuário preencher todos os campos obrigatórios, com informações exatas, precisas e verdadeiras. O usuário declarara e assume o compromisso de atualizar os dados inseridos em seu cadastro sempre que necessário ou tão logo houver modificações.
                    </p>

                    <p>
                        Os Usuários não devem cadastrar apelidos que possuam qualquer semelhança com o nome 123JUS ou qualquer outro nome de propriedade intelectual, tampouco deverá insinuar ou sugerir que os serviços anunciados na plataforma são de autoria da 123JUS. Também serão desconsiderados quaisquer atos ofensivos ao pleno funcionamento do sistema.
                    </p>

                    <p>
                        A 123JUS se reserva no direito de recusar solicitações de cadastros que estejam em desacordos com as políticas e regras dos presentes Termos e Condições Gerais, assim como possui o direito de suspender um cadastro previamente aceito.
                    </p>

                    <p>
                        A 123JUS poderá, a seu exclusivo critério, realizar buscas a fim de verificar os dados fornecidos pelos parceiros, julgar os serviços prestados e solicitar dados adicionais e documentos que se fizerem necessários para conferir as informações cadastradas, podendo ainda utilizar-se de base de dados públicas ou privadas.
                    </p>

                    <p>
                        Fornecer informações incompletas, falsas ou imprecisas violam toda a política dos Termos e Condições de Uso desta plataforma, estando o Usuário plenamente responsável pelos danos que vierem a ser causados por tal transgressão.
                    </p>

                    <p>
                        A Plataforma se reserva no direito de solicitar mais informações e documentos sobre o Usuário Contratante a qualquer tempo. Uma vez que se recuse a fornecer tais informações o usuário estará ferindo com os Termos.
                    </p>

                    <p>
                        A concessão de prazo para pagamento dos serviços poderá ou não ser concedido em função de análise do perfil do contratante, bem como documentos. Podendo tal concessão ser suspensa ou cancelada a qualquer tempo.
                    </p>

                    <p>
                        Qualquer problema encontrado no cadastro do usuário poderá trazer sanções a serem aplicadas pelo 123JUS, sendo essas a suspensão temporária ou definitiva da conta, sem prejuízo de outras medidas que entender necessárias e oportunas. Uma vez aplicada qualquer sanção será, automaticamente, cancelada os anúncios dos respectivos usuários, não cabendo a esta qualquer indenização ou ressarcimento.
                    </p>

                    <p>
                        A 123JUS não se responsabiliza pelos Dados Pessoais inseridos pelos Usuários. Esses devem garantir a sua veracidade e respondem, em qualquer caso, civil e criminalmente pelas informações cadastradas.
                    </p>

                    <p>
                        É de responsabilidade do usuário garantir a proteção de seus dados para acesso à plataforma, não podendo nenhuma contratação ser cancelada em razão do uso incorreto dos dados de acesso.
                    </p>

                    <p className="two">
                        O usuário acessará a sua conta através do apelido/e-mail (login) e senha, comprometendo-se a não informar a terceiros desautorizados esses dados, responsabilizando-se integralmente pelo uso que deles seja feito.
                    </p>

                    <p className="heavy">
                        DA DIVULGAÇÃO DE VAGAS
                    </p>

                    <p>
                        De acordo com a Lei n° 12.965/2014 ("Uso da Internet no Brasil"), artigo 18: “O provedor de conexão à internet não será responsabilizado civilmente por danos decorrentes de conteúdo gerado por terceiros”. Portanto, as informações providas pelo Correspondentes, as quais o Contratante possui amplo acesso, como nome, telefone de contato, localização, entre outras, não são responsabilidade da 123JUS.
                    </p>

                    <p>
                        A EMPRESA poderá disponibilizar para os usuários gratuitamente a possibilidade de divulgarem vagas e se candidatarem para as mesmas, sendo de responsabilidade dos mesmos as informações prestadas e o prosseguimento do processo seletivo.
                    </p>

                    <p className="two">
                        O disposto no item anterior se aplica igualmente a qualquer informação fornecida sobre serviços, tais como valores cobrados, características dos serviços, inclusive a divulgação e candidatura para vaga, são de responsabilidade única de seu anunciante, sem envolvimento por parte da Plataforma.
                    </p>

                    <p className="heavy">OBSERVÂNCIA À LEI DE PROTEÇÃO DE DADOS</p>

                    <p>
                       Estamos empenhados em salvaguardar a sua privacidade ao estabelecer esta relação conosco. Este termo tem a finalidade de deixar o mais claro possível a nossa política de coleta e compartilhamento de dados, informando sobre os dados coletados e como os utilizamos.
                    </p>

                    <p>
                        Ao utilizar os nossos serviços, você declara o seu EXPRESSO CONSENTIMENTO para coletarmos, tratarmos e armazenarmos dados sobre você quando julgarmos necessários à prestação de nossos serviços.
                    </p>

                    <p>
                        Pelo presente as partes declaram que tanto o aplicativo quanto o site quanto do uso do sistema observarão as disposições da à Lei 13.709/18 que regulamenta a proteção de dados pessoais e da Lei nº 12.965/14 que regulamenta o Marco Civil da Internet. Desde já você autoriza o colhimento e uso de dados nos seguintes teremos:
                    </p>

                    <p>
                        (I) - Algumas informações pessoais são obtidas quando você se registra, preenchendo um formulário de contato ou cadastro. Durante o cadastro a EMPRESA solicita alguns dados como nome, endereço de e-mail e outras informações pessoais. 
                    </p>

                    <p>
                        (II) - Podemos registrar e gravar todos os dados fornecidos em toda comunicação realizada com nossa equipe, seja por correio eletrônico, mensagens, telefone ou qualquer outro meio.
                    </p>

                    <p>
                        (III) - Ao submeter seus dados para pagamento, podemos coletar informações sobre a compra ou transação. Isso abrange suas informações de pagamento, como os dados do seu cartão de crédito ou débito, informações de conta e autenticação, além dos dados de faturamento, tais como endereço completo, CPF e CNPJ.
                    </p>

                    <p>
                        (IV) - As informações pessoais transmitidas não são reveladas sem a sua autorização prévia. Portanto, não serão vendidas, compartilhadas sem ser para os propósitos aqui expostos ou alugadas. Suas informações servem exclusivamente para criar um perfil dos usuários e permitem melhorar a qualidade dos serviços, ao mesmo tempo em que possibilitam direcionamento de conteúdos com maior eficácia.
                    </p>

                    <p>
                        (V) - As informações também poderão ser utilizadas para gerar dados estatísticos gerais com finalidade informativa ou comercial. Informações geográficas, demográficas, psicográficas e de perfil de uso dos usuários poderão ser fornecidas a parceiros, patrocinadores, anunciantes ou outras empresas externas, sem que sejam revelados nomes ou outros dados de navegação.
                    </p>

                    <p>
                        (VI) - Desde já fica permitido o uso de cookie, que é um pequeno arquivo que seu navegador salva no seu disco rígido. Os cookies não conterão nenhuma informação pessoal identificável, mas permitirão oferecer determinadas funcionalidades que facilitam a navegação.
                    </p>

                    <p>
                        (VII) - Ao navegar na Internet, algumas informações serão transmitidas automaticamente entre computadores e serão coletadas como parte das exigências tecnológicas inerentes à navegação, para fins estatísticos. São elas: nome do domínio, IP, data e hora de navegação no site da EMRPESA, páginas visitadas dentro do site da EMPRESA, tipo de navegador e outras correlacionadas.
                    </p>

                    <p>
                        Compartilhamos os seus dados com a empresa responsável pelo processamento de pagamentos, para fins de viabilizar pagamentos e o processamento de dados, bem como para tornar o produto mais relevante e eficiente à sua finalidade.
                    </p>

                    <p>
                        Estes dados são armazenados em servidores seguros nossos ou de fornecedores contratados, acessados e utilizados de acordo com nossas políticas e padrões de segurança. Todo o tráfego entre nossos servidores ou entre o seu computador e nossos servidores é encriptado através do protocolo seguro SSL ou semelhante.
                    </p>

                    <p>
                        Tomamos medidas de boas práticas e certificações existentes no mercado para garantir que os dados que coletamos sejam processados de acordo com segurança onde quer que os dados estejam localizados.
                    </p>

                    <p>
                        Nós manteremos as informações que coletamos de você até que ocorra a solicitação de exclusão definitiva por sua parte. Neste caso, nós cessaremos imediatamente a utilização dos seus dados para fins comerciais, porém armazenaremos os seus dados enquanto tenhamos obrigações legais, tributárias ou judiciais a cumprir com tais dados.
                    </p>

                    <p>
                        Ao utilizar a plataforma você AUTORIZA EXPRESSAMENTE o uso destas informações para as seguintes FINALIDADES:
                    </p>

                    <ul className="list">
                        <li className="list-info">
                            Fornecer, melhorar e desenvolver os Serviços disponíveis. Usamos os seus dados para melhorar a qualidade dos nossos serviços, através da personalização do serviço prestado.
                        </li>
                        <li className="list-info">
                            Obrigações legais. Compartilhamos seus dados com terceiros para cumprir exigências legais, regulatórias ou fiscais, envolvendo a divulgação de seus dados pessoais a terceiros, a um tribunal, reguladores ou agências governamentais.
                        </li>
                    </ul>

                    <p className="two">
                        A não observância de qualquer disposição das referidas leis implicarão em responsabilidade exclusiva do infrator.
                    </p>

                    <p className="heavy">LICENÇA</p>

                    <p>
                        A EMPRESA, outorga a você licença de caráter não exclusivo e limitado, proibindo a sublicença à terceiros, sendo revogável por opção da mesma para: (i) uso do site; e (ii) acesso e uso de aplicativo. Quaisquer direitos não expressamente aqui outorgado são restritos à EMPRESA.
                    </p>

                    <p className="two">
                        Este documento e/ou o uso dos serviços não conferem direito sobre os serviços, exceto pela licença limitada concedida acima, ficando restrito o uso de nomes societários, logotipos, nomes de produtos ou de serviços, marcas comerciais ou qualquer outra propriedade da EMPRESA.
                    </p>

                    <p className="heavy">OBRIGAÇÕES DOS USUÁRIOS</p>

                    <p className="two">
                        O Usuário não deve autorizar terceiros a usarem sua conta receberem serviços em seu nome, salvo se constituírem sociedade. O Usuário concorda em cumprir todas as leis aplicáveis quando usar os serviços da EMRPESA e apenas poderá usar os serviços da EMPRESA para finalidades lícitas e previstas neste termo. Não poderá o usuário utilizar a plataforma ou aplicativo para fins ilícitos ou para causar transtorno, aborrecimento, inconveniente ou danos à propriedade a qualquer pessoa.
                    </p>

                    <p className="heavy">CONFIDENCIALIDADE</p>

                    <p className="two">
                        Os usuários se comprometem a manter em estrita confidencialidade todos os documentos e informações, que não sejam de conhecimento público e outras informações divulgadas ou recebidas em decorrência da utilização do site e/ou aplicativo e dos serviços prestados pelos usuários fornecedores de serviços, tais como preços, estratégias, documentos produtos e serviços.
                    </p>

                    <p className="heavy">CONDIÇÕES GERAIS PARA PAGAMENTO</p>

                    <p>1. DO PAGAMENTO</p>

                    <p>
                        1.1 - Os Usuários aceitam, expressamente, pagar a mensalidade do plano livremente escolhido, bem como o valor da diferença entre o que foi pago pelo solicitante e cobrado pelo usuário prestador de serviço. Concordam a pagar a EMPRESA remuneração que envolverá: tarifa fixa de transação e/ou taxa do meio de pagamento, em porcentagem declarada, por transação. Os valores serão celebrados no acordo de tarifas ofertadas no site e aceitas entre as partes.
                    </p>

                    <p>
                        1.2 - A verificação de conta e preenchimento de formulário são fatores importantes para a utilização dos serviços. Os Usuários irão fornecer informações próprias: nome/razão social, CPF/CNPJ, endereço, endereço de cobrança, endereço eletrônico, números de telefones e dados da conta bancária.
                    </p>

                    <p>
                        1.2.1 - A falta de veracidade nestas informações acarretará sanções ao Usuário ou atraso no pagamento que será justificado.
                    </p>

                    <p>
                        1.3 - Todos os pagamentos devidos serão efetuados através do pix ou conta bancária informada no formulário de cadastro, sendo a remuneração da EMPRESA automaticamente descontada do valor, conforme item 1.1, podendo ocorrer diferenças em razão do tipo de transação, meio de pagamento, segmento de atuação, forma de captura ou plano contratado.
                    </p>

                    <p>
                        1.4 - Os pagamentos poderão ser realizados das formas que seguem:
                    </p>

                    <p>
                        1.4.1 - Boleto Bancário: A liberação irá depender da data de liquidação do boleto bancário. Deverão ser observadas as regras de compensação do banco ao qual o pagamento foi efetuado.                        
                    </p>

                    <p>
                        1.4.2 - Cartão de Crédito: A liberação será feita a cada fim do ciclo de cobrança (30 dias), por parcela, contando a partir da data de aprovação do pagamento efetuado.
                    </p>

                    <p>                        
                        1.4.3 - Dépósito em Conta: A liberação será feita de acordo com a regra de cada instituição bancária.
                    </p>

                    <p>
                        1.5	- Só serão pagas junto com a fatura mensal, as demanda que após a conclusão no sistema pelo USUÁRIO foram aprovadas com aceite pela equipe interna da EMPRESA, podendo haver prévio pagamento de despesas a serem pactuadas no curso da demanda. O comprovante de pagamento será disponibilizado e anexado na fatura mensal, conforme o caso.
                    </p>

                    <p className="two">
                        1.6	- As transações bancárias serão feitas no dia útil subsequente ao dia de vencimento quando este cair em feriado ou fim de semana e estará totalmente sujeita ao funcionamento do banco.
                    </p>

                    <p>
                        2.	DA RESTITUIÇÃO 
                    </p>

                    <p>
                        2.1	- A EMPRESAS possui políticas a respeito do cancelamento de transações e restituição. Essas deverão ser feitas no valor exato da transação.
                    </p>

                    <p>
                        2.2	- O Usuário deverá restituir a sua exclusiva responsabilidade.
                    </p>

                    <p>
                        2.3	- Em casos onde a 123JUS seja intimada a efetuar restituições, o Usuário será imediatamente notificado a fim de que este efetue a transação, a sua exclusiva responsabilidade.
                    </p>

                    <p className="two">
                        2.3.1 - Caso a restituição não seja feita nos prazos e condições estipulados, será demandado processo de cobrança dos valores devidos.
                    </p>

                    <p>
                       3. DOS CHARGEBACKS E ESTORNOS
                    </p>

                    <p>
                        3.1	- O presente item trata sobre o procedimento realizado pelo consumidor junto a operadora de cartão de débito ou crédito, alegando desconhecimento de transação específica efetuada com o cartão de sua titularidade.
                    </p>

                    <p>
                        3.2	- Deverão ser observadas regras de estorno de cada bandeira, mantendo as boas práticas comerciais a fim de que se evite o tratado no item 3.1. Recomendamos que os comprovantes de transações sejam conservados.
                    </p>

                    <p>
                        3.3	- Em caso de notificações de Chargerback recebidas pela EMPRESA, o Usuário será notificado para realizar as devidas providências junto ao reclamante, no prazo MÁXIMO de 30 dias e iniciará o devido processo de cobrança de valores devidos.
                    </p>

                    <p>
                        3.3.1 - No ato de descumprimento do prazo definido no item anterior, bem como falta de atualização à Plataforma sobre os trâmites, a EMPRESA iniciará processo de cobrança.
                    </p>

                    <p>
                        3.4	- O Usuário terá o limite máximo de 1% de pedidos de Chargerback no mês, em relação ao número máximo de transações, do contrário sua conta será suspensa pelo prazo de 60 dias.
                    </p>

                    <p>
                        3.5	- Para reincidentes no disposto no item 3.4 será aplicada multa de 25% sob o valor do Chargerback e a suspensão se dará por 90 dias.
                    </p>

                    <p className="two">
                        3.6	- Para reincidentes em dobro, as sanções citadas no item 3.5 serão aplicadas em dobro.
                    </p>

                    <p>4. DAS DISPUSTAS E RETENÇÕES </p>

                    <p>
                        4.1	- A EMPRESA reconhece a observância das regras de disputas quando em desacordo comercial e Chargebacks iniciados por seus Usuários. Em caso de disputas, os saldos estarão retidos até a sua resolução.
                    </p>

                    <p>
                        4.2	- A disputas devem ser solucionadas em um prazo máximo de 30 dias, podendo variar de acordo com cada banco ou administradora de cartão. Durante o período de disputa, todo saldo a ela relacionado estará retido.
                    </p>

                    <p>
                        4.3	- Se o Usuário perder a disputa, autoriza previamente que o saldo anteriormente retido seja usado para dar quitação a disputa ou qualquer outro débito proveniente ou junto ao EMPRESA.
                    </p>

                    <p>
                        4.4	- Os saldos dos Usuários poderão ser retidos para auditoria e apuração de conduta, quando o referido atuar em desacordo com as Políticas desta Plataforma e de estabelecimentos bancários, administradoras de cartão, lei e práticas de Mercado e/ou Normas do BACEN.
                    </p>

                    <p>
                        4.5	- Condutas em que forem constatadas fraude em termos gerais, lavagem de dinheiro, etc., ocasionarão cancelamento da conta do Usuário na Plataforma EMPRESA. Nestes casos, os saldos serão retidos e as informações do infrator serão entregues de boa-fé às autoridades.
                    </p>

                    <p>5. DO DOMICÍLIO BANCÁRIO E COBRANÇA DE VALORES</p>

                    <p>
                        5.1	- Por meio deste o Usuário expressamente autorizada a EMPRESA efetuar operações de débito automático em conta bancária vinculada à conta utilizada na Plataforma, conforme formulário de cadastro, para as finalidades definidas em itens anteriores.
                    </p>

                    <p>
                        5.2	- Quando ocorrerem restituições, estornos ou cancelamentos em favor da EMPRESA, o valor exato da respectiva transação deverá ser entregue a EMPRESA pelo Usuário, sendo o valor corrigido sobre IGP-M/FGV (ou outro índice), contando a data do repasse, com juros de 1% (um por cento) a.m. ou fração pró-rata, com o acréscimo de encargos operacionais e qualquer perdas e danos ocorridos.
                    </p>

                    <p>
                        5.3	- Podem ser adotadas as seguintes medidas com fins de cobrança de valores devidos a EMPRESA:
                    </p>

                    <p>
                        5.3.1 - Compensação de valores através de outros créditos, presentes ou futuros, devidos.
                    </p>

                    <p>
                        5.3.2 - Lançamentos de débitos na conta bancária vinculada.
                    </p>

                    <p>
                        5.3.3 - Compensação por meio de cheque, boleto, TED, DOC ou depósito identificado, de acordo com tratativas entre as partes.
                    </p>

                    <p>
                        5.3.4 - Escritórios de cobrança especializados.
                    </p>

                    <p>
                        5.3.5 - Ações Judicial, protestos e negativações.
                    </p>

                    <p>
                        5.4	- Falta ou atraso de pagamento, seja total ou parcial, dentro dos prazos previamente acordados em contrato ou em respectivas atualizações, podem sujeitar o Usuário ao pagamento dos seguintes encargos:
                    </p>

                    <p>
                        5.4.1 - Atualização monetária das dívidas, baseando-se no IPC/FGV, ou outro índice.
                    </p>

                    <p>
                        5.4.2 - Acréscimo de juros de 2% (um por cento) a.m. Não obstante, o EMPRESA se reserva no direito de incluir os débitos do Usuário no cadastro de Pendências Financeiras (PEFIN) e qualquer outro órgão de proteção de crédito.
                    </p>

                    <p className="two">
                        5.4.3 - Inscrição nos órgãos de cadastro e proteção ao crédito.
                    </p>

                    <p>6. DO PRAZO DE PAGAMENTO</p>

                    <p>
                        6.1. - O usuário profissional jurídico que realiza serviços dentro de um mês, deverá agrupar todos os serviços feitos no mês em um relatório e enviar até o dia 20 (vinte) do mês subsequente ao de realização, relação de serviços realizados no mês anterior, que compreende o período do primeiro ao último dia do referido mês. Este relatório deverá ser mediante planilha modelo que lhe e deve ser enviado para o email financeiro@123jus.com.br.  O não envio do relatório dentro do prazo irá fazer com que a fatura passe para a fase de conferência com status de atrasada, o que atrasará o pagamento.
                    </p>

                    <p>
                        6.1.1. - A EMPRESA poderá concordar com a relação enviada ou caso discorde irá até o último dia do mês subsequente ao de realização dos serviços, enviar um email com o motivo da discordância e valor da fatura correto.
                    </p>

                    <p>
                        6.1.2. - O não envio de comprovação da divergência implicará automaticamente em concordância pelo usuário profissional jurídico dos serviços e valores contabilizado pela EMPRESA no período.
                    </p>

                    <p>
                        6.1.3. - Após finalizada a fase de conferência, no mês subsequente ao mês de conferência, entre o dia 20 e o último dia de cada mês, a EMPRESA realizará o pagamento ao usuário profissional jurídico na conta bancária indicada no cadastro, ou seja, em até 31 dias após o fim da fase de conferência, que dura do dia 20 (vinte) do mês subsequente à realização dos serviços até o último dia do mês. Portanto, o pagamento das diligências poderá ocorrer em até 60 (sessenta) dias após o fim do mês de realização dos serviços.
                    </p>

                    <p className="two">
                        6.1.4. - Excepcionalmente, em caso de atraso de pagamento pelo usuário solicitante poderá também haver atraso no pagamento do usuário profissional jurídico.
                    </p>

                    <p>RESCISÃO</p>

                    <p>
                        Os usuários tem o direito de cancelar sua conta ou assinatura na plataforma a qualquer momento. O cancelamento não incide em desobrigação de cumprimento das obrigações anteriormente assumidas.
                    </p>

                    <p>
                        As condições de cancelamento quando estiverem em curso a realização de serviços serão tratadas entre os usuários ou entre o usuário e a EMPRESA.
                    </p>

                    <p>
                        A 123JUS se reserva no direito de suspender, cancelar, impedir ou interromper o acesso de usuários à plataforma, sem aviso prévio, uma vez não seguidas as regras dispostas nos Termos e Condições de Uso, contratos celebrados por meio da plataforma, ou qualquer outro dispositivo que viole o bom funcionamento do site e aplicativo.
                    </p>

                    <p>
                        O usuário infrator poder ser notificado sobre o uso inadequado, à critério da 123JUS.
                    </p>

                    <p className="two">
                        Na suspeita de uso inadequado ou reincidência o usuário poderá ter o cadastrado cancelado.
                    </p>

                    <p>POLÍTICAS DE COOKIES</p>

                    <p>
                        Cookies são pequenos arquivos de texto ou fragmentos de informação que são baixados em seu computador, smartphone ou qualquer outro dispositivo com acesso à internet quando você visita nossa aplicação.
                    </p>

                    <p>
                        Eles contêm informações sobre a sua navegação em nossas páginas e retêm apenas informações relacionadas a suas preferências.
                    </p>

                    <p>
                        Assim, essa página consegue armazenar e recuperar os dados sobre os seus hábitos de navegação, de forma a melhorar a experiência de uso, por exemplo. É importante frisar que eles não contêm informações pessoais específicas, como dados sensíveis ou bancários.
                    </p>

                    <p>
                        O seu navegador armazena os cookies no disco rígido, mas ocupam um espaço de memória mínimo, que não afetam o desempenho do seu computador. A maioria das informações são apagadas logo ao encerrar a sessão, como você verá no próximo tópico.
                    </p>

                    <p className="heavy">
                        Os cookies, quanto a seus proprietários, podem ser:
                    </p>

                    <p>
                        Cookies proprietários: são cookies definidos por nós ou por terceiros em nosso nome
                    </p>

                    <p>
                        Cookies de terceiros: são cookies definidos por terceiros confiáveis em nossa aplicação
                    </p>

                    <p className="heavy">
                       Os cookies, quanto ao seu tempo de vida, podem ser:
                    </p>

                    <p>
                        Cookies de sessão ou temporários: são cookies que expiram assim que você fecha o seu navegador, encerrando a sessão.
                    </p>

                    <p>
                        Cookies persistentes ou permanentes: são cookies que permanecem no seu dispositivo durante um período determinado ou até que você os exclua.
                    </p>

                    <p className="heavy">
                        Os cookies, quanto a sua finalidade, podem ser:
                    </p>

                    <p>
                        Cookies necessários: são cookies essenciais que possibilitam a navegação em nossas aplicações e o acesso a todos os recursos; se estes, nossos serviços podem apresentar mal desempenho ou não funcionar.
                    </p>

                    <p>
                        Cookies de desempenho: são cookies que otimizam a forma que nossas aplicações funcionam, coletando informações anônimas sobre as páginas acessadas.
                    </p>

                    <p>
                        Cookies de funcionalidade: são cookies que memorizam suas preferências e escolhas (como seu nome de usuário)
                    </p>

                    <p>
                        Cookies de publicidade: são cookies que direcionam anúncios em função dos seus interesses e limitam a quantidade de vezes que o anúncio aparece.
                    </p>

                    <p className="heavy">
                        Porque usamos cookies:
                    </p>

                    <p>
                        A EMPRESA utiliza Cookies para fornecer a melhor experiência de uso, tornando nossas aplicações mais fáceis e personalizadas, tendo por base suas escolhas e comportamento de navegação.
                    </p>

                    <p>
                        Assim, buscamos entender como você utiliza nossas aplicações e ajustar o conteúdo para torná-lo mais relevante para você, além de lembrar de suas preferências.
                    </p>

                    <p>
                        Os Cookies participam deste processo porquanto armazenam, leem e executam os dados necessários para cumprir com o nosso objetivo.
                    </p>

                    <p className="heavy">Que tipo de cookies utilizamos:</p>

                    <p>
                        Vários são os tipos de cookies que podem ser utilizados pela EMPRESA. Dentre eles, não se restringindo a cookies necessários, de publicidade, desempenho, funcionalidade e outros que a EMPRESA julgar necessário. É importante lembrar que você pode gerenciar a permissão concedida para cada Cookie em seu navegador. Além disso, uma vez que os Cookies capturam dados sobre você, aconselhamos a leitura de nossa Política de Privacidade, disponível neste documento.
                    </p>

                    <p className="heavy">Gerenciamento de cookies:</p>

                    <p>
                        A instalação dos cookies está sujeita ao seu consentimento. Apesar da maioria dos navegadores estar inicialmente configurada para aceitar cookies de forma automática, você pode rever suas permissões a qualquer tempo, de forma a bloqueá-los, aceitá-los ou ativar notificações para quando alguns cookies forem enviados ao seu dispositivo.
                    </p>

                    <p>
                        Atualmente, na primeira vez que você acessa nossas aplicações, será requerida a sua concordância com a instalação destes. Apenas após a sua aceitação eles serão ativados.
                    </p>

                    <p>
                        Como já dito, você pode, a qualquer tempo e sem nenhum custo, alterar as permissões, bloquear ou recusar os Cookies. Você também pode configurá-los caso a caso. Todavia, a revogação do consentimento de determinados Cookies pode inviabilizar o funcionamento correto de alguns recursos da plataforma.
                    </p>

                    <p>
                        Para gerenciar os cookies do seu navegador, basta fazê-lo diretamente nas configurações do navegador, na área de gestão de Cookies.
                    </p>

                    <p>
                        Você pode acessar tutoriais sobre o tema diretamente nos links abaixo:
                    </p>

                    <p>
                        Se você usa o Internet Explorer. <br />
                        Se você usa o Firefox. <br />
                        Se você usa o Safari. <br />
                        Se você usa o Google Chrome. <br />
                        Se você usa o Microsoft Edge. <br />
                        Se você usa o Opera. <br />
                    </p>

                    <p className="heavy">Disposições finais sobre a política de cookies:</p>

                    <p>
                        Para a (nome empresarial), a privacidade e confiança são fundamentais para a nossa relação com você. Estamos sempre nos atualizando para manter os mais altos padrões de segurança 
                    </p>

                    <p>
                        Assim, reservamo-nos o direito de alterar esta Política de Cookies a qualquer tempo. As mudanças entrarão em vigor logo após a publicação, e você será avisado
                    </p>

                    <p>
                        Ao continuar a navegação nas nossas aplicações após essa mudança se tornar eficaz, você concorda com elas. Aconselhamos que você sempre verifique esta Política, bem como a nossa Política de Privacidade.
                    </p>

                    <p className="two">
                        Em caso de dúvidas sobre esta Política de Cookies, entre em contato conosco pelo email contato@123jus.com.br.
                    </p>

                    <p className="heavy">OUTRAS DISPOSIÇÕES </p>

                    <p>
                        <span>Tributos.</span> Qualquer tributo de qualquer natureza que possa incidir em decorrência das operações aqui previstas deverá ser arcado, exclusivamente, pela Parte a quem a lei aplicável atribuir a condição de contribuinte fiscal.
                    </p>

                    <p>
                        <span>Renúncia.</span> Nenhuma renúncia por qualquer das Partes a qualquer termo ou disposição deste atermo ou a qualquer descumprimento deste deverá afetar o direito de tal Parte de posteriormente exigir o cumprimento de tal termo ou disposição ou de exercer qualquer direito ou recurso na hipótese de qualquer outro descumprimento, seja ou não semelhante.
                    </p>

                    <p>
                       <span>Independência.</span> Se qualquer disposição deste termo for considerada inválida ou inexequível por qualquer tribunal com jurisdição competente, as demais disposições permanecerão em pleno vigor e efeito. Qualquer disposição deste instrumento que for considerada inválida ou inexequível somente em parte ou grau permanecerá em pleno vigor e efeito na medida em que não tenha sido considerada inválida ou inexequível.
                    </p>

                    <p>
                        <span>Responsabilidade por impostos.</span> Exceto se expressamente estabelecido de outra forma, a EMPRESA e usuários arcarão com seus próprios gastos, impostos, custos e despesas incorridos execução dos serviços prestados.
                    </p>

                    <p>
                        <span>Lei Aplicável.</span> Este Contrato reger-se-á por e será interpretado de acordo com as Leis da República Federativa do Brasil.
                    </p>

                    <p>
                        <span>Totalidade dos Entendimentos.</span> Este documento substitui todos e quaisquer entendimentos prévios mantidos, representando a totalidade dos entendimentos havidos entre as partes. 
                    </p>

                    <p>
                        <span>Eleição de Foro.</span> As Partes elegem o foro da Cidade de Belo Horizonte, Estado de Minas Gerais para dirimir quaisquer dúvidas ou divergências relativas a este termo, renunciando a qualquer outro, por mais privilegiado que seja.
                    </p>

                    <p>
                        Estes Termos de Uso, em conjunto com a Política de Cookies e de Privacidade e quaisquer outros avisos legais publicados pela EMPRSA integram o contrato entre a EMPRESA e os usuários. Se a conta do usuário for encerrada por violações relevantes a este termo ou às políticas de privacidades o usuário poderá ser proibido de fazer qualquer uso dos serviços da EMPREA.O uso do website da EMPRESA pelos usuários após qualquer aditamento aos Termos de Uso implicará o consentimento e a aceitação dos termos aditados.
                    </p>

                    <p className="center">
                        Belo Horizonte, 19 de fevereiro de 2023.
                    </p>
                    
                </ModalMain>
            </ModalTerms> 
            
            {/* Modal para os termos de pagamento */}
            <ModalTerms
                id={'overlayModal'}
                onClose={() => setOpenPaymentTerms((openPaymentTerms) => !openPaymentTerms)}
                openModal={openPaymentTerms}
                width="100%"
            >
                <ModalHeader>
                    <div><IconLogoJuridico /></div>

                    <button
                        onClick={() => setOpenPaymentTerms(false)}
                    >
                        <IconClose />
                    </button>

                </ModalHeader>

                <ModalMain>
                    <div className="title">
                        <p>Termos e condições de uso e políticas de privacidade</p>
                        para se tornar um <span className="pro">profissional jurídico</span> <span className="now">em 1,2,3!</span>
                    </div>

                    <p className="heavy">POR FAVOR, LEIA COM ATENÇÃO ESTES TERMOS ANTES DE ACESSAR OU USAR OS SERVIÇOS.</p>

                    <p className="two">
                        Os Termos, Condições gerais de uso e política de cookies e privacidade de acordo com a Lei nº 13.709/2018 - Lei de Proteção de Dados, apresentados se aplicam aos serviços prestados pela plataforma 123JUS, doravante denominada EMPRESA.
                    </p>

                    <p className="heavy">
                        DISPOSIÇÕES GERAIS
                    </p>

                    <p className="heavy">
                        CONSIDERANDO QUE:
                    </p>

                    <p>
                        (I) - A empresa 123JUS é uma empresa de tecnologia que criou sistema para intermediar a contratação de profissionais jurídicos e auxiliar na execução dos serviços dos mesmos;
                    </p>

                    <p>
                        (II) - que a plataforma será integrada com chat para comunicação, sistema web e outras ferramentas de tecnologia;
                    </p>

                    <p>
                        (III) - que a intermediação de serviços se dará mediante planos de assinatura, conforme livre escolha do usuário no momento de cadastro;
                    </p>

                    <p>
                        (IV) - que gateway de pagamento irá "distribuir" valores recebidos pelos usuários solicitantes de serviços entre o usuário executor do serviço e a plataforma 123JUS em contraprestação à intermediação da contratação e pagamento do serviço prestado;
                    </p>

                    <p className="two">
                        (V) - que a plataforma contará com os seguintes tipos de usuários: usuários solicitantes de serviço (clientes), usuários prestadores de serviço (profissional jurídico), usuários auditores (jurídico interno de empresas) e usuários representantes regionais (prestam serviço aos profissionais jurídicos intermediando seus serviços), podendo serem criados novos tipos de usuários em que este termo precise ser alterado, valendo desde já para todos que utilizarem os serviços da empresa 123JUS.
                    </p>

                    <p>
                        Assim, ciente das considerações acima e de acordo com as mesmas a EMPRESA disponibiliza para todos os usuários contratantes este termo, condições de uso e políticas de privacidade. Qualquer usuário ou cliente que deseje utilizar os serviços da EMPRESA será denominado USUÁRIO e/ou no plural USUÁRIOS quando os termos forem os mesmo para mais de um tipo de usuário, deve ler atentamente este termo, considerações acima expostas e caso queira deve aceitar os Termos, Condições Gerais e políticas de privacidade com todos os princípios que o regem. A sua aceitação é indispensável à utilização, devendo o usuário ler com atenção, certificar-se de haver entendido e aceitar todas as condições estabelecidas, além de outras regras que possam ser aplicáveis, não se restringindo as aqui descritas, tais como: Lei nº 8.078/1990 (“Código de Defesa do Consumidor”), Lei nº 10.406/2002 (“Código Civil Brasileiro”), Lei nº 13.709/2018 (Lei de Proteção de Dados), Código de Ética e Disciplina da Ordem dos Advogados do Brasil e Lei nº 8.904/1994 (“Estatuto da Ordem dos Advogados do Brasil”), antes de concluir o cadastro e/ou solicitar ou aceitar qualquer serviço.
                    </p>

                    <p>
                        Ao acessar e usar os Serviços você concorda com os presentes termos, forma de pagamento, prazo e condições, que estabelecem o relacionamento contratual entre você, na qualidade de usuário(a), e a EMPRESA. Se você não concorda com estes termos, você não pode acessar nem usar os serviços da EMPRESA. Mediante referido acesso e uso, estes Termos imediatamente encerram, substituem e superam todos os acordos, termos e acertos anteriores. Podendo a EMPRESA encerrar este termo ou serviços em relação a você a qualquer tempo e condição.
                    </p>

                    <p>
                        Ao acessar/cadastrar/utilizar a plataforma o Utilizador declara ser civilmente capaz nos termos da Lei nº 10.406/2002 (“Código Civil Brasileiro”) para compreender, aceitar e cumprir os Termos de Uso e a Política de Privacidade. No caso de pessoas jurídicas, estas devem estar devidamente representadas. No ato da realização do cadastro o Usuário em nome de pessoa jurídica declara estar apto para representá-la nos termos que seguem e em outras ações relacionadas ao uso do site e aplicativo. O Usuário responderá solidariamente perante a Plataforma por violações aos Termos ou das legislações vigentes aplicáveis. O cadastro de utilizador com idade inferior a 18 (dezoito) anos não será de responsabilidade da Plataforma e sim de seus responsáveis legais, respondendo inclusive por danos eventualmente causados e ou serviços contratados.
                    </p>

                    <p>
                        Este termo se aplica a todos advogados(as), estudantes, pessoas físicas, pessoas jurídicas, que busquem ofertar ou contratar serviços. É recomendável que os denominados ESTUDANTES que queira prestar serviços, que possuam a Carteira de Estagiário da Ordem dos Advogados do Brasil (“OAB-E”) para atuação com excelência.
                    </p>

                    <p>
                        De acordo com os Termos, os Usuários assumem a obrigação de usar o site e o aplicativo de forma positiva, respeitando o princípio da boa-fé e sem enviar ao sistema material que seja ameaçador, violento, difamatório, pornográfico, abusivo, ofensivo, racista, discriminatório ou em desacordo com os direitos fundamentais resguardados pelo texto constitucional de 1988 ("Constituição da República Federativa do Brasil") ou outra legislação vigente;
                    </p>
                    
                    <p>
                        O Usuário ainda manifesta que só fará uso de materiais que tenham todas licenças e/ou aprovações necessárias;
                    </p>

                    <p>
                        Não irá incitar condutas que possam ser consideradas ilícitas na esfera civil e criminal ou que viole direito de terceiros em território nacional ou internacional, ou ilegais de qualquer forma;
                    </p>

                    <p>
                       Não fará o uso de fraudes ou dissimulações a fim de viciar as contratações ou escolhas dos Usuários ou funcionamento do sistema, como violações de segurança, protocolos, perfis falsos, ou qualquer outro meio.
                    </p>

                    <p>
                        Os Usuários que não agirem em conformidade e zelo para o bom funcionamento da plataforma serão responsabilizados por quaisquer danos e prejuízos que causarem. A EMPRESA informa que irá cooperar com autoridades que necessitarem identificar Usuários que estejam em desconformidade com os dispostos nestes Termos.
                    </p>

                    <p>
                        O usuário assume total responsabilidade por prejuízos, diretos ou indiretos, incluindo indenizações, lucros cessantes, honorários advocatícios e demais encargos judiciais ou extrajudiciais que a EMPRESA seja obrigado a incorrer em razão de ato ou omissão do Usuário.
                    </p>

                    <p>
                        A EMPRESA terá direito a indenização por danos causados pelo Usuário decorrentes de atividades no site ou por descumprimento dos termos gerais de uso e demais políticas, ou direitos de terceiros, incluindo honorários de advogados.
                    </p>

                    <p>
                        A EMPRESA está em acordo com todas as leis e tratados vigentes em território brasileiro. Caso o Usuário utilize a plataforma em outro local, estará sujeito ao cumprimento das normas locais.
                    </p>

                    <p>
                        O uso do nome “123JUS”, bem como os conteúdos que o acompanhe na prática empresarial como nome empresarial, marca, domínio, programas, banco de dados, documentos, são de propriedade exclusiva da EMPRESA e estão protegidos por leis e tratados, visto registro em órgão competente. A reprodução ou uso sem a devida autorização legal é expressamente proibido, acarretando responsabilidade penal e civil.
                    </p>

                    <p>
                        A página dos usuários pode expor links de outros sites, o que não significa que a EMPRESA seja responsável pelos mesmos. Portanto, a EMPRESA não possui responsabilidade pelo conteúdo, prática e serviços ofertados em site de terceiros, ainda que apresentados em sua plataforma.
                    </p>

                    <p>
                        Os links de outros sites apresentados na plataforma não incidem em relação de sociedade, supervisão, cumplicidade, solidariedade ou posse.
                    </p>

                    <p>
                        Os usuários, expressamente afirmam e reconhecem que não existem vínculos além do negócio jurídico de intermediação, reiterando que não existe relação trabalhista ou societária. 
                    </p>

                    <p className="two">
                        Este documento poderá sofrer mudanças a qualquer momento, bem como as funcionalidades do sistema, sem aviso prévio. O utilizador será notificado a aceitar os termos atualizados e estando em desacordo com as alterações deverá encerrar sua conta imediatamente. Caso não seja feito incidirá em aceitação automática do novo regulamento. A alteração dos termos poderá ser substituída por avisos e notificações dispostos no site e no aplicativo da plataforma. As alterações não vigorarão sob as negociações já iniciadas antes da publicação, sendo, neste caso, vigente a redação anterior.
                    </p>

                    <p className="heavy">
                        DOS SERVIÇOS
                    </p>

                    <p>
                        Ao aceitar este termo você está ciente e declara que a EMPRESA não é fornecedora de serviços, bens, funciona apenas como intermediadora dos serviços dos usuários prestadores e os que solicitam serviços, e, que estes serviços são prestados independentemente da EMPRESA QUE não se responsabiliza pela execução, qualidade, eventuais danos e qualquer outro fato ligado à realização do mesmo.
                    </p>

                    <p>
                        A EMPRESSA não se responsabiliza por indisponibilidade decorrente de falhas no sistema, seja em períodos longos ou curtos. Os usuários devem estar cientes de que a qualquer momento o acesso pode ser interrompido temporariamente sem aviso prévio em caso de falhas, manutenção ou alterações no sistema.
                    </p>

                    <p>
                        A EMPRESA não se responsabiliza por danos causados em caso de prejuízo, perda ou danos em função da interrupção do sistema. Para tanto, recomenda-se que os usuários mantenham forma sobressalente de contratação, sendo certo que não haverá indenização ou reparação por parte da EMPESA, sendo isenta a responsabilidade desta.
                    </p>

                    <p>
                        Nas audiências em que os USUÁRIOS prestadores de serviço prestem depoimento como preposto para os USUÁRIOS solicitantes de serviço, deve este buscar o contato do prestador de serviço e fazer as devidas orientações. Ficando ciente que o ideal é sempre enviar preposto da própria empresa e que optar pela contratação de terceiros é um risco, já que estes não vivem o dia a dia da empresa representada, podendo haver má interpretação pelo Poder Judiciário das respostas dada, ou mesmo respostas em desacordo com o esperado. Não tem a EMPRESA qualquer responsabilidade sobre a oitiva de prepostos ou testemunhas orientados ou não pelo USUÁRIO solicitante de serviço.
                    </p>

                    <p>
                        A EMPRESA Já não se responsabiliza por qualquer vírus que possa atacar o equipamento do usuário em decorrência do acesso, utilização ou navegação na internet ou como consequência da transferência de dados, arquivos, imagens, textos vídeos ou áudio. Todavia os Usuários se comprometem a fazer o uso de arquivos livres de vírus ou tecnicamente danosos, incluindo, mas não se limitando a vírus como "cavalo de Tróia", worms, macros, componentes maliciosos, dados corrompidos e outros programas maliciosos ou projetados para alterar o bom funcionamento do sistema ou operações comuns de computadores.
                    </p>

                    <p>
                        O usuário é responsável por adquirir e atualizar os equipamentos e dispositivos necessários para acessar e usar os serviços e aplicativos da EMPRESA. A EMPRESA não garante que os serviços funcionarão em qualquer aparelho. Além disso, os serviços poderão estar sujeitos a mau funcionamento e atrasos inerentes ao uso da Internet e de comunicações eletrônicas.
                    </p>

                    <p>
                        A EMPRESA não será responsável por danos indiretos, incidentais, especiais, punitivos ou emergentes, inclusive lucros cessantes, perda de dados, danos morais ou patrimoniais relacionados, associados ou decorrentes de qualquer uso dos serviços ainda que a EMPRESA tenha sido alertada para a possibilidade desses danos.
                    </p>

                    <p className="two">
                        Para manter a qualidade dos serviços ofertados pela plataforma, a EMPRESA possui o direito de impedir ou interromper acesso de determinado Usuário que atue em desconformidade com o que consta nos Termos e Condições de Uso, da Política de Privacidade ou contratos que se celebrem através do uso da plataforma, sem a necessidade de notificação prévia.
                    </p>

                    <p className="heavy">
                       DOS PRAZOS PARA REALIZAÇÃO DOS SERVIÇOS
                    </p>

                    <p>
                        Quando o usuário (profissional jurídico) aceitar realizar o serviço este deverá cumprir o prazo estipulado como final para a realização, o não cumprimento no prazo estipulado gera responsabilidade ao mesmo. Em caso de impossibilidade o usuário deverá informar a EMPRESA com no mínimo 24 (vinte e quatro) horas antes do fim do referido prazo.
                    </p>

                    <p className="two">
                        Os prazos poderão sofrer alterações pela EMPRESA, para maior ou menor, em virtude da complexidade do pedido ou da facilidade da diligência, considerando (outros serviços em destacamento, saída para diligência, etc.), bem como em decorrência dos procedimentos administrativos adotados pelos órgãos responsáveis, além dos casos em que para cumprimento da medida, o correspondente tiver que aguardar a devolução do processo e/ou procedimento da conclusão, da ausência de localização, indisponibilidade, entre outros fatores de força maior e caso fortuito.
                    </p>

                    <p className="heavy">
                        DO PREÇO PELOS SERVIÇOS PRESTADOS POR PROFISSIONAIS
                    </p>

                    <p>
                        A EMPRESA indica aos usuários a observância do disposto no Código de Ética e Disciplina da Ordem dos Advogados do Brasil, Lei nº 8.904/1994 (“Estatuto da Ordem dos Advogados do Brasil”) e Tabela de Honorários da Ordem dos Advogados do Brasil.
                    </p>

                    <p>
                        Os usuários são livres para no momento de cadastro estipularem os valores que serão cobrados pelos serviços prestados, não tendo a plataforma qualquer responsabilidade sobre os mesmos. O valor se dá em observância ao tempo e complexidade do serviço.
                    </p>

                    <p>
                        Os preços das diligências e serviços obedecem às tabelas da Ordem dos Advogados quando no Estado correspondente ao serviço houver, levando em conta ainda fatores como local, dificuldade de acesso, valores praticados pelos demais advogados, simplicidade ou complexidade do ato. Podendo haver desconto por eventual simplicidade do ato praticado.
                    </p>

                    <p className="two">
                        A EMPRESA declara que não há relação trabalhista entre as partes que pactuam entre si, bem como não há vínculo societário, agenciamento, consórcio, ou relação de qualquer natureza que não seja apenas o negócio jurídico.
                    </p>

                    <p className="heavy">DO CADASTRO</p>

                    <p>
                        Cada usuário poderá manter somente um cadastro junto a plataforma 123JUS.
                    </p>

                    <p>
                        O cadastro é pessoal e intransferível. Em nenhuma hipótese será permitida a cessão, venda, aluguel ou outra forma de transferência da conta. Também não se permitirá a criação de novos cadastros por pessoas cujos cadastros originais tenham sido suspensos de alguma forma por infração às políticas do site.
                    </p>

                    <p>
                        O cadastro é confirmado após o usuário preencher todos os campos obrigatórios, com informações exatas, precisas e verdadeiras. O usuário declarara e assume o compromisso de atualizar os dados inseridos em seu cadastro sempre que necessário ou tão logo houver modificações.
                    </p>

                    <p>
                        Os Usuários não devem cadastrar apelidos que possuam qualquer semelhança com o nome 123JUS ou qualquer outro nome de propriedade intelectual, tampouco deverá insinuar ou sugerir que os serviços anunciados na plataforma são de autoria da 123JUS. Também serão desconsiderados quaisquer atos ofensivos ao pleno funcionamento do sistema.
                    </p>

                    <p>
                        A 123JUS se reserva no direito de recusar solicitações de cadastros que estejam em desacordos com as políticas e regras dos presentes Termos e Condições Gerais, assim como possui o direito de suspender um cadastro previamente aceito.
                    </p>

                    <p>
                        A 123JUS poderá, a seu exclusivo critério, realizar buscas a fim de verificar os dados fornecidos pelos parceiros, julgar os serviços prestados e solicitar dados adicionais e documentos que se fizerem necessários para conferir as informações cadastradas, podendo ainda utilizar-se de base de dados públicas ou privadas.
                    </p>

                    <p>
                        Fornecer informações incompletas, falsas ou imprecisas violam toda a política dos Termos e Condições de Uso desta plataforma, estando o Usuário plenamente responsável pelos danos que vierem a ser causados por tal transgressão.
                    </p>

                    <p>
                        A Plataforma se reserva no direito de solicitar mais informações e documentos sobre o Usuário Contratante a qualquer tempo. Uma vez que se recuse a fornecer tais informações o usuário estará ferindo com os Termos.
                    </p>

                    <p>
                        A concessão de prazo para pagamento dos serviços poderá ou não ser concedido em função de análise do perfil do contratante, bem como documentos. Podendo tal concessão ser suspensa ou cancelada a qualquer tempo.
                    </p>

                    <p>
                        Qualquer problema encontrado no cadastro do usuário poderá trazer sanções a serem aplicadas pelo 123JUS, sendo essas a suspensão temporária ou definitiva da conta, sem prejuízo de outras medidas que entender necessárias e oportunas. Uma vez aplicada qualquer sanção será, automaticamente, cancelada os anúncios dos respectivos usuários, não cabendo a esta qualquer indenização ou ressarcimento.
                    </p>

                    <p>
                        A 123JUS não se responsabiliza pelos Dados Pessoais inseridos pelos Usuários. Esses devem garantir a sua veracidade e respondem, em qualquer caso, civil e criminalmente pelas informações cadastradas.
                    </p>

                    <p>
                        É de responsabilidade do usuário garantir a proteção de seus dados para acesso à plataforma, não podendo nenhuma contratação ser cancelada em razão do uso incorreto dos dados de acesso.
                    </p>

                    <p className="two">
                        O usuário acessará a sua conta através do apelido/e-mail (login) e senha, comprometendo-se a não informar a terceiros desautorizados esses dados, responsabilizando-se integralmente pelo uso que deles seja feito.
                    </p>

                    <p className="heavy">
                        DA DIVULGAÇÃO DE VAGAS
                    </p>

                    <p>
                        De acordo com a Lei n° 12.965/2014 ("Uso da Internet no Brasil"), artigo 18: “O provedor de conexão à internet não será responsabilizado civilmente por danos decorrentes de conteúdo gerado por terceiros”. Portanto, as informações providas pelo Correspondentes, as quais o Contratante possui amplo acesso, como nome, telefone de contato, localização, entre outras, não são responsabilidade da 123JUS.
                    </p>

                    <p>
                        A EMPRESA poderá disponibilizar para os usuários gratuitamente a possibilidade de divulgarem vagas e se candidatarem para as mesmas, sendo de responsabilidade dos mesmos as informações prestadas e o prosseguimento do processo seletivo.
                    </p>

                    <p className="two">
                        O disposto no item anterior se aplica igualmente a qualquer informação fornecida sobre serviços, tais como valores cobrados, características dos serviços, inclusive a divulgação e candidatura para vaga, são de responsabilidade única de seu anunciante, sem envolvimento por parte da Plataforma.
                    </p>

                    <p className="heavy">OBSERVÂNCIA À LEI DE PROTEÇÃO DE DADOS</p>

                    <p>
                       Estamos empenhados em salvaguardar a sua privacidade ao estabelecer esta relação conosco. Este termo tem a finalidade de deixar o mais claro possível a nossa política de coleta e compartilhamento de dados, informando sobre os dados coletados e como os utilizamos.
                    </p>

                    <p>
                        Ao utilizar os nossos serviços, você declara o seu EXPRESSO CONSENTIMENTO para coletarmos, tratarmos e armazenarmos dados sobre você quando julgarmos necessários à prestação de nossos serviços.
                    </p>

                    <p>
                        Pelo presente as partes declaram que tanto o aplicativo quanto o site quanto do uso do sistema observarão as disposições da à Lei 13.709/18 que regulamenta a proteção de dados pessoais e da Lei nº 12.965/14 que regulamenta o Marco Civil da Internet. Desde já você autoriza o colhimento e uso de dados nos seguintes teremos:
                    </p>

                    <p>
                        (I) - Algumas informações pessoais são obtidas quando você se registra, preenchendo um formulário de contato ou cadastro. Durante o cadastro a EMPRESA solicita alguns dados como nome, endereço de e-mail e outras informações pessoais. 
                    </p>

                    <p>
                        (II) - Podemos registrar e gravar todos os dados fornecidos em toda comunicação realizada com nossa equipe, seja por correio eletrônico, mensagens, telefone ou qualquer outro meio.
                    </p>

                    <p>
                        (III) - Ao submeter seus dados para pagamento, podemos coletar informações sobre a compra ou transação. Isso abrange suas informações de pagamento, como os dados do seu cartão de crédito ou débito, informações de conta e autenticação, além dos dados de faturamento, tais como endereço completo, CPF e CNPJ.
                    </p>

                    <p>
                        (IV) - As informações pessoais transmitidas não são reveladas sem a sua autorização prévia. Portanto, não serão vendidas, compartilhadas sem ser para os propósitos aqui expostos ou alugadas. Suas informações servem exclusivamente para criar um perfil dos usuários e permitem melhorar a qualidade dos serviços, ao mesmo tempo em que possibilitam direcionamento de conteúdos com maior eficácia.
                    </p>

                    <p>
                        (V) - As informações também poderão ser utilizadas para gerar dados estatísticos gerais com finalidade informativa ou comercial. Informações geográficas, demográficas, psicográficas e de perfil de uso dos usuários poderão ser fornecidas a parceiros, patrocinadores, anunciantes ou outras empresas externas, sem que sejam revelados nomes ou outros dados de navegação.
                    </p>

                    <p>
                        (VI) - Desde já fica permitido o uso de cookie, que é um pequeno arquivo que seu navegador salva no seu disco rígido. Os cookies não conterão nenhuma informação pessoal identificável, mas permitirão oferecer determinadas funcionalidades que facilitam a navegação.
                    </p>

                    <p>
                        (VII) - Ao navegar na Internet, algumas informações serão transmitidas automaticamente entre computadores e serão coletadas como parte das exigências tecnológicas inerentes à navegação, para fins estatísticos. São elas: nome do domínio, IP, data e hora de navegação no site da EMRPESA, páginas visitadas dentro do site da EMPRESA, tipo de navegador e outras correlacionadas.
                    </p>

                    <p>
                        Compartilhamos os seus dados com a empresa responsável pelo processamento de pagamentos, para fins de viabilizar pagamentos e o processamento de dados, bem como para tornar o produto mais relevante e eficiente à sua finalidade.
                    </p>

                    <p>
                        Estes dados são armazenados em servidores seguros nossos ou de fornecedores contratados, acessados e utilizados de acordo com nossas políticas e padrões de segurança. Todo o tráfego entre nossos servidores ou entre o seu computador e nossos servidores é encriptado através do protocolo seguro SSL ou semelhante.
                    </p>

                    <p>
                        Tomamos medidas de boas práticas e certificações existentes no mercado para garantir que os dados que coletamos sejam processados de acordo com segurança onde quer que os dados estejam localizados.
                    </p>

                    <p>
                        Nós manteremos as informações que coletamos de você até que ocorra a solicitação de exclusão definitiva por sua parte. Neste caso, nós cessaremos imediatamente a utilização dos seus dados para fins comerciais, porém armazenaremos os seus dados enquanto tenhamos obrigações legais, tributárias ou judiciais a cumprir com tais dados.
                    </p>

                    <p>
                        Ao utilizar a plataforma você AUTORIZA EXPRESSAMENTE o uso destas informações para as seguintes FINALIDADES:
                    </p>

                    <ul className="list">
                        <li className="list-info">
                            Fornecer, melhorar e desenvolver os Serviços disponíveis. Usamos os seus dados para melhorar a qualidade dos nossos serviços, através da personalização do serviço prestado.
                        </li>
                        <li className="list-info">
                            Obrigações legais. Compartilhamos seus dados com terceiros para cumprir exigências legais, regulatórias ou fiscais, envolvendo a divulgação de seus dados pessoais a terceiros, a um tribunal, reguladores ou agências governamentais.
                        </li>
                    </ul>

                    <p className="two">
                        A não observância de qualquer disposição das referidas leis implicarão em responsabilidade exclusiva do infrator.
                    </p>

                    <p className="heavy">LICENÇA</p>

                    <p>
                        A EMPRESA, outorga a você licença de caráter não exclusivo e limitado, proibindo a sublicença à terceiros, sendo revogável por opção da mesma para: (i) uso do site; e (ii) acesso e uso de aplicativo. Quaisquer direitos não expressamente aqui outorgado são restritos à EMPRESA.
                    </p>

                    <p className="two">
                        Este documento e/ou o uso dos serviços não conferem direito sobre os serviços, exceto pela licença limitada concedida acima, ficando restrito o uso de nomes societários, logotipos, nomes de produtos ou de serviços, marcas comerciais ou qualquer outra propriedade da EMPRESA.
                    </p>

                    <p className="heavy">OBRIGAÇÕES DOS USUÁRIOS</p>

                    <p className="two">
                        O Usuário não deve autorizar terceiros a usarem sua conta receberem serviços em seu nome, salvo se constituírem sociedade. O Usuário concorda em cumprir todas as leis aplicáveis quando usar os serviços da EMRPESA e apenas poderá usar os serviços da EMPRESA para finalidades lícitas e previstas neste termo. Não poderá o usuário utilizar a plataforma ou aplicativo para fins ilícitos ou para causar transtorno, aborrecimento, inconveniente ou danos à propriedade a qualquer pessoa.
                    </p>

                    <p className="heavy">CONFIDENCIALIDADE</p>

                    <p className="two">
                        Os usuários se comprometem a manter em estrita confidencialidade todos os documentos e informações, que não sejam de conhecimento público e outras informações divulgadas ou recebidas em decorrência da utilização do site e/ou aplicativo e dos serviços prestados pelos usuários fornecedores de serviços, tais como preços, estratégias, documentos produtos e serviços
                    </p>

                    <p className="heavy">CONDIÇÕES GERAIS PARA PAGAMENTO</p>

                    <p>1. DO PAGAMENTO</p>

                    <p>
                        1.1 - Os Usuários aceitam, expressamente, pagar a mensalidade do plano livremente escolhido, bem como o valor da diferença entre o que foi pago pelo solicitante e cobrado pelo usuário prestador de serviço. Concordam a pagar a EMPRESA remuneração que envolverá: tarifa fixa de transação e/ou taxa do meio de pagamento, em porcentagem declarada, por transação. Os valores serão celebrados no acordo de tarifas ofertadas no site e aceitas entre as partes.
                    </p>

                    <p>
                        1.2 - A verificação de conta e preenchimento de formulário são fatores importantes para a utilização dos serviços. Os Usuários irão fornecer informações próprias: nome/razão social, CPF/CNPJ, endereço, endereço de cobrança, endereço eletrônico, números de telefones e dados da conta bancária.
                    </p>

                    <p>
                        1.2.1 - A falta de veracidade nestas informações acarretará sanções ao Usuário ou atraso no pagamento que será justificado.
                    </p>

                    <p>
                        1.3 - Todos os pagamentos devidos serão efetuados através do pix ou conta bancária informada no formulário de cadastro, sendo a remuneração da EMPRESA automaticamente descontada do valor, conforme item 1.1, podendo ocorrer diferenças em razão do tipo de transação, meio de pagamento, segmento de atuação, forma de captura ou plano contratado.
                    </p>

                    <p>
                        1.4 - Os pagamentos poderão ser realizados das formas que seguem:
                    </p>

                    <p>
                        1.4.1 - Boleto Bancário: A liberação irá depender da data de liquidação do boleto bancário. Deverão ser observadas as regras de compensação do banco ao qual o pagamento foi efetuado.                        
                    </p>

                    <p>
                        1.4.2 - Cartão de Crédito: A liberação será feita a cada fim do ciclo de cobrança (30 dias), por parcela, contando a partir da data de aprovação do pagamento efetuado.
                    </p>

                    <p>                        
                        1.4.3 - Dépósito em Conta: A liberação será feita de acordo com a regra de cada instituição bancária.
                    </p>

                    <p>
                        1.5	- Só serão pagas junto com a fatura mensal, as demanda que após a conclusão no sistema pelo USUÁRIO foram aprovadas com aceite pela equipe interna da EMPRESA, podendo haver prévio pagamento de despesas a serem pactuadas no curso da demanda. O comprovante de pagamento será disponibilizado e anexado na fatura mensal, conforme o caso.
                    </p>

                    <p className="two">
                        1.6	- As transações bancárias serão feitas no dia útil subsequente ao dia de vencimento quando este cair em feriado ou fim de semana e estará totalmente sujeita ao funcionamento do banco.
                    </p>

                    <p>
                        2.	DA RESTITUIÇÃO 
                    </p>

                    <p>
                        2.1	- A EMPRESAS possui políticas a respeito do cancelamento de transações e restituição. Essas deverão ser feitas no valor exato da transação.
                    </p>

                    <p>
                        2.2	- O Usuário deverá restituir a sua exclusiva responsabilidade.
                    </p>

                    <p>
                        2.3	- Em casos onde a 123JUS seja intimada a efetuar restituições, o Usuário será imediatamente notificado a fim de que este efetue a transação, a sua exclusiva responsabilidade.
                    </p>

                    <p className="two">
                        2.3.1 - Caso a restituição não seja feita nos prazos e condições estipulados, será demandado processo de cobrança dos valores devidos.
                    </p>

                    <p>
                       3. DOS CHARGEBACKS E ESTORNOS
                    </p>

                    <p>
                        3.1	- O presente item trata sobre o procedimento realizado pelo consumidor junto a operadora de cartão de débito ou crédito, alegando desconhecimento de transação específica efetuada com o cartão de sua titularidade.
                    </p>

                    <p>
                        3.2	- Deverão ser observadas regras de estorno de cada bandeira, mantendo as boas práticas comerciais a fim de que se evite o tratado no item 3.1. Recomendamos que os comprovantes de transações sejam conservados.
                    </p>

                    <p>
                        3.3	- Em caso de notificações de Chargerback recebidas pela EMPRESA, o Usuário será notificado para realizar as devidas providências junto ao reclamante, no prazo MÁXIMO de 30 dias e iniciará o devido processo de cobrança de valores devidos.
                    </p>

                    <p>
                        3.3.1 - No ato de descumprimento do prazo definido no item anterior, bem como falta de atualização à Plataforma sobre os trâmites, a EMPRESA iniciará processo de cobrança.
                    </p>

                    <p>
                        3.4	- O Usuário terá o limite máximo de 1% de pedidos de Chargerback no mês, em relação ao número máximo de transações, do contrário sua conta será suspensa pelo prazo de 60 dias.
                    </p>

                    <p>
                        3.5	- Para reincidentes no disposto no item 3.4 será aplicada multa de 25% sob o valor do Chargerback e a suspensão se dará por 90 dias.
                    </p>

                    <p className="two">
                        3.6	- Para reincidentes em dobro, as sanções citadas no item 3.5 serão aplicadas em dobro.
                    </p>

                    <p>4. DAS DISPUSTAS E RETENÇÕES </p>

                    <p>
                        4.1	- A EMPRESA reconhece a observância das regras de disputas quando em desacordo comercial e Chargebacks iniciados por seus Usuários. Em caso de disputas, os saldos estarão retidos até a sua resolução.
                    </p>

                    <p>
                        4.2	- A disputas devem ser solucionadas em um prazo máximo de 30 dias, podendo variar de acordo com cada banco ou administradora de cartão. Durante o período de disputa, todo saldo a ela relacionado estará retido.
                    </p>

                    <p>
                        4.3	- Se o Usuário perder a disputa, autoriza previamente que o saldo anteriormente retido seja usado para dar quitação a disputa ou qualquer outro débito proveniente ou junto ao EMPRESA.
                    </p>

                    <p>
                        4.4	- Os saldos dos Usuários poderão ser retidos para auditoria e apuração de conduta, quando o referido atuar em desacordo com as Políticas desta Plataforma e de estabelecimentos bancários, administradoras de cartão, lei e práticas de Mercado e/ou Normas do BACEN.
                    </p>

                    <p>
                        4.5	- Condutas em que forem constatadas fraude em termos gerais, lavagem de dinheiro, etc., ocasionarão cancelamento da conta do Usuário na Plataforma EMPRESA. Nestes casos, os saldos serão retidos e as informações do infrator serão entregues de boa-fé às autoridades.
                    </p>

                    <p>5. DO DOMICÍLIO BANCÁRIO E COBRANÇA DE VALORES</p>

                    <p>
                        5.1	- Por meio deste o Usuário expressamente autorizada a EMPRESA efetuar operações de débito automático em conta bancária vinculada à conta utilizada na Plataforma, conforme formulário de cadastro, para as finalidades definidas em itens anteriores.
                    </p>

                    <p>
                        5.2	- Quando ocorrerem restituições, estornos ou cancelamentos em favor da EMPRESA, o valor exato da respectiva transação deverá ser entregue a EMPRESA pelo Usuário, sendo o valor corrigido sobre IGP-M/FGV (ou outro índice), contando a data do repasse, com juros de 1% (um por cento) a.m. ou fração pró-rata, com o acréscimo de encargos operacionais e qualquer perdas e danos ocorridos.
                    </p>

                    <p>
                        5.3	- Podem ser adotadas as seguintes medidas com fins de cobrança de valores devidos a EMPRESA:
                    </p>

                    <p>
                        5.3.1 - Compensação de valores através de outros créditos, presentes ou futuros, devidos.
                    </p>

                    <p>
                        5.3.2 - Lançamentos de débitos na conta bancária vinculada.
                    </p>

                    <p>
                        5.3.3 - Compensação por meio de cheque, boleto, TED, DOC ou depósito identificado, de acordo com tratativas entre as partes.
                    </p>

                    <p>
                        5.3.4 - Escritórios de cobrança especializados.
                    </p>

                    <p>
                        5.3.5 - Ações Judicial, protestos e negativações.
                    </p>

                    <p>
                        5.4	- Falta ou atraso de pagamento, seja total ou parcial, dentro dos prazos previamente acordados em contrato ou em respectivas atualizações, podem sujeitar o Usuário ao pagamento dos seguintes encargos:
                    </p>

                    <p>
                        5.4.1 - Atualização monetária das dívidas, baseando-se no IPC/FGV, ou outro índice.
                    </p>

                    <p>
                        5.4.2 - Acréscimo de juros de 2% (um por cento) a.m. Não obstante, o EMPRESA se reserva no direito de incluir os débitos do Usuário no cadastro de Pendências Financeiras (PEFIN) e qualquer outro órgão de proteção de crédito.
                    </p>

                    <p className="two">
                        5.4.3 - Inscrição nos órgãos de cadastro e proteção ao crédito.
                    </p>

                    <p>6. DO PRAZO DE PAGAMENTO</p>

                    <p>
                        6.1. - O usuário profissional jurídico que realiza serviços dentro de um mês, deverá agrupar todos os serviços feitos no mês em um relatório e enviar até o dia 20 (vinte) do mês subsequente ao de realização, relação de serviços realizados no mês anterior, que compreende o período do primeiro ao último dia do referido mês. Este relatório deverá ser mediante planilha modelo que lhe e deve ser enviado para o email financeiro@123jus.com.br.  O não envio do relatório dentro do prazo irá fazer com que a fatura passe para a fase de conferência com status de atrasada, o que atrasará o pagamento.
                    </p>

                    <p>
                        6.1.1. - A EMPRESA poderá concordar com a relação enviada ou caso discorde irá até o último dia do mês subsequente ao de realização dos serviços, enviar um email com o motivo da discordância e valor da fatura correto.
                    </p>

                    <p>
                        6.1.2. - O não envio de comprovação da divergência implicará automaticamente em concordância pelo usuário profissional jurídico dos serviços e valores contabilizado pela EMPRESA no período.
                    </p>

                    <p>
                        6.1.3. - Após finalizada a fase de conferência, no mês subsequente ao mês de conferência, entre o dia 20 e o último dia de cada mês, a EMPRESA realizará o pagamento ao usuário profissional jurídico na conta bancária indicada no cadastro, ou seja, em até 31 dias após o fim da fase de conferência, que dura do dia 20 (vinte) do mês subsequente à realização dos serviços até o último dia do mês. Portanto, o pagamento das diligências poderá ocorrer em até 60 (sessenta) dias após o fim do mês de realização dos serviços.
                    </p>

                    <p className="two">
                        6.1.4. - Excepcionalmente, em caso de atraso de pagamento pelo usuário solicitante poderá também haver atraso no pagamento do usuário profissional jurídico.
                    </p>

                    <p>RESCISÃO</p>

                    <p>
                        Os usuários tem o direito de cancelar sua conta ou assinatura na plataforma a qualquer momento. O cancelamento não incide em desobrigação de cumprimento das obrigações anteriormente assumidas.
                    </p>

                    <p>
                        As condições de cancelamento quando estiverem em curso a realização de serviços serão tratadas entre os usuários ou entre o usuário e a EMPRESA.
                    </p>

                    <p>
                        A 123JUS se reserva no direito de suspender, cancelar, impedir ou interromper o acesso de usuários à plataforma, sem aviso prévio, uma vez não seguidas as regras dispostas nos Termos e Condições de Uso, contratos celebrados por meio da plataforma, ou qualquer outro dispositivo que viole o bom funcionamento do site e aplicativo.
                    </p>

                    <p>
                        O usuário infrator poder ser notificado sobre o uso inadequado, à critério da 123JUS.
                    </p>

                    <p className="two">
                        Na suspeita de uso inadequado ou reincidência o usuário poderá ter o cadastrado cancelado.
                    </p>

                    <p>POLÍTICAS DE COOKIES</p>

                    <p>
                        Cookies são pequenos arquivos de texto ou fragmentos de informação que são baixados em seu computador, smartphone ou qualquer outro dispositivo com acesso à internet quando você visita nossa aplicação.
                    </p>

                    <p>
                        Eles contêm informações sobre a sua navegação em nossas páginas e retêm apenas informações relacionadas a suas preferências.
                    </p>

                    <p>
                        Assim, essa página consegue armazenar e recuperar os dados sobre os seus hábitos de navegação, de forma a melhorar a experiência de uso, por exemplo. É importante frisar que eles não contêm informações pessoais específicas, como dados sensíveis ou bancários.
                    </p>

                    <p>
                        O seu navegador armazena os cookies no disco rígido, mas ocupam um espaço de memória mínimo, que não afetam o desempenho do seu computador. A maioria das informações são apagadas logo ao encerrar a sessão, como você verá no próximo tópico.
                    </p>

                    <p className="heavy">
                        Os cookies, quanto a seus proprietários, podem ser:
                    </p>

                    <p>
                        Cookies proprietários: são cookies definidos por nós ou por terceiros em nosso nome
                    </p>

                    <p>
                        Cookies de terceiros: são cookies definidos por terceiros confiáveis em nossa aplicação
                    </p>

                    <p className="heavy">
                       Os cookies, quanto ao seu tempo de vida, podem ser:
                    </p>

                    <p>
                        Cookies de sessão ou temporários: são cookies que expiram assim que você fecha o seu navegador, encerrando a sessão.
                    </p>

                    <p>
                        Cookies persistentes ou permanentes: são cookies que permanecem no seu dispositivo durante um período determinado ou até que você os exclua.
                    </p>

                    <p className="heavy">
                        Os cookies, quanto a sua finalidade, podem ser:
                    </p>

                    <p>
                        Cookies necessários: são cookies essenciais que possibilitam a navegação em nossas aplicações e o acesso a todos os recursos; se estes, nossos serviços podem apresentar mal desempenho ou não funcionar.
                    </p>

                    <p>
                        Cookies de desempenho: são cookies que otimizam a forma que nossas aplicações funcionam, coletando informações anônimas sobre as páginas acessadas.
                    </p>

                    <p>
                        Cookies de funcionalidade: são cookies que memorizam suas preferências e escolhas (como seu nome de usuário)
                    </p>

                    <p>
                        Cookies de publicidade: são cookies que direcionam anúncios em função dos seus interesses e limitam a quantidade de vezes que o anúncio aparece.
                    </p>

                    <p className="heavy">
                        Porque usamos cookies:
                    </p>

                    <p>
                        A EMPRESA utiliza Cookies para fornecer a melhor experiência de uso, tornando nossas aplicações mais fáceis e personalizadas, tendo por base suas escolhas e comportamento de navegação.
                    </p>

                    <p>
                        Assim, buscamos entender como você utiliza nossas aplicações e ajustar o conteúdo para torná-lo mais relevante para você, além de lembrar de suas preferências.
                    </p>

                    <p>
                        Os Cookies participam deste processo porquanto armazenam, leem e executam os dados necessários para cumprir com o nosso objetivo.
                    </p>

                    <p className="heavy">Que tipo de cookies utilizamos:</p>

                    <p>
                        Vários são os tipos de cookies que podem ser utilizados pela EMPRESA. Dentre eles, não se restringindo a cookies necessários, de publicidade, desempenho, funcionalidade e outros que a EMPRESA julgar necessário. É importante lembrar que você pode gerenciar a permissão concedida para cada Cookie em seu navegador. Além disso, uma vez que os Cookies capturam dados sobre você, aconselhamos a leitura de nossa Política de Privacidade, disponível neste documento.
                    </p>

                    <p className="heavy">Gerenciamento de cookies:</p>

                    <p>
                        A instalação dos cookies está sujeita ao seu consentimento. Apesar da maioria dos navegadores estar inicialmente configurada para aceitar cookies de forma automática, você pode rever suas permissões a qualquer tempo, de forma a bloqueá-los, aceitá-los ou ativar notificações para quando alguns cookies forem enviados ao seu dispositivo.
                    </p>

                    <p>
                        Atualmente, na primeira vez que você acessa nossas aplicações, será requerida a sua concordância com a instalação destes. Apenas após a sua aceitação eles serão ativados.
                    </p>

                    <p>
                        Como já dito, você pode, a qualquer tempo e sem nenhum custo, alterar as permissões, bloquear ou recusar os Cookies. Você também pode configurá-los caso a caso. Todavia, a revogação do consentimento de determinados Cookies pode inviabilizar o funcionamento correto de alguns recursos da plataforma.
                    </p>

                    <p>
                        Para gerenciar os cookies do seu navegador, basta fazê-lo diretamente nas configurações do navegador, na área de gestão de Cookies.
                    </p>

                    <p>
                        Você pode acessar tutoriais sobre o tema diretamente nos links abaixo:
                    </p>

                    <p>
                        Se você usa o Internet Explorer. <br />
                        Se você usa o Firefox. <br />
                        Se você usa o Safari. <br />
                        Se você usa o Google Chrome. <br />
                        Se você usa o Microsoft Edge. <br />
                        Se você usa o Opera. <br />
                    </p>

                    <p className="heavy">Disposições finais sobre a política de cookies:</p>

                    <p>
                        Para a (nome empresarial), a privacidade e confiança são fundamentais para a nossa relação com você. Estamos sempre nos atualizando para manter os mais altos padrões de segurança 
                    </p>

                    <p>
                        Assim, reservamo-nos o direito de alterar esta Política de Cookies a qualquer tempo. As mudanças entrarão em vigor logo após a publicação, e você será avisado
                    </p>

                    <p>
                        Ao continuar a navegação nas nossas aplicações após essa mudança se tornar eficaz, você concorda com elas. Aconselhamos que você sempre verifique esta Política, bem como a nossa Política de Privacidade.
                    </p>

                    <p className="two">
                        Em caso de dúvidas sobre esta Política de Cookies, entre em contato conosco pelo email contato@123jus.com.br.
                    </p>

                    <p className="heavy">OUTRAS DISPOSIÇÕES </p>

                    <p>
                        <span>Tributos.</span> Qualquer tributo de qualquer natureza que possa incidir em decorrência das operações aqui previstas deverá ser arcado, exclusivamente, pela Parte a quem a lei aplicável atribuir a condição de contribuinte fiscal.
                    </p>

                    <p>
                        <span>Renúncia.</span> Nenhuma renúncia por qualquer das Partes a qualquer termo ou disposição deste atermo ou a qualquer descumprimento deste deverá afetar o direito de tal Parte de posteriormente exigir o cumprimento de tal termo ou disposição ou de exercer qualquer direito ou recurso na hipótese de qualquer outro descumprimento, seja ou não semelhante.
                    </p>

                    <p>
                       <span>Independência.</span> Se qualquer disposição deste termo for considerada inválida ou inexequível por qualquer tribunal com jurisdição competente, as demais disposições permanecerão em pleno vigor e efeito. Qualquer disposição deste instrumento que for considerada inválida ou inexequível somente em parte ou grau permanecerá em pleno vigor e efeito na medida em que não tenha sido considerada inválida ou inexequível.
                    </p>

                    <p>
                        <span>Responsabilidade por impostos.</span> Exceto se expressamente estabelecido de outra forma, a EMPRESA e usuários arcarão com seus próprios gastos, impostos, custos e despesas incorridos execução dos serviços prestados.
                    </p>

                    <p>
                        <span>Lei Aplicável.</span> Este Contrato reger-se-á por e será interpretado de acordo com as Leis da República Federativa do Brasil.
                    </p>

                    <p>
                        <span>Totalidade dos Entendimentos.</span> Este documento substitui todos e quaisquer entendimentos prévios mantidos, representando a totalidade dos entendimentos havidos entre as partes. 
                    </p>

                    <p>
                        <span>Eleição de Foro.</span> As Partes elegem o foro da Cidade de Belo Horizonte, Estado de Minas Gerais para dirimir quaisquer dúvidas ou divergências relativas a este termo, renunciando a qualquer outro, por mais privilegiado que seja.
                    </p>

                    <p>
                        Estes Termos de Uso, em conjunto com a Política de Cookies e de Privacidade e quaisquer outros avisos legais publicados pela EMPRSA integram o contrato entre a EMPRESA e os usuários. Se a conta do usuário for encerrada por violações relevantes a este termo ou às políticas de privacidades o usuário poderá ser proibido de fazer qualquer uso dos serviços da EMPREA.O uso do website da EMPRESA pelos usuários após qualquer aditamento aos Termos de Uso implicará o consentimento e a aceitação dos termos aditados.
                    </p>

                    <p className="center">
                        Belo Horizonte, 19 de fevereiro de 2023.
                    </p>
                    
                </ModalMain>

            </ModalTerms>        
            
            {/* Modal para excluir a precificação */}
            <ModalDefault
                id={'overlayModal'}
                onClose={() => setOpenModalConfirmation((openModalConfirmation) => !openModalConfirmation)}
                openModal={openModalConfirmation}
                width="45%"
            >
                <ModalPrecification>
                    <div className="header">
                        <p>Deseja excluir a precificação?</p>
                        <div
                            className="close"
                            onClick={() => setOpenModalConfirmation(false)}
                        >
                            <IconClose />
                        </div>
                    </div>
                    <div className="main">
                        <ButtonDefault
                            className="cancel"
                            onClick={() => setOpenModalConfirmation(false)}
                        >
                            Cancelar
                        </ButtonDefault>
                        <ButtonDefault
                            className="danger"
                            onClick={() => setExcludePrecification(true)}
                        >
                            Confirmar
                        </ButtonDefault>
                    </div>
                </ModalPrecification>
            </ModalDefault>

            {/* Modal para excluir a Comarca */}
            <ModalDefault
                id={'overlayModal'}
                onClose={() => setOpenModalDeleteComarca((openModalDeleteComarca) => !openModalDeleteComarca)}
                openModal={openModalDeleteComarca}
                width="45%"
            >
                <ModalPrecification>
                    <div className="header">
                        <p>Deseja excluir a comarca?</p>
                        <div
                            className="close"
                            onClick={() => setOpenModalDeleteComarca(false)}
                        >
                            <IconClose />
                        </div>
                    </div>
                    <div className="main">
                        <ButtonDefault
                            className="cancel"
                            onClick={() => setOpenModalDeleteComarca(false)}
                        >
                            Cancelar
                        </ButtonDefault>
                        <ButtonDefault
                            className="danger"
                            onClick={() => setDeleteComarca(true)}
                        >
                            Confirmar
                        </ButtonDefault>
                    </div>
                </ModalPrecification>
            </ModalDefault>

            <div onClick={() => toast.remove()}>                            
                <Toaster  />
            </div>
        </Container>
    )
}